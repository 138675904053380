import { getAddressFromLatLng } from "@/components/Map/useGetAddressFromLatLng";
import { EARTH_DIRECTIONS } from "../constants";
import { env } from "../env";
import { parseInfoResponse } from "../utils/Utils";
import { setDataWindow, setDataWindowContent } from "./actions_datawindow";
import { saveInfoResponse } from "./actions_dyno_info";
import { setTaxlot, setTaxlotError } from "./actions_taxlot";
import { setLoader, setUserLoginError, userNotAuth } from "./actions_user";

export const SET_INFO_DEFAULT_VALUE = "SET_INFO_DEFAULT_VALUE";
export const SET_INFO_CURRENT_VALUE = "SET_INFO_CURRENT_VALUE";
export const RESET_INFO_CURRENT_INFO_SELECTOR =
  "RESET_INFO_CURRENT_INFO_SELECTOR";
export const INFO_API_RESPONSE = "INFO_API_RESPONSE";
export const INFO_VALUES_UPDATED = "INFO_VALUES_UPDATED";
export const INFO_MAP_ADDRESS = "INFO_MAP_ADDRESS";
export const INFO_COORDS = "INFO_COORDS";
export const SET_INFO_UPDATED = "SET_INFO_UPDATED";
export const INFO_GEO = "INFO_GEO";
export const SET_LINK_VALUE = "SET_LINK_VALUE";
export const SET_PHOTO_REF = "SET_PHOTO_REF";
export const INFO_NOT_LOGGED_IN = "INFO_NOT_LOGGED_IN";
export const USE_ALLOWANCE_UPDATE = "USE_ALLOWANCE_UPDATE";
export const USE_CATEGORY_UPDATE = "USE_CATEGORY_UPDATE";
export const INFO_REPORT_REMAIN = "INFO_REPORT_REMAIN";
export const RESET_INFO = "RESET_INFO";
// This is for info

export function getSelectInfo(
  object,
  infoDefaultValues,
  lngLat,
  number,
  user = null
) {
  const token = window.localStorage.getItem("_uft");

  return async (dispatch) => {
    dispatch(resetInfo());

    // dispatch(resetAllCurrentFilters())
    try {
      const response = await fetch(
        env.REACT_APP_URBANFORM_API_URL + "/api/info/",
        {
          method: "POST",
          body: JSON.stringify(object)
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("tokenExpired")) {
        window.location.reload(false);
      } else if (Object.keys(data).includes("error_message")) {
        dispatch(setUserLoginError(data.error_message));
        dispatch(setLoader(false));
      } else if (Object.keys(data).includes("primaccnum")) {
        dispatch(setUserLoginError("Prime account number is required!"));
        dispatch(setLoader(false));
      } else if (Object.keys(data).includes("error")) {
        dispatch(setUserLoginError(data.msg));
        dispatch(setLoader(false));
      } else if (Object.keys(data).includes("code")) {
        window.localStorage.removeItem("_uft");
        dispatch(
          setUserLoginError("You logged in many days ago. Please login again.")
        );
        dispatch(userNotAuth());
        dispatch(
          setDataWindowContent({
            show: { showData: "invalidToken", showControl: "login" }
          })
        );
        dispatch(setLoader(false));
      } else if (Object.keys(data).includes("message")) {
        if (Object.keys(data).includes("availableIn")) {
          dispatch(
            setUserLoginError(
              "Request limit exceeded. Please try again in " +
                data.availableIn +
                " sec!"
            )
          );
          dispatch(setLoader(false));
        }
        if (data?.message.includes("exceeded")) {
          dispatch(
            setUserLoginError(
              "Your limit for unique lot selections has been exceeded. Please contact support to discuss your options."
            )
          );
          dispatch(setLoader(false));
        }
      }
      if (data.taxLots) {
        const res = data.taxLots;
        // TODO
        const address = data.taxLots.identifiers[0].properties;
        const addres =
          Capitalize(address.addr) +
          " " +
          Capitalize(address.addr_city) +
          ", " +
          Capitalize(address.addr_state) +
          " " +
          address.addr_zip;
        dispatch(setPhotoRef(addres));

        const updatedInfo = parseInfoResponse(infoDefaultValues, res);
        dispatch(saveInfoResponse(res));
        if (updatedInfo) {
          dispatch(setInfoDefaultValue(updatedInfo));
        } else {
          dispatch(setInfoDefaultValue(updatedInfo));
        }
        dispatch(setLoader(false));
        if (lngLat && data.taxLots) {
          data.taxLots["mapboxCoords"] = lngLat;
          dispatch(setTaxlot(data));
        }
        if (data && !data.taxLots) {
          dispatch(setTaxlotError("No tax lot data found"));
        }

        if (data.taxLots.centre_geom) {
          const lng = parseFloat(
            data.taxLots.centre_geom.split(";")[1].split(" ")[1].split(")")[0]
          );
          const lat = parseFloat(
            data.taxLots.centre_geom.split(";")[1].split(" ")[0].substr(6)
          );
          const cords = {
            lng: lng,
            lat: lat
          };
          dispatch(infoCoords(cords));
        }
        dispatch(infoGeometry(data.taxLots.centre_geom));
        dispatch(infoValuesUpdated(number));
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: null,
              contactInfo: {},
              choosePlan: {},
              isPayment: false,
              isInfo: true
            }
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "summary", showControl: "info" }
          })
        );
      }

      if (
        !(token != null && token !== "null") ||
        (token && user?.status !== "Enterprise")
      ) {
        dispatch(infoUserNotLoggedIn(true));
      }

      return data;
    } catch (err) {
      console.error(err);
      dispatch(setUserLoginError(JSON.stringify(err)));
      dispatch(setLoader(false));
    }
  };
}

type GetAddressByLngLatOptions = {
  object: any;
  lat: any;
  lng: any;
  searchBarGeocoder: any;
};

type AddressStore = {
  addr: string;
  addr_city: string;
  addr_state: string;
  addr_zip: string;
};

export function getAddressByLngLat({
  object,
  lat,
  lng,
  searchBarGeocoder
}: GetAddressByLngLatOptions) {
  return async (dispatch) => {
    const token = window.localStorage.getItem("_uft");
    const resp = await fetch(env.REACT_APP_URBANFORM_API_URL + "/api/info/", {
      method: "POST",
      body: JSON.stringify(object)
    });
    const data = await resp.json();
    if (data.taxLots) {
      const address = data.taxLots.identifiers[0].properties as AddressStore;
      let addressString = "";

      if (address.addr && address.addr_city && address.addr_state) {
        addressString =
          Capitalize(address.addr) +
          " " +
          Capitalize(address.addr_city) +
          ", " +
          Capitalize(address.addr_state) +
          " " +
          address.addr_zip;
      } else {
        addressString = await getAddressFromLatLng(lat, lng);
      }
      if (searchBarGeocoder) {
        searchBarGeocoder.setInput(addressString);
      }
      dispatch(setPhotoRef(addressString));
      if (!(token != null && token !== "null")) {
        dispatch(infoUserNotLoggedIn(true));
      }
    }
  };
}

export function updatedInfoSection(object, infoDefaultValues) {
  return async (dispatch) => {
    const resp = await fetch(env.REACT_APP_URBANFORM_API_URL + "/api/info/", {
      method: "POST",
      body: JSON.stringify(object)
    });
    const data = await resp.json();
    if (Object.keys(data).includes("tokenExpired")) {
      window.location.reload(false);
    } else if (Object.keys(data).includes("error_message")) {
      dispatch(setUserLoginError(data.error_message));
      dispatch(setLoader(false));
    } else if (Object.keys(data).includes("primaccnum")) {
      dispatch(setUserLoginError("Prime account number is required!"));
      dispatch(setLoader(false));
    } else if (Object.keys(data).includes("error")) {
      dispatch(setUserLoginError(data.msg));
      dispatch(setLoader(false));
    } else if (Object.keys(data).includes("code")) {
      window.localStorage.removeItem("_uft");
      dispatch(
        setUserLoginError("You logged in many days ago. Please login again.")
      );
      dispatch(userNotAuth());
      dispatch(
        setDataWindowContent({
          show: { showData: "invalidToken", showControl: "login" }
        })
      );
      dispatch(setLoader(false));
    } else if (Object.keys(data).includes("message")) {
      if (Object.keys(data).includes("availableIn")) {
        dispatch(
          setUserLoginError(
            "Request limit exceeded. Please try again in " +
              data.availableIn +
              " sec!"
          )
        );
        dispatch(setLoader(false));
      }
    } else {
      const res = data.taxLots;
      const updatedInfo = parseInfoResponse(infoDefaultValues, res);

      dispatch(saveInfoResponse(res));
      if (updatedInfo) {
        dispatch(setInfoDefaultValue(updatedInfo));
      } else {
        dispatch(setInfoDefaultValue(updatedInfo));
      }
      dispatch(setLoader(false));
    }
  };
}

export function getReportCount(token) {
  return async (dispatch) => {
    try {
      const resp = await fetch(
        env.REACT_APP_URBANFORM_API_URL + "/api/report/counter",
        {
          method: "GET"
        }
      );
      const data = await resp.json();
      dispatch(updateReportCount(data));
    } catch (error) {
      dispatch(setLoader(false));
    }
  };
}

function Capitalize(str) {
  const value = str.split(" ");

  const new_value = value.map(function (item, key) {
    if (EARTH_DIRECTIONS.includes(item)) {
      return item;
    } else {
      return item.replace(
        /(^\w|\s\w)(\S*)/g,
        (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
      );
    }
  });
  const a_t_s = new_value.toString();
  return a_t_s.split(",").join(" ");
}

export function setInfoDefaultValue(res) {
  return {
    type: SET_INFO_DEFAULT_VALUE,
    payload: res
  };
}

export function setInfoCurrentValue(infoValue) {
  return {
    type: SET_INFO_CURRENT_VALUE,
    payload: {
      data: infoValue
    }
  };
}

export function resetInfo() {
  return {
    type: RESET_INFO
  };
}
export function resetAllCurrentFilters(data) {
  return {
    type: RESET_INFO_CURRENT_INFO_SELECTOR,
    payload: data
  };
}

export function infoValuesUpdated(data) {
  return {
    type: INFO_VALUES_UPDATED,
    payload: data
  };
}
export function setInfoSearchMapAddress(data) {
  return {
    type: INFO_MAP_ADDRESS,
    payload: data
  };
}
export function infoCoords(coords) {
  return {
    type: INFO_COORDS,
    payload: coords
  };
}
export function infoGeometry(geometry) {
  return {
    type: INFO_GEO,
    payload: geometry
  };
}
export function setInfoUpdated(data) {
  return {
    type: SET_INFO_UPDATED,
    payload: data
  };
}

export function setLinkValue(data) {
  return {
    type: SET_LINK_VALUE,
    payload: data
  };
}

export function setPhotoRef(data) {
  return {
    type: SET_PHOTO_REF,
    payload: data
  };
}

export function infoUserNotLoggedIn(data) {
  return {
    type: INFO_NOT_LOGGED_IN,
    payload: data
  };
}

export function updateUseAllowance(data) {
  return {
    type: USE_ALLOWANCE_UPDATE,
    payload: data
  };
}

export function updateUseCategory(data) {
  return {
    type: USE_CATEGORY_UPDATE,
    payload: data
  };
}

export function updateReportCount(data) {
  return {
    type: INFO_REPORT_REMAIN,
    payload: data
  };
}
