import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import {
  setDataWindow,
  setDataWindowContent
} from "../../../../actions/actions_datawindow";
import {
  downloadUserReport,
  setLoader
} from "../../../../actions/actions_user";
import {
  FULL_REPORT,
  QUOTA_EXCEEDS,
  REPORT_DOWNLOAD,
  REPORT_PURCHASE
} from "../../../../constants";
import "../../../../css/ReportPreview.css";
import Container from "../Container";
import Header from "../Header";
import Section from "../Section";
import Segment from "../Segment";
import SegmentFooter from "../SegmentFooter";
import SegmentHeader from "../SegmentHeader";
const ReportPreview = ({ dispatch }) => {
  const alert = useAlert();
  const { token, user, pdfReport, planPrice, quotaExceed } = useSelector(
    (state) => state.user
  );
  const { summary } = useSelector((state) => state.info.infoDefaultValues);
  const { place_name } = useSelector(
    (state) => state.info.infoSearchMapAddress
  );
  const { geojson } = useSelector((state) => state.taxlot);

  const [report, setReport] = useState({});
  const handleReport = () => {
    if (token !== null) {
      if (["Professional", "Enterprise"].includes(user.status)) {
        if (quotaExceed === false) {
          if (geojson) {
            const coordinates = geojson.features[0].geometry.coordinates[0][0];
            const zooming1 = `${coordinates[0]},${coordinates[1]},16`;
            const zooming2 = `${coordinates[0]},${coordinates[1]},16.5`;
            const zooming3 = `${coordinates[0]},${coordinates[1]},16.8`;
            const body = {
              map_id: summary[0].properties[0].map_id,
              geojson: geojson,
              zoom1: zooming1,
              zoom2: zooming2,
              zoom3: zooming3
            };
            dispatch(setLoader(true));
            dispatch(downloadUserReport(body, token));
          } else
            {alert.error(
              "Please select or search a property to download report."
            );}
        } else {
          dispatch(
            setDataWindow({
              dataWindow: true,
              content: {
                isContact: null,
                isForce: true,
                isPayment: true,
                oneTimePayment: true,
                choosePlan: { plans: "", activeContent: "" }
              }
            })
          );
          dispatch(
            setDataWindowContent({
              show: { showData: "payment-quota", showControl: "info" }
            })
          );
        }
      } else if (["Basic"].includes(user.status)) {
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: null,
              contactInfo: {},
              choosePlan: {},
              isPayment: false,
              isInfo: true
            }
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "purchaseInfo", showControl: "info" }
          })
        );
      }
    } else {
      dispatch(
        setDataWindow({
          dataWindow: true,
          content: {
            isContact: null,
            contactInfo: {},
            choosePlan: {},
            isPayment: false,
            isInfo: true
          }
        })
      );
      dispatch(
        setDataWindowContent({
          show: { showData: "purchaseInfo", showControl: "info" }
        })
      );
    }
  };

  useEffect(() => {
    if (token !== null) {
      if (["Professional", "Enterprise"].includes(user.status))
        {if (quotaExceed === false) {
          setReport(REPORT_DOWNLOAD);
        } else {
          QUOTA_EXCEEDS.code = "quota_exceeds";
          QUOTA_EXCEEDS.productName = planPrice.Quota_Exceed_product_name;
          QUOTA_EXCEEDS.cost.header = "per report";
          QUOTA_EXCEEDS.price = planPrice.Quota_Exceed;
          QUOTA_EXCEEDS.title = "$" + planPrice.Quota_Exceed;
          QUOTA_EXCEEDS.detail =
            "You've reached your monthly limit for downloadable reports. You may purchase additional reports for a reduced price of " +
            QUOTA_EXCEEDS.title;
          QUOTA_EXCEEDS.cost.content =
            planPrice?.Quota_Exceed_product_description;
          setReport(QUOTA_EXCEEDS);
        }}
      else {
        REPORT_PURCHASE.code = "one_time";
        REPORT_PURCHASE.title = "$ " + planPrice.OneTime;
        REPORT_PURCHASE.productName = planPrice.OneTime_product_name;
        REPORT_PURCHASE.cost.header = "per report";
        REPORT_PURCHASE.price = planPrice.OneTime;
        REPORT_PURCHASE.cost.content = planPrice?.OneTime_product_description;
        setReport(REPORT_PURCHASE);
      }
    } else {
      REPORT_PURCHASE.code = "one_time";
      REPORT_PURCHASE.title = "$ " + planPrice.OneTime;
      REPORT_PURCHASE.productName = planPrice.OneTime_product_name;
      REPORT_PURCHASE.cost.header = "per report";
      REPORT_PURCHASE.price = planPrice.OneTime;
      REPORT_PURCHASE.cost.content = planPrice?.OneTime_product_description;
      REPORT_PURCHASE.title = "$ " + planPrice.OneTime;
      setReport(REPORT_PURCHASE);
    }
  }, []);

  const plan =
    ["Professional", "Enterprise"].includes(user?.status) && quotaExceed
      ? QUOTA_EXCEEDS
      : REPORT_PURCHASE;
  return (
    <>
      {quotaExceed === false && (
        <div className="full-report">
          <Container>
            <Header content={FULL_REPORT} />
            <Section content={FULL_REPORT} handleSampleReport={true} />
          </Container>
        </div>
      )}
      <div className="report-purchase">
        <Container>
          <SegmentHeader content={plan} />
          <Segment content={plan} />
          <SegmentFooter />
        </Container>
        <div className="btn">
          <Button className="gold-button" fluid onClick={handleReport}>
            {plan.button.label}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ReportPreview;
