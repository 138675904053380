import { isMobile } from "react-device-detect";
import { Button } from "semantic-ui-react";
import "../../../css/InfoFontStyle.css";
import "../../../css/Segment.css";
import Pdf from "../../../images/UrbanForm_Sample.pdf";

const Segment = ({ content }) => {
  return (
    <div className="section">
      <div
        className={[
          `${content?.sectionClass ? content.sectionClass : ""} section-display`
        ]}
      >
        <h2
          className="value-title heading-text"
          style={{ width: isMobile && "89%" }}
        >
          {content.cost.content}
        </h2>
        {content?.price && (
          <h2 className="value-01 heading-text">{`$${content.price}`}</h2>
        )}
        {!content?.custom && (
          <h2 className="value-title heading-text per-unit">
            {content?.code === "non-subscriber"
              ? content.cost.subContent
              : content.cost.header}
          </h2>
        )}
        {content?.buttons &&
          content?.buttons.map((button) => {
            return (
              <Button
                fluid
                type="submit"
                className={button.className}
                onClick={button.buttonFunction}
              >
                {button.label}
              </Button>
            );
          })}
        {content?.code &&
          (content?.code === "one_time" ||
            content?.code === "quota_exceeds") && (
            <Button
              fluid
              type="submit"
              className="primary"
              onClick={() => window.open(Pdf)}
            >
              See Sample Report
            </Button>
          )}
      </div>
    </div>
  );
};
export default Segment;
