import { Image } from "semantic-ui-react";
import { ICONS } from "../../../constants";
import "../../../css/Segment.css";

const SegmentHeader = ({ image, content, headingClass }) => {
  const getIconHelper = (name) => {
    return ICONS.filter((icon) => name === icon.name)[0]?.icon;
  };

  const GetHeaderContent = ({ content }) => {
    return (
      <h2 className={`${headingClass ?? ""} segment-text`}>
        {content.productName}
      </h2>
    );
  };

  return (
    <div className={`${content?.customClass ?? ""} segment-header`}>
      <div
        className="header-display"
        style={{ ...(image ? { display: "flex", marginLeft: "6px" } : {}) }}
      >
        {image && (
          <span style={{ ...(image ? { marginRight: "25px" } : {}) }}>
            <Image
              src={getIconHelper("CITIES&PLANS")}
              style={{ height: "35px" }}
            />
          </span>
        )}
        {image && image === true ? (
          <span style={{ ...(image ? { margin: "auto 0" } : {}) }}>
            <GetHeaderContent content={content} />
          </span>
        ) : (
          <GetHeaderContent content={content} />
        )}
      </div>
    </div>
  );
};
export default SegmentHeader;
