import { setInfoUpdated } from "@/actions/actions_info";
import { setAlertMessage, setLoader } from "@/actions/actions_user";
import config from "@/config";
import { BBOX } from "@/constants";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import { useDispatch } from "react-redux";

export function useSearchBarGeocoder() {
  const dispatch = useDispatch();

  const addSearchBarGeocoderToMap = (map: any) => {
    // https://docs.mapbox.com/api/search/search-box/
    const mapboxExcludeCategories = ["approximate", "street"];
    const filter = (item) => {
      const accuracy = item?.properties?.accuracy;
      if (mapboxExcludeCategories.includes(accuracy)) {
        return false;
      }
      return true;
    };

    //render geocoder list items according to requirements
    const renderItem = (item) => {
      const place_name = item.place_name.replace(", United States", "");
      // extract the item's maki icon or use a default
      return `<div class='geocoder-dropdown-item'>
      <span class='geocoder-dropdown-text'>
      ${place_name}
      </span>
    </div>`;
    };

    const searchBarGeocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: "Search for an address here...",
      marker: false,
      countries: "us",
      bbox: BBOX,
      proximity: config.PROXIMITY,
      zoom: 18,
      types: "address",
      filter: (item) => filter(item),
      render: (item) => renderItem(item)
    }).on("result", (e) => {
      dispatch(setLoader(true));
      map.once("idle", () => {
        const thisTaxLot = map.queryRenderedFeatures(
          map.project(e.result.center),
          {
            layers: config.MB_TILES_LIST.includes(",")
              ? config.MB_TILES_LIST.split(",")
              : config.MB_TILES_LIST.split(",")
          }
        );
        if (thisTaxLot && thisTaxLot.length > 0) {
          map.fire("click", {
            lngLat: e.result.center,
            point: map.project(e.result.center),
            originalEvent: { type: "search" }
          });
        } else {
          dispatch(setInfoUpdated(true));
          dispatch(
            setAlertMessage("No zoning details found for this location.")
          );
          dispatch(setLoader(false));
        }
      });
    });

    const elementExists =
      document.getElementById("mapbox-geocoder-info").childElementCount > 0;
    if (elementExists) {
      return;
    }

    document
      .getElementById("mapbox-geocoder-info")
      .appendChild(searchBarGeocoder.onAdd(map));

    searchBarGeocoder._inputEl.addEventListener("focus", () => {
      // if there was a list before, just display it, so the user can quickly choose a different result
      if (searchBarGeocoder._typeahead.data.length) {
        searchBarGeocoder._typeahead.data !== "" &&
          searchBarGeocoder._typeahead.update(
            searchBarGeocoder._typeahead.data
          );
      } else {
        // otherwise, get and show results
        searchBarGeocoder._inputEl.value !== "" &&
          searchBarGeocoder._geocode(searchBarGeocoder._inputEl.value);
      }
    });

    return searchBarGeocoder;
  };

  return addSearchBarGeocoderToMap;
}
