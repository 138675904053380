import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Icon, Input } from "semantic-ui-react";
import { changePassword, setLoader } from "../../../actions/actions_user";
import { CHANGE_PASSWORD } from "../../../constants";
import "../../../css/ForgetPassword.css";

const ChangePassword = ({ dispatch }) => {
  const { token } = useSelector((state) => state.user);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [currentPasswordTouched, setCurrentPasswordTouched] = useState(false);
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    name === "current_password" && setCurrentPassword(value);
    name === "password" && setPassword(value);
    name === "confirm_password" && setConfirmPassword(value);
  };
  const handleSubmitForm = () => {
    if (
      password !== confirmPassword ||
      password === "" ||
      confirmPassword === ""
    ) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }
    if (currentPassword === "") {setCurrentPasswordTouched(true);}
    else {setCurrentPasswordTouched(false);}
    const updatePassword = {
      current_password: currentPassword,
      new_password: password
    };
    if (!passwordMismatch && currentPassword !== "") {
      dispatch(setLoader(true));
      dispatch(changePassword(updatePassword, token));
    }
  };
  return (
    <div className="forget-password">
      <div className="header">
        <Icon disabled name="repeat" className="forget-icon" />
        <h4>Change Password</h4>
      </div>
      <div className="forget-password-section">
        <Form>
          {CHANGE_PASSWORD.map((field) => {
            return (
              <Form.Field
                error={
                  (passwordMismatch &&
                    (field.name === "confirm_password" ||
                      field.name === "password")) ||
                  (currentPassword === "" &&
                    field.name === "current_password" &&
                    currentPasswordTouched)
                }
              >
                <Input
                  transparent
                  name={field.name}
                  type={field.type}
                  placeholder={field.placeHolder}
                  onChange={handleFieldChange}
                />
                {passwordMismatch && field.name === "confirm_password" && (
                  <span>Password Mismatch.</span>
                )}
                {currentPassword === "" &&
                  field.name === "current_password" &&
                  currentPasswordTouched && (
                    <span>Current Password is required.</span>
                  )}
              </Form.Field>
            );
          })}
          <div className="forget-password-btn">
            <Button fluid onClick={handleSubmitForm}>
              Change Password
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default ChangePassword;
