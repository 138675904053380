export const ausConfig: RegionConfig = {
  MAPBOX_STYLE_URL_BASIC: "mapbox://styles/qvt282/clquwzt5100zz01nwhxh96et0", //multi-region-style
  MAPBOX_STYLE_URL_SATELLITE:
    "mapbox://styles/qvt282/clfq1w5fd002501nsr1y9tdv8",
  MAPBOX_STYLE_URL_MBENVELOPE:
    "mapbox://styles/qvt282/clfq1wuw2000n01n2982xsf7w",
  MAPBOX_LAYER_NAME: "aus_taxlots",
  BBOX: "-98.074951, 30.086919, -97.520828, 30.534468",
  MAP_CENTER: "-97.743559, 30.270928",
  REGION: {
    slug: "austin",
    name: "Austin, Texas"
  },
  JURISDICTIONS: [
    // NB it is a coincidence that the portland jurisdiction has the same name as the portland region
    {
      slug: "austin",
      name: "Austin, Texas"
    }
  ],
  OTHER_REGIONS:
    '{ "portland": [], "seattle": [], "san_diego": [], "austin": [] }',
  PROXIMITY: "-97.743559, 30.270928",
  MB_TILES_LIST: "taxlots-fill",
  ZOOM_LEVEL: 11
};
