import {
  SET_FLAG_FILE,
  SET_SAVED_PROPERTY_DEFAULT_VALUE,
  SET_SAVED_PROPERTY_VALUE
} from "../actions/actions_save_property";

const initialState = {
  savedDefaultValues: {},
  setFlagFile: false
};

const property = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SAVED_PROPERTY_DEFAULT_VALUE:
      var newState = JSON.parse(JSON.stringify(state));
      newState.savedDefaultValues = payload;
      return newState;
    case SET_SAVED_PROPERTY_VALUE:
      var newState = JSON.parse(JSON.stringify(state));
      var thisSavedData = payload.data;
      newState.savedDefaultValues = thisSavedData;
      return newState;
    case SET_FLAG_FILE:
      var newState = JSON.parse(JSON.stringify(state));
      newState.setFlagFile = payload;
      return newState;
    default:
      return state;
  }
};

export default property;
