import { useSelector } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import "../../../../css/Total.css";
import Container from "../Container";
import Header from "../Header";
import SliderSection from "../SliderSection";
const UnitsFilter = ({ dispatch }) => {
  const { units } = useSelector((state) => state.filters.filterValues);

  return (
    <>
      {units.map((field) => {
        return (
          <>
            <Container>
              <Header content={field} />
              <div className="slider-section">
                <SliderSection
                  range={field.range}
                  content={field.fields}
                  selectedFilter={units}
                  dispatch={dispatch}
                  checkboxField={field.checkbox}
                  maxRangeValue={field?.maxValue ?? ""}
                  columnName={field.column_name}
                />
              </div>
              <br />
            </Container>
          </>
        );
      })}
      ;
    </>
  );
};
export default UnitsFilter;
