import { Range } from "rc-slider";
import { Checkbox, Grid } from "semantic-ui-react";
import useInfoMaxUnit from "../../../../hooks/useInfoMaxUnit";

export default function InfoMaxUnit(props) {
  const {
    defaultValue,
    label,
    name,
    range = true,
    line,
    min,
    max,
    getSliderValue,
    disabled,
    useExistingBldgSf = false,
    setState,
    step
  } = props;
  const {
    value,
    setvalue,
    useDefault,
    setuseDefault,
    onSliderChange,
    handleChange
  } = useInfoMaxUnit(props);

  return (
    <div className="slider" style={{ padding: "0 0px 0 31px" }}>
      <p className="heading6 value-title">{label}:</p>
      <Grid verticalAlign="middle" centered={range}>
        <Grid.Row>
          <Grid.Column width={8}>
            <input
              disabled={disabled || useDefault}
              min={min}
              max={max}
              value={props.value ?? value}
              type="number"
              onChange={handleChange}
              className="paragraph1 fields value-02"
              name={name}
              style={{
                padding: "5px",
                width: "100%",
                border: "1px solid rgba(34, 36, 38, 0.35)"
              }}
            />
          </Grid.Column>
          {range && (
            <Grid.Column width={8}>
              <Range
                step={step}
                defaultValue={[props.value || value]}
                min={min}
                max={max}
                allowCross={false}
                value={[props.value ?? value]}
                trackStyle={[{ backgroundColor: "#bfbfbf" }]}
                handleStyle={[
                  {
                    backgroundColor: "#fff",
                    border: "5px solid rgb(0, 212, 255)",
                    boxShadow: "0px 1px 2px 0px,0px 0px 0px 1px"
                  },
                  {
                    backgroundColor: "#fff",
                    border: "5px solid rgb(255, 0, 135)",
                    boxShadow: "0px 1px 2px 0px,0px 0px 0px 1px"
                  }
                ]}
                onChange={onSliderChange}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      {useExistingBldgSf && (
        <div style={{ margin: "10px 0" }}>
          <Checkbox
            label="Use existing bldg SF"
            name="useExistingBldgSf"
            checked={useDefault}
            onChange={(e, data) => {
              if (data.checked) {
                setvalue(defaultValue);
                getSliderValue(name, defaultValue);
              }
              setState((prev) => ({
                ...prev,
                useExistingBldgSf: data.checked
              }));
              setuseDefault(data.checked);
            }}
          />
        </div>
      )}
      {line && <hr style={{ margin: "10px 0" }} />}
    </div>
  );
}
