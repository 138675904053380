import { useDispatch, useSelector } from "react-redux";
import { Image } from "semantic-ui-react";
import {
  setDataWindow,
  setDataWindowContent
} from "../../../actions/actions_datawindow";
import { PLANS } from "../../../constants";
import "../../../css/Dashboard.css";
import ICON_UPGRADE from "../../../images/upgrade-icon.svg";
import MarkedCitiesAndPlans from "./payment/MarkedCitiesAndPlans";
import SegmentHeader from "./SegmentHeader";

const CitiesAndPlans = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const { citiesAndPlans } = useSelector((state) => state.plans);

  const getActiveContent = () => {
    let active = "pro";
    Object.values(citiesAndPlans).map((plans) => {
      plans.forEach((plan) => {
        if (plan.isChecked && plan.code !== "basic") {
          if (active !== "ent") {active = plan.code;}
        }
      });
    });
    return active;
  };

  const handleAddMore = () => {
    dispatch(
      setDataWindow({
        dataWindow: true,
        content: {
          isContact: null,
          isForce: true,
          choosePlan: { plans: PLANS, activeContent: getActiveContent() }
        }
      })
    );
    dispatch(
      setDataWindowContent({
        show: { showData: "choose-plan", showControl: "choose-plan" }
      })
    );
  };

  return (
    <>
      <div className="acc_data_window">
        <h1 style={{ color: "#000", fontFamily: "Inter-Black" }}>
          Hello, {user?.first_name}!
        </h1>
        <div className="property-data">
          <SegmentHeader
            image={true}
            content={{ productName: "Cities & plans" }}
          />
          <MarkedCitiesAndPlans allowAll={true} />
        </div>
        <div className="upgrade-container">
          <div className="upgrade-plan-btn">
            <a
              className="contact-support-button"
              target="_blank"
              href="mailto:support@urbanform.us"
              rel="noreferrer"
            >
              <Image src={ICON_UPGRADE} />
              <span>UPGRADE</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default CitiesAndPlans;
