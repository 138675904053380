import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Button, Image } from "semantic-ui-react";
import {
  setDataWindow,
  setDataWindowContent
} from "../../../../actions/actions_datawindow";
import { PLANS } from "../../../../constants";
import "../../../../css/DownloadButton.css";
import "../../../../css/PurchaseInfo.css";
import card from "../../../../images/credit-card.svg";
import ICON_LOGIN from "../../../../images/login-key.svg";

const PurchaseInfo = ({ dispatch, info, setCurrentContent }) => {
  const { photo_ref } = useSelector((state) => state.info);
  const imageWidth = isMobile ? "247x295" : "321x295";
  const { token, user, quotaExceed } = useSelector((state) => state.user);

  const upgradeAccount = () => {
    dispatch(
      setDataWindow({
        dataWindow: true,
        content: {
          isContact: null,
          isForce: true,
          choosePlan: {
            plans: PLANS,
            activeContent: user.status === "Basic" ? "pro" : "ent"
          },
          downloadPdf: true
        }
      })
    );
    dispatch(
      setDataWindowContent({
        show: { showData: "choose-plan", showControl: "choose-plan" }
      })
    );
  };
  const proceedOneTimePurchase = () => {
    dispatch(
      setDataWindow({
        dataWindow: true,
        content: {
          isContact: null,
          isForce: true,
          isPayment: true,
          oneTimePayment: true,
          choosePlan: { plans: "", activeContent: "" }
        }
      })
    );
    dispatch(
      setDataWindowContent({
        show: { showData: "payment", showControl: "info" }
      })
    );
  };
  const accountCreate = () => {
    setCurrentContent("User");
    dispatch(
      setDataWindowContent({ show: { showData: "", showControl: "login" } })
    );
    dispatch(
      setDataWindow({
        dataWindow: true,
        content: { isContact: null, choosePlan: {} }
      })
    );
  };
  return (
    <div className="purchase-info">
      <div className="image-box">
        <img
          src={
            "https://maps.googleapis.com/maps/api/streetview?size=" +
            imageWidth +
            "&location=" +
            photo_ref +
            "&key=AIzaSyCMSrRiLxLUnqLB5aKrQrxw-9Qhg9WnZ1g"
          }
          style={{ width: window.innerWidth <= 768 ? " 87%" : "100%" }}
        />
      </div>
      <div className="download-report">
        <div className="download-card">
          <div className="download-header">
            <h3>Download Report</h3>
          </div>
          <div className="download-body">
            <p>
              To download the report, please log into your account. If you don’t
              have an account you can sign up for one now, or you can continue
              as a guest to download without signing up.
            </p>
          </div>
        </div>
      </div>
      <div className="download-btns">
        <div className="btn-moveto-login">
          <Button fluid size="large" onClick={accountCreate}>
            <Image src={ICON_LOGIN} />
            <span>
              Login or <br />
              create account{" "}
            </span>
          </Button>
        </div>
        <div className="btn-guest">
          <Button fluid size="large" onClick={proceedOneTimePurchase}>
            <Image src={card} />
            <span>
              guest <br />
              checkout{" "}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseInfo;
