import { useSelector } from "react-redux";
import "../../../../css/use.css";
import CheckBoxGrid from "../CheckBoxGrid";
import Container from "../Container";
import Header from "../Header";

const Use = ({ dispatch }) => {
  const { use } = useSelector((state) => state.filters.filterValues);
  const { dynoUseRegulationsFilters } = useSelector((state) => state.DynoInfo);

  return (
    <>
      {use.map((info, index) => {
        if (index !== 0) {
          info.label = "Select/Unselect all";
        }
        return (
          <div className="use-filter" key={index}>
            <Container>
              {dynoUseRegulationsFilters && (
                <Header
                  content={{
                    title:
                      dynoUseRegulationsFilters[index]?.name ===
                      "include_conditional"
                        ? "USE"
                        : dynoUseRegulationsFilters[index]?.name,
                    subTitle: "Filter by allowable uses",
                    className: "use-sub-title"
                  }}
                />
              )}
              <div className="use-fields">
                <CheckBoxGrid
                  info={info}
                  dispatch={dispatch}
                  columns={1}
                  selectedFilter={use}
                />
              </div>
            </Container>
          </div>
        );
      })}
    </>
  );
};

export default Use;
