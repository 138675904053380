import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import "../../../css/InfoFontStyle.css";
import "../../../css/InfoSection.css";
import "../../../css/InfoTabs.css";
import ICON_LOCK from "./../../../images/lock.jpg";
const InfoSection = ({ content }) => {
  const { token } = useSelector((state) => state.user);

  if (token != null) {
    return (
      <div className="section">
        {content?.map((result) => {
          if (Object.keys(result).includes("sourceHr") && result.sourceHr) {
            if (result?.title) {delete result["title"];}
          }
          return (
            <>
              {!result?.text &&
                result?.title &&
                result.title != "" &&
                result.display &&
                result.title !== null && (
                  <div className="info-tabs">
                    <div className="card-label">
                      <p>
                        {result?.small_heading}
                        <span
                          style={{
                            position: "relative",
                            wordBreak: "break-word",
                            fontStyle:
                              result.applyItalic === true ? "italic" : "normal",
                            fontWeight: result.applyItalic ? "unset" : "bold",
                            marginLeft: result.indent === true ? "0px" : "7px"
                            // fontStyle: result?.style?.fontStyle ?? "italic",
                            // fontStyle: (result?.properties && result?.properties.find(prop => prop.style))?.style?.fontStyle ?? "italic",
                            // fontWeight: result?.property_class === "coverage_land_detail_c" ? "unset" : result?.property_class === "far_base_b" ? "unset" : result?.property_class === "far_base_c" ? "unset" : result?.property_class === "height_limit_base_b" ? "unset" : result?.property_class === "height_limit_base_c" ? "unset" : result?.title_desc ? "unset": "bold",
                            // display:  result?.property_class === "residential_a" ? "flex" :  result?.property_class === "residential_b"  ? "flex" : result?.property_class === "commercial_A"  ? "flex" : result?.property_class === "commercial_b"  ? "flex" : "unset",
                            // width:  result?.property_class === "residential_a" ? "90%" :  result?.property_class === "residential_b"  ? "90%" : result?.property_class === "commercial_A"  ? "90%" : result?.property_class === "commercial_b"  ? "90%" : "unset"
                          }}
                        >
                          {result.title}

                          {/* title text */}
                          {result?.title && result.title != "-" && (
                            <span
                              className="title-text"
                              style={{
                                width: isMobile == true ? "89%" : "",
                                wordBreak: isMobile == true ? "break-word" : ""
                              }}
                            >
                              {" "}
                              {result.title_text}
                            </span>
                          )}

                          {/* use A,CL,P */}
                          {result?.title_desc_inline && (
                            <span
                              className="use-small-text"
                              style={{
                                position: "absolute",
                                left: "90%",
                                width: isMobile == true ? "89%" : "",
                                wordBreak: isMobile == true ? "break-word" : ""
                              }}
                            >
                              {result.title_desc_inline}
                            </span>
                          )}

                          {result?.ul === true &&
                            result.title_desc != "N/A" &&
                            result.data === true && (
                              <div className="section-header">
                                <span
                                  className={result?.small_heading_class_desc}
                                  style={{
                                    width: isMobile == true ? "89%" : "",
                                    wordBreak:
                                      isMobile == true ? "break-word" : ""
                                  }}
                                >
                                  {result?.small_heading_desc}
                                </span>
                                {!result?.category && (
                                  <ul className="ul-align">
                                    <li
                                      style={{
                                        width: isMobile == true ? "89%" : "",
                                        wordBreak:
                                          isMobile == true ? "break-word" : ""
                                      }}
                                    >
                                      {/* identifiers */}
                                      <span
                                        className="li-text"
                                        style={{ marginLeft: "unset" }}
                                      >
                                        {result.title_desc
                                          ? result.title_desc
                                          : "N/A"}{" "}
                                      </span>{" "}
                                      {result?.title_desc_inline && (
                                        <span className="">
                                          {result.title_desc_inline}
                                        </span>
                                      )}
                                    </li>
                                  </ul>
                                )}
                                <ul className="ul-align">
                                  {result?.category &&
                                    result.category.map((value) => {
                                      return (
                                        <li>
                                          <span
                                            className="li-text"
                                            style={{
                                              width:
                                                isMobile == true ? "89%" : "",
                                              wordBreak:
                                                isMobile == true
                                                  ? "break-word"
                                                  : ""
                                            }}
                                          >
                                            {value ? value : "N/A"}{" "}
                                          </span>{" "}
                                          {result?.title_desc_inline && (
                                            <span
                                              className=""
                                              style={{
                                                width:
                                                  isMobile == true ? "89%" : "",
                                                wordBreak:
                                                  isMobile == true
                                                    ? "break-word"
                                                    : ""
                                              }}
                                            >
                                              {result.title_desc_inline}
                                            </span>
                                          )}
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            )}

                          {/*  for comment N/A */}

                          {result?.ul === true && result.data === false && (
                            <div className="section-header">
                              <span className="li-text">N/A</span>
                            </div>
                          )}

                          {result?.ul === false &&
                            result.title_desc != "" &&
                            result.title_desc != "" && (
                              <>
                                <span
                                  className=""
                                  style={{
                                    width: isMobile == true ? "89%" : "",
                                    wordBreak:
                                      isMobile == true ? "break-word" : "",
                                    color: "#818286"
                                  }}
                                >
                                  {result?.small_heading_desc}
                                </span>
                                {/* units like square feet acre  */}
                                {result?.title_desc && (
                                  <span
                                    className=""
                                    style={{
                                      width: isMobile == true ? "89%" : "",
                                      wordBreak:
                                        isMobile == true ? "break-word" : "",
                                      color:
                                        result?.property_class ===
                                        "coverage_land_detail_c"
                                          ? "black"
                                          : result?.property_class ===
                                              "address_a"
                                            ? "black"
                                            : result?.property_class ===
                                                "far_base_b"
                                              ? "black"
                                              : result?.property_class ===
                                                  "far_base_c"
                                                ? "black"
                                                : result?.property_class ===
                                                    "height_limit_base_b"
                                                  ? "black"
                                                  : result?.property_class ===
                                                      "height_limit_base_c"
                                                    ? "black"
                                                    : "#818286",
                                      opacity: "unset"
                                    }}
                                  >
                                    {result.title_desc}
                                  </span>
                                )}
                              </>
                            )}

                          {/* title zip code using in Address */}

                          {result?.title_zip_code && (
                            <>
                              {result?.title_zip_code && (
                                <p
                                  style={{
                                    display: "contents",
                                    fontWeight: "bold",
                                    width: isMobile == true ? "89%" : "",
                                    wordBreak:
                                      isMobile == true ? "break-word" : ""
                                  }}
                                >
                                  {result.title_zip_code}
                                </p>
                              )}
                            </>
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                )}

              {/* source visible only when there's value in it */}

              {result?.source_p && result?.display && (
                <div className="main-source">
                  <span>Source:</span>
                  <div className="src_body">
                    <u>
                      <a
                        href={result.link}
                        title={result.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {result.source_p}
                      </a>
                    </u>
                  </div>
                </div>
              )}

              {/* source when there is text instead of numbers */}
              {result?.source_text && result?.display && (
                <div className="property-source-text">
                  <span className="small-heading-class link-title">
                    Source:
                  </span>
                  <a
                    href={result?.link ?? "#"}
                    title={result?.link ?? "#"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {result.source_text}
                  </a>
                </div>
              )}

              {/* horizontal line */}
              {result?.source_p != null ||
                (result.onlyLine == true && result?.display && (
                  <div className="sourceHr" style={result?.customStyle ?? {}}>
                    {result?.sourceHr === true && (
                      <hr style={{ width: isMobile == true ? "215px" : "" }} />
                    )}
                  </div>
                ))}

              {result?.display == true && (
                <div className={[`${result?.property_class} section-data`]}>
                  {result?.text &&
                  result?.identity === "dynamic-entry" &&
                  result.text.length > 0
                    ? result?.text.map((value, index) => {
                        return (
                          <>
                            <div className="section-header">
                              <span
                                className={result?.small_heading_class}
                                style={{
                                  width: isMobile == true ? "89%" : "",
                                  wordBreak:
                                    isMobile == true ? "break-word" : ""
                                }}
                              >
                                {result?.small_heading}
                              </span>
                              <h2
                                className={[
                                  `${result?.fontClass} heading-text`
                                ]}
                                style={{
                                  width: isMobile == true ? "89%" : "",
                                  wordBreak:
                                    isMobile == true ? "break-word" : ""
                                }}
                              >
                                {value}{" "}
                                {result?.title && result.title != "-" && (
                                  <span
                                    style={{
                                      width: isMobile == true ? "89%" : "",
                                      wordBreak:
                                        isMobile == true ? "break-all" : ""
                                    }}
                                    className="title-text"
                                  >
                                    {result.title_text}
                                  </span>
                                )}{" "}
                                {result?.title_desc_inline && (
                                  <span
                                    className={[
                                      `${result?.fontClassInline} heading-inline-desc`
                                    ]}
                                    style={{
                                      width: isMobile == true ? "89%" : "",
                                      wordBreak:
                                        isMobile == true ? "break-word" : ""
                                    }}
                                  >
                                    {result.title_desc_inline}
                                  </span>
                                )}
                              </h2>
                            </div>
                            {result?.property_source && (
                              <div
                                className={[
                                  `${result.property_class} property-main-more`
                                ]}
                              >
                                <span
                                  className="small-heading-class link-title"
                                  style={{
                                    width: isMobile == true ? "89%" : "",
                                    wordBreak:
                                      isMobile == true ? "break-word" : ""
                                  }}
                                >
                                  Source:
                                </span>
                                {/* <div className="property-link-image">
                                  <img src={LinkImage} alt="" width="100%" />
                                </div> */}
                                <div className="property-more">
                                  <u>
                                    <a
                                      href={result.source_link}
                                      title={result.source_link}
                                      target="_blank"
                                      style={{
                                        width: isMobile == true ? "89%" : "",
                                        wordBreak:
                                          isMobile == true ? "break-word" : ""
                                      }}
                                      rel="noreferrer"
                                    >
                                      {result.property_source}
                                    </a>
                                  </u>
                                </div>
                              </div>
                            )}
                            {index + 1 !== result?.text.length &&
                              result?.horizontalRow === true && (
                                <hr style={{ marginTop: "0" }} />
                              )}
                          </>
                        );
                      })
                    : result?.identity === "dynamic-entry" && (
                        <h2
                          className={[`${result?.fontClass} heading-text`]}
                          style={{
                            width: isMobile == true ? "89%" : "",
                            wordBreak: isMobile == true ? "break-word" : ""
                          }}
                        >
                          N/A
                        </h2>
                      )}
                  {result?.ul === true &&
                    result.title_desc != "N/A" &&
                    result.data === true && (
                      <div className="section-header">
                        <span
                          className={result?.small_heading_class_desc}
                          style={{
                            width: isMobile == true ? "89%" : "",
                            wordBreak: isMobile == true ? "break-word" : ""
                          }}
                        >
                          {result?.small_heading_desc}
                        </span>
                        {!result?.category && (
                          <ul className="ul-align">
                            <li
                              style={{
                                width: isMobile == true ? "89%" : "",
                                wordBreak: isMobile == true ? "break-word" : ""
                              }}
                            >
                              <span className="li-text">
                                {result.title_desc ? result.title_desc : "N/A"}{" "}
                              </span>{" "}
                              {result?.title_desc_inline && (
                                <span className="">
                                  {result.title_desc_inline}
                                </span>
                              )}
                            </li>
                          </ul>
                        )}
                        <ul className="ul-align">
                          {result?.category &&
                            result.category.map((value) => {
                              return (
                                <li>
                                  <span
                                    className="li-text"
                                    style={{
                                      width: isMobile == true ? "89%" : "",
                                      wordBreak:
                                        isMobile == true ? "break-word" : ""
                                    }}
                                  >
                                    {value ? value : "N/A"}{" "}
                                  </span>{" "}
                                  {result?.title_desc_inline && (
                                    <span
                                      className=""
                                      style={{
                                        width: isMobile == true ? "89%" : "",
                                        wordBreak:
                                          isMobile == true ? "break-word" : ""
                                      }}
                                    >
                                      {result.title_desc_inline}
                                    </span>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                  {result?.hr === true && <hr />}
                  {result?.title_cat && result.title_cat != "" && (
                    <div className="section-description">
                      <p
                        className={result.subClassName}
                        style={{
                          width: isMobile == true ? "89%" : "",
                          wordBreak: isMobile == true ? "break-word" : ""
                        }}
                      >
                        {result.title_cat}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  } else {
    return (
      <div className="section">
        {content.map((result) => {
          if (result.user == "not-login") {
            return (
              <>
                <div className="section-data">
                  <div className="section-description">
                    <img src={ICON_LOCK} className="lock-image" alt="" />
                    {result?.title_desc && (
                      <p
                        className={result.className}
                        style={{
                          width: isMobile == true ? "89%" : "",
                          wordBreak: isMobile == true ? "break-word" : ""
                        }}
                      >
                        {result.title_desc}
                      </p>
                    )}
                  </div>
                </div>
              </>
            );
          } else {
            return (
              <>
                {!result?.text &&
                  result?.title &&
                  result?.display &&
                  result.title != "" &&
                  result.title !== null && (
                    <div className="info-tabs">
                      <div className="card-label">
                        <p>
                          {result?.small_heading}
                          <span
                            style={{
                              position: "relative",
                              fontStyle:
                                result.applyItalic === true
                                  ? "italic"
                                  : "normal",
                              fontWeight: result.applyItalic ? "unset" : "bold",
                              marginLeft: result.indent === true ? "0px" : "7px"
                            }}
                          >
                            {result.title}

                            {/* use A,CL,P */}
                            {result?.title_desc_inline && (
                              <span
                                className="use-small-text"
                                style={{
                                  position: "absolute",
                                  left: "90%",
                                  width: isMobile == true ? "89%" : "",
                                  wordBreak:
                                    isMobile == true ? "break-word" : ""
                                }}
                              >
                                {result.title_desc_inline}
                              </span>
                            )}

                            {result?.ul === true &&
                              result.title_desc != "N/A" &&
                              result.data === true && (
                                <div className="section-header">
                                  <span
                                    className={result?.small_heading_class_desc}
                                    style={{
                                      width: isMobile == true ? "89%" : "",
                                      wordBreak:
                                        isMobile == true ? "break-word" : ""
                                    }}
                                  >
                                    {result?.small_heading_desc}
                                  </span>
                                  {!result?.category && (
                                    <ul className="ul-align">
                                      <li
                                        style={{
                                          width: isMobile == true ? "89%" : "",
                                          wordBreak:
                                            isMobile == true ? "break-word" : ""
                                        }}
                                      >
                                        <span className="li-text">
                                          {result.title_desc
                                            ? result.title_desc
                                            : "N/A"}{" "}
                                        </span>{" "}
                                        {result?.title_desc_inline && (
                                          <span className="">
                                            {result.title_desc_inline}
                                          </span>
                                        )}
                                      </li>
                                    </ul>
                                  )}
                                  <ul className="ul-align">
                                    {result?.category &&
                                      result.category.map((value) => {
                                        return (
                                          <li>
                                            <span
                                              className="li-text"
                                              style={{
                                                width:
                                                  isMobile == true ? "89%" : "",
                                                wordBreak:
                                                  isMobile == true
                                                    ? "break-word"
                                                    : ""
                                              }}
                                            >
                                              {value ? value : "N/A"}{" "}
                                            </span>{" "}
                                            {result?.title_desc_inline && (
                                              <span
                                                className=""
                                                style={{
                                                  width:
                                                    isMobile == true
                                                      ? "89%"
                                                      : "",
                                                  wordBreak:
                                                    isMobile == true
                                                      ? "break-word"
                                                      : ""
                                                }}
                                              >
                                                {result.title_desc_inline}
                                              </span>
                                            )}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              )}

                            {/*  for comment N/A */}

                            {result?.ul === true && result.data === false && (
                              <div className="section-header">
                                <span className="li-text">N/A</span>
                              </div>
                            )}

                            {result?.ul === false &&
                              result.title_desc != "" &&
                              result.title_desc != "" && (
                                <>
                                  <span
                                    className=""
                                    style={{
                                      width: isMobile == true ? "89%" : "",
                                      wordBreak:
                                        isMobile == true ? "break-word" : "",
                                      color: "#818286"
                                    }}
                                  >
                                    {result?.small_heading_desc}
                                  </span>
                                  {/* units like square feet acre  */}
                                  {result?.title_desc && (
                                    <span
                                      className=""
                                      style={{
                                        width: isMobile == true ? "89%" : "",
                                        wordBreak:
                                          isMobile == true ? "break-word" : "",
                                        color:
                                          result?.property_class ===
                                          "coverage_land_detail_c"
                                            ? "black"
                                            : result?.property_class ===
                                                "address_a"
                                              ? "black"
                                              : result?.property_class ===
                                                  "far_base_b"
                                                ? "black"
                                                : result?.property_class ===
                                                    "far_base_c"
                                                  ? "black"
                                                  : result?.property_class ===
                                                      "height_limit_base_b"
                                                    ? "black"
                                                    : result?.property_class ===
                                                        "height_limit_base_c"
                                                      ? "black"
                                                      : "#818286"
                                      }}
                                    >
                                      {result.title_desc}
                                    </span>
                                  )}
                                </>
                              )}

                            {/* title zip code using in Address */}

                            {result?.title_zip_code && (
                              <>
                                {result?.title_zip_code && (
                                  <p
                                    style={{
                                      display: "contents",
                                      fontWeight: "bold",
                                      width: isMobile == true ? "89%" : "",
                                      wordBreak:
                                        isMobile == true ? "break-word" : ""
                                    }}
                                  >
                                    {result.title_zip_code}
                                  </p>
                                )}
                              </>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}

                {/* source visible only when there's value in it */}

                {result?.source_p && result?.display && (
                  <div className="main-source">
                    <span>Source:</span>
                    <div className="src_body">
                      <u>
                        <a
                          href={result.link}
                          title={result.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {result.source_p}
                        </a>
                      </u>
                    </div>
                  </div>
                )}

                {/* source when there is text instead of numbers */}
                {result?.source_text && result?.display && (
                  <div className="property-source-text">
                    <span className="small-heading-class link-title">
                      Source:
                    </span>
                    <a
                      href={result?.link ?? "#"}
                      title={result?.link ?? "#"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {result.source_text}
                    </a>
                  </div>
                )}

                {/* horizontal line */}
                {result?.source_p != null ||
                  (result.onlyLine == true && result?.display && (
                    <div className="sourceHr" style={result?.customStyle ?? {}}>
                      {result?.sourceHr === true && (
                        <hr
                          style={{ width: isMobile == true ? "215px" : "" }}
                        />
                      )}
                    </div>
                  ))}

                {result?.display == true && (
                  <div className={[`${result.property_class} section-data`]}>
                    {result?.text &&
                    result?.identity === "dynamic-entry" &&
                    result?.text.length > 0
                      ? result.text.map((value, index) => {
                          return (
                            <>
                              <div className="section-header">
                                <span
                                  className={result?.small_heading_class}
                                  style={{
                                    width: isMobile == true ? "89%" : "",
                                    wordBreak:
                                      isMobile == true ? "break-word" : ""
                                  }}
                                >
                                  {result?.small_heading}
                                </span>
                                <h2
                                  className={[
                                    `${result?.fontClass} heading-text`
                                  ]}
                                  style={{
                                    width: isMobile == true ? "89%" : "",
                                    wordBreak:
                                      isMobile == true ? "break-word" : ""
                                  }}
                                >
                                  {value}{" "}
                                  {result?.title && result.title != "-" && (
                                    <span
                                      style={{
                                        width: isMobile == true ? "89%" : "",
                                        wordBreak:
                                          isMobile == true ? "break-word" : ""
                                      }}
                                      className="title-text"
                                    >
                                      {result.title_text}
                                    </span>
                                  )}{" "}
                                  {result?.title_desc_inline && (
                                    <span
                                      style={{
                                        width: isMobile == true ? "89%" : "",
                                        wordBreak:
                                          isMobile == true ? "break-word" : ""
                                      }}
                                      className={[
                                        `${result?.fontClassInline} heading-inline-desc value-03`
                                      ]}
                                    >
                                      {result.title_desc_inline}
                                    </span>
                                  )}
                                </h2>
                              </div>
                              {result?.property_source && (
                                <div
                                  className={[
                                    `${result.property_class} property-main-more`
                                  ]}
                                >
                                  <span
                                    className="small-heading-class link title"
                                    style={{
                                      width: isMobile == true ? "89%" : "",
                                      wordBreak:
                                        isMobile == true ? "break-word" : ""
                                    }}
                                  >
                                    Source:
                                  </span>
                                  <div className="property-more">
                                    <u>
                                      <a
                                        href={result.source_link}
                                        title={result.property_source}
                                        target="_blank"
                                        style={{
                                          width: isMobile == true ? "89%" : "",
                                          wordBreak:
                                            isMobile == true ? "break-word" : ""
                                        }}
                                        rel="noreferrer"
                                      >
                                        {result.property_source}
                                      </a>
                                    </u>
                                  </div>
                                  <br /> <br />
                                </div>
                              )}
                              {index + 1 !== result?.text.length &&
                                result?.horizontalRow === true && (
                                  <hr style={{ marginTop: "0" }} />
                                )}
                            </>
                          );
                        })
                      : result?.identity === "dynamic-entry" && (
                          <h2
                            className={[`${result?.fontClass} heading-text`]}
                            style={{
                              width: isMobile == true ? "89%" : "",
                              wordBreak: isMobile == true ? "break-word" : ""
                            }}
                          >
                            N/A
                          </h2>
                        )}
                    {result?.ul === true &&
                      result.title_desc != "N/A" &&
                      result.data === true && (
                        <div className="section-header">
                          <span
                            className={result?.small_heading_class_desc}
                            style={{
                              width: isMobile == true ? "89%" : "",
                              wordBreak: isMobile == true ? "break-word" : ""
                            }}
                          >
                            {result?.small_heading_desc}
                          </span>
                          {!result?.category && (
                            <ul className="ul-align">
                              <li
                                style={{
                                  width: isMobile == true ? "89%" : "",
                                  wordBreak:
                                    isMobile == true ? "break-word" : ""
                                }}
                              >
                                <span
                                  className="li-text"
                                  style={{
                                    width: isMobile == true ? "89%" : "",
                                    wordBreak:
                                      isMobile == true ? "break-word" : ""
                                  }}
                                >
                                  {result.title_desc
                                    ? result.title_desc
                                    : "N/A"}{" "}
                                </span>{" "}
                                {result?.title_desc_inline && (
                                  <span
                                    className=""
                                    style={{
                                      width: isMobile == true ? "89%" : "",
                                      wordBreak:
                                        isMobile == true ? "break-word" : ""
                                    }}
                                  >
                                    {result.title_desc_inline}
                                  </span>
                                )}
                              </li>
                            </ul>
                          )}
                          <ul className="ul-align">
                            {result?.category &&
                              result.category.map((value) => {
                                return (
                                  <li>
                                    <span
                                      className="li-text"
                                      style={{
                                        width: isMobile == true ? "89%" : "",
                                        wordBreak:
                                          isMobile == true ? "break-word" : ""
                                      }}
                                    >
                                      {value ? value : "N/A"}{" "}
                                    </span>{" "}
                                    {result?.title_desc_inline && (
                                      <span className="">
                                        {result.title_desc_inline}
                                      </span>
                                    )}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}
                    {result?.hr === true && <hr />}
                    {result?.title_cat && result.title_cat.trim() != "" && (
                      <div className="section-description">
                        <p
                          className={result.subClassName}
                          style={{
                            width: isMobile == true ? "89%" : "",
                            wordBreak: isMobile == true ? "break-word" : ""
                          }}
                        >
                          {result.title_cat}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          }
        })}
      </div>
    );
  }
};
export default InfoSection;
