import { useState } from "react";
import { useSelector } from "react-redux";
import { Accordion, Icon } from "semantic-ui-react";
import "../../../../css/zone.css";
import Header from "../Header";
import SingleCheckBox from "../SingleCheckBox";
import LSCheckbox from "./LSCheckbox";

const Location = ({ dispatch }) => {
  const [activeIndexes, setActiveIndexes] = useState([0]);

  const handleAccordionClick = (index) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes(activeIndexes.filter((item) => item !== index));
    } else {
      setActiveIndexes([...activeIndexes, index]);
    }
  };

  const [size, setSize] = useState("middle");
  const { location } = useSelector((state) => state.filters.filterValues);

  return (
    <div className="zone-general" style={{ marginTop: "22px" }}>
      {location.map((info, index) => {
        const isCheckboxLength2 = info.checkbox.length === 2;

        return (
          <div className="zone-filter" key={index}>
            <Accordion styled>
              <Accordion.Title onClick={() => handleAccordionClick(index)}>
                <Header content={info} />
                <Icon
                  name={
                    activeIndexes.includes(index) ? "angle down" : "angle right"
                  }
                />
              </Accordion.Title>
              <Accordion.Content active={activeIndexes.includes(index)}>
                <div className="zone-fields">
                  {isCheckboxLength2 ? (
                    <SingleCheckBox
                      info={info}
                      dispatch={dispatch}
                      columns={1}
                      selectedFilter={location}
                    />
                  ) : (
                    <LSCheckbox
                      info={info}
                      dispatch={dispatch}
                      columns={1}
                      selectedFilter={location}
                    />
                  )}
                </div>
              </Accordion.Content>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default Location;
