import { useState } from "react";
import { useDispatch } from "react-redux";
import { Image } from "semantic-ui-react";
import {
  setDataWindow,
  setDataWindowContent
} from "../../actions/actions_datawindow";
import config from "../../config";
import "../../css/DefaultDataWindowContent.css";
import ICON_BULB from "../../images/bulb.svg";
import ICON_COMPUTER from "../../images/password-desktop-approved.svg";

const DefaultInfoContent = () => {
  const [currentContent, setCurrentContent] = useState("Help");

  const dispatch = useDispatch();

  const switchToHelp = () => {
    setCurrentContent("Help");
    dispatch(
      setDataWindow({
        dataWindow: true,
        content: {
          isContact: null,
          contactInfo: {},
          choosePlan: {},
          isPayment: false
        }
      })
    );
    dispatch(
      setDataWindowContent({ show: { showData: "Help", showControl: "" } })
    );
  };

  return (
    <>
      <div className="default-info" style={{ marginTop: "22px" }}>
        <div className="d-info-card">
          <div className="d-info-about-header">
            <h3>
              UrbanForm -{" "}
              <span style={{ textTransform: "uppercase" }}>
                {config.REGION.name.split(",")[0]}
              </span>
            </h3>
          </div>
          <div className="d-info-about-body">
            <Image src={ICON_COMPUTER} />
            <p>
              <b>
                <span style={{ textTransform: "capitalize" }}>
                  {config.REGION.name.split(",")[0]}
                </span>{" "}
                Zoning Map | UrbanForm
              </b>
              <br />
              Find all zoning details for any address in{" "}
              <span style={{ textTransform: "capitalize" }}>
                {" "}
                {config.REGION.name}
              </span>{" "}
              using this interactive zoning map.
              <br /> <br />
              Details include base zones, overlay zones, plan districts, FAR,
              coverage, setbacks, height limits, unit minimums and maximums,
              calculated maximum build areas, and much more!
              <br /> <br />
              <b>UrbanForm</b> provides building professionals with accurate,
              efficient, and verifiable zoning information.
              <br /> <br />
              Learn more by going to our{" "}
              <a style={{ cursor: "pointer" }} onClick={() => switchToHelp()}>
                Help{" "}
              </a>
              page, or by going to:
              <br />
              <a href="https://urbanform.us" target="_blank" rel="noreferrer">
                https://urbanform.us
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="default-info">
        <div className="d-info-card">
          <div className="d-info-header">
            <h3>Get Started</h3>
          </div>
          <div className="d-info-body">
            <Image src={ICON_BULB} />
            <p>
              Get started with UrbanForm by searching for an address above or
              clicking on a property on the map.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultInfoContent;
