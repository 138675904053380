import { useSelector } from "react-redux";
import { Button, Image } from "semantic-ui-react";
import fly from "../../images/map-email.svg";

type RegionLink = {
  name: string;
  url: string;
};

export function RegionLinks() {
  const { dynoButtonsResponse } = useSelector((state) => state.DynoInfo);

  const handleClickGotoRegionButton = (url: string) => {
    const token = localStorage.getItem("_uft");

    const updatedSiteAddress = token ? url + "?t=" + token : url + "?s=expired";
    window.open(updatedSiteAddress, "_self");
  };

  return (
    <>
      {Array.isArray(dynoButtonsResponse) && (
        <div className="city-switcher">
          {dynoButtonsResponse.map((region: RegionLink) => (
            <div key={region.name}>
              <Button
                fluid
                className="value-03"
                basic
                onClick={() => handleClickGotoRegionButton(region.url)}
              >
                <Image src={fly} />
                <span>Go to {region.name}</span>
              </Button>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
