import { Image } from "semantic-ui-react";
import "../../css/DefaultDataWindowContent.css";
import ICON_BULB from "../../images/bulb.svg";

const DefaultFilterContent = () => {
  return (
    <div className="default-filter">
      <div className="d-filter-card">
        <div className="d-filter-header">
          <h3>Form Filter</h3>
        </div>
        <div className="d-filter-body">
          <Image src={ICON_BULB} />
          <p>
            Search, filter, and sort through properties in jurisdictions by any
            number or combination of development parameters. <br />
            <br />
            Use the tabs and controls at right to isolate properties with
            specific zoning, location, and development parameters.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DefaultFilterContent;
