import { isMobile } from "react-device-detect";
import Switch from "react-switch";
import { RegionLinks } from "./RegionLinks";

export type LayerTogglesProps = {
  layers: LayerToggleProps[];
};

/** All the toggles */
export function LayerToggles({ layers }: LayerTogglesProps) {
  return (
    <div
      className={"controls-box2"}
      style={{
        background: isMobile
          ? "rgba(255, 255, 255, .25)"
          : "rgba(255, 255, 255, .85)"
      }}
    >
      {layers.map((layer) => (
        <LayerToggle key={layer.label} {...layer} />
      ))}
      <RegionLinks />
    </div>
  );
}

export type LayerToggleProps = {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  /** True if the toggle should manage its own state */
  control?: boolean;
  onChange?: () => void;
};

/** A specific toggle */
export function LayerToggle({
  onChange,
  // checked: _checked = false,
  disabled,
  checked,
  // control = false,
  label
}: LayerToggleProps) {
  const handleChange = () => {
    // if (control) {
    //   setChecked(!checked);
    // }
    onChange?.();
  };

  return (
    <div className="underlays-switcher">
      <div className="switch-container">
        <Switch
          disabled={disabled}
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          offHandleColor="#000"
          offColor="#fff"
          onHandleColor="#fff"
          onColor="#43c1f3"
          onChange={handleChange}
          checked={checked}
        />
        <span className="bld-status value-03">{label}</span>
      </div>
    </div>
  );
}
