import { useSelector } from "react-redux";
import "../../../../css/ExistingStructureInfo.css";
import ICON_LOCK from "../../../../images/lock-1.png";
import Container from "../Container";
import Header from "../Header";
import InfoSection from "../InfoSection";

const ExistingStructureInfo = () => {
  const { existing_structures } = useSelector(
    (state) => state.info.infoDefaultValues
  );
  return existing_structures?.map((field) => {
    if (field.lock == true) {
      return (
        <>
          <div className="lock">
            <div className="summary-data">
              <Container>
                <Header content={field} />

                <div
                  className="section-data"
                  style={{ border: "1px solid #F2F2F2" }}
                >
                  <div className="section-description">
                    <p className="sub-heading-lock">Enterprise users only</p>
                    <img
                      src={ICON_LOCK}
                      className="lock-image"
                      alt=""
                      style={{ marginTop: "-15px" }}
                    />
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </>
      );
    } else {
      if (field.footerText === true) {
        return (
          <>
            <div className="existing-structure">
              <div className="summary-data">
                <Container>
                  <Header content={field} />
                  <InfoSection content={field.properties} />
                  <div className="footer-section">
                    {field.properties.map((field) => {
                      return (
                        <div className="more">
                          <u>
                            <a
                              href={field.link}
                              title={field.source}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {field.source}
                            </a>
                          </u>
                        </div>
                      );
                    })}
                  </div>
                </Container>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="use-regulation">
              <div className="summary-data">
                <Container>
                  <Header content={field} />
                  <InfoSection content={field.properties} />
                  <div className="footer-section">
                    <div className="more"></div>
                  </div>
                </Container>
              </div>
            </div>
          </>
        );
      }
    }
  });
};
export default ExistingStructureInfo;
