import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getPaymentMethodsQuota } from "../../../../actions/actions_user";
import { env } from "../../../../env";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(env.REACT_APP_STRIPE_KEY);

const Payment = ({ plan, dispatch, title, quota }) => {
  const { token } = useSelector((state) => state.user);
  useEffect(() => {
    token && dispatch(getPaymentMethodsQuota(token));
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        plan={plan}
        dispatch={dispatch}
        title={title}
        quota={quota}
      />
    </Elements>
  );
};

export default Payment;
