import { useSelector } from "react-redux";
import "../../../../css/Development.css";
import ICON_LOCK from "../../../../images/lock-1.png";
import Container from "../Container";
import Header from "../Header";
import InfoSection from "../InfoSection";
import InfoMaxBuildCalculator from "./InfoMaxBuildCalculator";

const Development = () => {
  const { development } = useSelector((state) => state.info.infoDefaultValues);

  return development.map((field) => {
    if (field.lock === true || field.properties?.locked === true) {
      return (
        <>
          <div className="lock">
            <div className="summary-data">
              <Container>
                <Header content={field} />
                <hr className="lock-hr" />
                <div className="section-data">
                  <div className="section-description">
                    <p className="sub-heading-lock">
                      Download full report or sign up for a PRO account to see!
                    </p>
                    <img src={ICON_LOCK} className="lock-image" alt="" />
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </>
      );
    } else {
      if (field.footerText === true) {
        return (
          <>
            <div className="development">
              <div className="summary-data">
                <Container>
                  <Header content={field} />
                  {field.id === "max_building_calculator" ? (
                    <InfoMaxBuildCalculator content={field.properties} />
                  ) : (
                    // <></>
                    <InfoSection content={field.properties} />
                  )}
                  <div className="footer-section">
                    {field.properties.map((field) => {
                      return (
                        <div className="more">
                          <u>
                            <a
                              href={field.link}
                              title={field.source}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {field.source}
                            </a>
                          </u>
                        </div>
                      );
                    })}
                  </div>
                </Container>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="development hello">
              <div className="summary-data">
                <Container>
                  <Header content={field} />

                  {field.id === "max_building_calculator" ? (
                    <InfoMaxBuildCalculator content={field.properties} />
                  ) : (
                    <InfoSection content={field.properties} />
                  )}
                  <div className="footer-section">
                    <div className="more" />
                  </div>
                </Container>
              </div>
            </div>
          </>
        );
      }
    }
  });
};
export default Development;
