import { useSelector } from "react-redux";
import "../../../../css/FAR.css";
import ICON_LOCK from "../../../../images/lock-1.png";
import Container from "../Container";
import Header from "../Header";
import InfoSection from "../InfoSection";

const Coverage = () => {
  const { coverage } = useSelector((state) => state.info.infoDefaultValues);

  return coverage.map((field) => {
    if (field.show === true) {
      if (field.lock == true) {
        return (
          <>
            <div className="lock">
              <div className="summary-data">
                <Container>
                  <Header content={field} />
                  <hr className="lock-hr" />
                  <div className="section-data">
                    <div className="section-description">
                      <p className="sub-heading-lock">
                        Download full report or sign up for a PRO account to
                        see!
                      </p>
                      <img src={ICON_LOCK} className="lock-image" alt="" />
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="far">
              <div className="summary-data">
                <Container>
                  <Header content={field} />
                  <InfoSection content={field.properties} />
                  <div className="footer-section">
                    <div className="more"></div>
                  </div>
                </Container>
              </div>
            </div>
          </>
        );
      }
    }
  });
};
export default Coverage;
