export const laConfig = {
  MAPBOX_STYLE_URL_BASIC: "mapbox://styles/qvt282/clx3od61i01fh01rbdkh993w5", //multi-region-style
  MAPBOX_STYLE_URL_SATELLITE:
    "mapbox://styles/qvt282/clx3oda0p01r901po1xoyg2gs",
  MAPBOX_STYLE_URL_MBENVELOPE:
    "mapbox://styles/qvt282/clx3ocmxv01r801podj8b7ugf",
  MAPBOX_LAYER_NAME: "la_taxlots",
  BBOX: "-118.589172,33.694638,-117.976685,34.198173",
  MAP_CENTER: "-118.274503022256, 34.053989378733",
  REGION: {
    slug: "los_angeles",
    name: "Los Angeles, California"
  },
  JURISDICTIONS: [
    {
      // TODO migrate backend to city_of_los_angeles etc. for other regions, for now just los_angeles
      slug: "los_angeles",
      name: "City of Los Angeles, California"
    }
  ],
  OTHER_REGIONS:
    '{ "portland": [], "seattle": [], "san_diego": [], "austin": [],"los_angeles": [] }',
  PROXIMITY: "-118.274503022256, 34.053989378733",
  MB_TILES_LIST: "taxlots-fill",
  ZOOM_LEVEL: 10
};
