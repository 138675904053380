import { useSelector } from "react-redux";
import { applyFilter } from "../../../../actions/actions_filters";
import "../../../../css/Result.css";
import Container from "../Container";
import ResultTable from "../ResultTable";

import { setLoader } from "../../../../actions/actions_user";

const Result = ({ dispatch }) => {
  const { filterResult, filterValues } = useSelector((state) => state.filters);
  const { token } = useSelector((state) => state.user);
  const onPageChange = (e, pageInfo) => {
    const object = {
      filter_json: filterValues,
      page_number: pageInfo.activePage
    };
    dispatch(setLoader(true));
    dispatch(applyFilter(object, token));
  };
  return (
    <div className="result-section">
      <Container>
        <div className="result-count">
          <h1 style={{ fontWeight: "bolder" }}>
            {filterResult?.totalCount ?? 0}
          </h1>
          <p>Results</p>
        </div>
      </Container>
      <Container>
        <div className="result-body">
          <ResultTable
            data={filterResult.data}
            totalCount={filterResult.totalCount}
            activePage={filterResult.pageNumber}
            onPageChange={onPageChange}
          />
        </div>
      </Container>
    </div>
  );
};
export default Result;
