import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input, Message } from "semantic-ui-react";
import { activateUserAccount, setLoader } from "../../../actions/actions_user";
import "../../../css/Dashboard.css";

const AccountActivation = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [isError, setIsError] = useState(false);

  const handleUserInput = (e) => {
    const { name, value } = e.target;
    if (name === "token") {setToken(value);}
    if (value === "") {setIsError(true);}
  };

  const handleSubmit = () => {
    const payload = {
      token: token
    };
    dispatch(setLoader(true));
    dispatch(activateUserAccount(payload));
  };
  return (
    <div className="account-activation">
      <div className="activation-header">
        <h4>Account Verification</h4>
      </div>
      <div>
        <Form onSubmit={handleSubmit}>
          {isError && (
            <Message
              error
              header="Action Forbidden"
              content="You can only sign up for an account once with a given e-mail address."
            />
          )}
          <Form.Field error={token === ""}>
            <label>
              <b>Verification Code</b>
            </label>
            <Input
              transparent
              required={true}
              name="token"
              type="text"
              placeholder="Enter Code"
              onChange={(e) => {
                handleUserInput(e);
              }}
            />
            {token === "" && (
              <span className="errorMessage">
                This verification code field is required!
              </span>
            )}
          </Form.Field>
          <div className="account-activation-btn">
            <Button fluid type="submit">
              Verify
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default AccountActivation;
