export const SET_TAXLOT = "SET_TAXLOT";
export const SET_GEOJSON = "SET_GEOJSON";
export const SET_TAXLOT_ERROR = "SET_TAXLOT_ERROR";

export function setTaxlot(result) {
  return {
    type: SET_TAXLOT,
    payload: result
  };
}

export function setGeojson(result) {
  return {
    type: SET_GEOJSON,
    payload: result
  };
}

export function setTaxlotError(msg) {
  return {
    type: SET_TAXLOT_ERROR,
    payload: msg
  };
}
