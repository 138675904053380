const UseRegulationWindow = ({ data }) => {
  return (
    <div className="section">
      <>
        {data.properties[0].map((use_x) => (
          <>
            <div className="info-tabs">
              <div className="card-label">
                <p>
                  <span style={{ fontWeight: "bold" }}>{use_x.title}</span> :{" "}
                  {use_x.value ? use_x.value : "N/A"}
                </p>
              </div>
            </div>
            <div className="industrial_p section-data" />
          </>
        ))}

        <>
          <div className="industrial_p section-data" />

          <div className="main-source">
            <span>Source:</span>
            <div className="src_body">
              <u>
                <a
                  href={
                    data.properties[1][0]?.link
                      ? data.properties[1][0]?.link
                      : ""
                  }
                  target="_blank"
                  title={
                    data.properties[1][0]?.link
                      ? data.properties[1][0]?.link
                      : ""
                  }
                  rel="noreferrer"
                >
                  {data.properties[1][0]?.link
                    ? data.properties[1][0]?.value
                    : "N/A"}
                </a>
              </u>
            </div>
          </div>
          <div className="industrial_p section-data" />
        </>
      </>
    </div>
  );
};

export default UseRegulationWindow;
