import { env } from "../env";
import { setDataWindow, setDataWindowContent } from "./actions_datawindow";
import { setAlertMessage, setLoader } from "./actions_user";

export const SET_SAVED_PROPERTY_DEFAULT_VALUE =
  "SET_SAVED_PROPERTY_DEFAULT_VALUE";
export const SET_SAVED_PROPERTY_VALUE = "SET_SAVED_PROPERTY_VALUE";
export const SET_FLAG_FILE = "SET_FLAG_FILE";

export function postSaveProperty(object) {
  return async (dispatch) => {
    const response = await fetch(
      env.REACT_APP_URBANFORM_API_URL + "/api/info/user/",
      {
        method: "POST",
        body: JSON.stringify(object)
      }
    );
    const data = await response.json();
    dispatch(setLoader(false));
    dispatch(
      setDataWindow({
        dataWindow: true,
        content: {
          isContact: null,
          contactInfo: {},
          choosePlan: {},
          isPayment: false,
          isInfo: true
        }
      })
    );
    dispatch(
      setDataWindowContent({
        show: { showData: "summary", showControl: "info" }
      })
    );
    dispatch(setAlertMessage("You have successfully saved the property!"));
  };
}

export function getSaveProperty(id) {
  return async (dispatch) => {
    const response = await fetch(
      env.REACT_APP_URBANFORM_API_URL + "/api/info/user/" + id,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    if (Object.keys(data).includes("tokenExpired")) {
      window.location.reload(false);
    } else {
      dispatch(setSavedPropertyDefaultValue(data)); // Here we are also passing the TYPE so we can set it dynamically in the reducer
      dispatch(setLoader(false));
      dispatch(
        setDataWindow({
          dataWindow: true,
          content: {
            isContact: true,
            contactInfo: {},
            choosePlan: {},
            isPayment: false
          }
        })
      );
      dispatch(
        setDataWindowContent({
          show: { showData: "saved-property", showControl: "dashboard" }
        })
      );
    }
  };
}

export function setDelete(resp) {
  return (dispatch) => {
    dispatch(setSavedPropertyDefaultValue(resp));
  };
}

export function deleteSaveProperty(id) {
  return async (dispatch) => {
    try {
      await fetch(env.REACT_APP_URBANFORM_API_URL + "/api/info/user/" + id, {
        method: "DELETE"
      });
      dispatch(setAlertMessage("Your property has been deleted successfully"));
    } catch {
      dispatch(
        setAlertMessage("Failed to delete the property. Please try again.")
      );
    } finally {
      dispatch(setLoader(false));
    }
  };
}

export function setSavedPropertyDefaultValue(res) {
  return {
    type: SET_SAVED_PROPERTY_DEFAULT_VALUE,
    payload: res
  };
}

export function setSavedPropertyCurrentValue(infoValue) {
  return {
    type: SET_SAVED_PROPERTY_VALUE,
    payload: {
      data: infoValue
    }
  };
}

export function setFlagFile(flag) {
  return {
    type: SET_FLAG_FILE,
    payload: flag
  };
}
