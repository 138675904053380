import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "semantic-ui-react";
import { getNewsFeed } from "../../../../actions/actions_user";
import "../../../../css/ContactCity.css";
const NewsFeed = () => {
  const dispatch = useDispatch();
  const { news } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(getNewsFeed());
  }, [dispatch]);

  const News = news?.rss?.channel;

  return (
    <div>
      {News?.item.map((newsCard, index) => {
        return (
          <div key={index} className="news-feed" style={{ marginTop: "15px" }}>
            <div className="news-header">
              <h2>{newsCard?.title?._cdata}</h2>
            </div>
            <div className="news-body">
              <Image src={newsCard?.enclosure?._attributes?.url} />
              <p>{newsCard?.description?._cdata}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NewsFeed;
