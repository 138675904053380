import { ICONS } from "../../../constants";
import "../../../css/Header.css";
const Header = ({ content }) => {
  const getIconHelper = (name) => {
    return ICONS.filter((icon) => name === icon.name)[0].icon;
  };
  return (
    <div className="data-header">
      <div className="header-display">
        <h2>{content?.title}</h2>
        <h3 style={{ color: "black" }}>{content?.megaTitle}</h3>
      </div>
    </div>
  );
};
export default Header;
