import { env } from "@/env";
import { isMobile } from "react-device-detect";

type UrbanformCustomHeaders = {
  "Region-Slug"?: string;
  "Jurisdiction-Slug"?: string;
  [key: string]: string;
};

export function applyFetchInterceptors() {
  // TODO temp overriding all requests with headers to avoid putting them everywhere. Will move away from
  // headers in favor of query params, except for perhaps the user's account. Search parameters in
  // custom headers is not RESTful, and not a best practice.

  const originalFetch = fetch;

  window.fetch = (url: RequestInfo | string, options = {}) => {
    // only apply custom headers IFF it's our backend
    if (
      typeof url === "string" &&
      url.includes(env.REACT_APP_URBANFORM_API_URL)
    ) {
      const token = window.localStorage.getItem("_uft");
      const device = isMobile ? "M" : "D";

      const headers: UrbanformCustomHeaders = {
        "Content-type": "application/json",
        "Region-Slug": localStorage.getItem("regionSlug"),
        "Jurisdiction-Slug": localStorage.getItem("jurisdictionSlug"),
        "Device-Type": device
      };

      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      options.headers = headers;
    }

    return originalFetch(url, options);
  };
}
