import { setMapSearch } from "@/actions/actions_datawindow";
import {
  getAddressByLngLat,
  getSelectInfo,
  setInfoCurrentValue,
  setInfoSearchMapAddress
} from "@/actions/actions_info";
import { setGeojson } from "@/actions/actions_taxlot";
import { setLoader } from "@/actions/actions_user";
import config from "@/config";
import { INFO_CONSTANT } from "@/constants";
import { env } from "@/env";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddressFromLatLng } from "./useGetAddressFromLatLng";

/** returns the handler for clicking the parcel */
export function useHandleTaxlotClick() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const taxlotData = useSelector((state) => state.taxlot.currentTaxlot);

  const [map, setMap] = useState(null);

  const setGeoJson = (feature) => {
    dispatch(
      setGeojson({
        type: "FeatureCollection",
        features: [
          {
            properties: {
              "fill-opacity": 0,
              "stroke-width": 4,
              "stroke-opacity": 1,
              stroke: "%2300adef"
            },
            type: "Feature",
            geometry: { ...feature.geometry }
          }
        ]
      })
    );
  };

  useEffect(() => {
    // hook for when the taxlot has been fetched and populated in the store
    // we use an effect because the store doesn't sync well

    const focusOnTaxlot = () => {
      if (map && taxlotData && taxlotData.taxLots) {
        // If tax lot data changes but isn't selected yet
        if (taxlotData.taxLots.mapboxCoords) {
          map.flyTo({
            center: taxlotData.taxLots.mapboxCoords,
            zoom: 18
          });
          if (!map.getSource("selected-lot")) {
            map.addSource("selected-lot", {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: JSON.parse(taxlotData.taxLots.geom)
              }
            });
            map.addLayer({
              id: "selected-lot",
              source: "selected-lot",
              type: "line",
              paint: {
                "line-color": "cyan",
                "line-width": 3
              }
            });
          } else {
            map.getSource("selected-lot").setData({
              type: "Feature",
              geometry: JSON.parse(taxlotData.taxLots.geom)
            });
          }
          map.once("moveend", () => {
            if (!map.getSource("selected-lot")) {
              map.addSource("selected-lot", {
                type: "geojson",
                data: {
                  type: "Feature",
                  geometry: JSON.parse(taxlotData.taxLots.geom)
                }
              });
              map.addLayer({
                id: "selected-lot",
                source: "selected-lot",
                type: "line",
                paint: {
                  "line-color": "cyan",
                  "line-width": 3
                }
              });
            } else {
              map.getSource("selected-lot").setData({
                type: "Feature",
                geometry: JSON.parse(taxlotData.taxLots.geom)
              });
            }

            setGeoJson({ geometry: JSON.parse(taxlotData.taxLots.geom) });
          });
        }
      }
    };

    focusOnTaxlot();
  }, [taxlotData]);

  const handleMapTaxlotClick = async ({
    e,
    map,
    searchBarGeocoder,
    taxlotData
  }: {
    e: Event;
    map: any;
    searchBarGeocoder: any;
    taxlotData: any;
  }) => {
    setMap(map);

    const fetchPropertyData = (primaccnum, lngLat) => {
      const body = {
        primaccnum: primaccnum
      };

      dispatch(setInfoCurrentValue(INFO_CONSTANT));
      dispatch(getSelectInfo(body, INFO_CONSTANT, lngLat, Math.random(), user));
    };

    if (e.features[0]?.properties.JURIS) {
      const mapboxTaxlotFeatureJurisProperty = e.features[0]?.properties.JURIS;

      // On click
      localStorage.setItem(
        "jurisdictionSlug",
        env.REACT_APP_JURISDICTION_SLUG_MAP[mapboxTaxlotFeatureJurisProperty]
      );
    }

    let place_name: string;
    if (!e.features[0].properties.ADDR) {
      const { lat, lng } = map.unproject(e.point);
      place_name = await getAddressFromLatLng(lat, lng);
    } else {
      place_name = e.features[0].properties.ADDR;
    }

    dispatch(setInfoSearchMapAddress({ place_name }));

    // don't allow clicking when too far away
    if (map.getZoom() < 14) {
      return;
    }

    // does the mapbox layer have the taxlot?
    const thisTaxLot = map.queryRenderedFeatures(e.point, {
      layers: config.MB_TILES_LIST.split()
    });
    if (thisTaxLot) {
      dispatch(setLoader(true));
      const body = {
        primaccnum: thisTaxLot[0].properties.PRIMACCNUM,
        searchtype: e.originalEvent.type
      };
      dispatch(setMapSearch(true));
      dispatch(
        getAddressByLngLat({
          object: body,
          lat: e.lngLat.lat,
          lng: e.lngLat.lng,
          searchBarGeocoder
        })
      );
      setGeoJson(thisTaxLot[0]);
      // setCurrentContent("Info");
      fetchPropertyData(
        thisTaxLot[0].properties.PRIMACCNUM,
        map.unproject(e.point)
      );
    }
  };

  return handleMapTaxlotClick;
}
