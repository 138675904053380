import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UpdateFilter } from "../../../actions/actions_filters";
import "../../../css/SliderSection.css";
import {
  checkboxUpdate,
  updateMinMax,
  updateSlider
} from "../../../utils/Utils";

const SliderSection = ({
  content,
  range,
  checkboxField,
  dispatch,
  selectedFilter,
  disabled = false,
  maxRangeValue,
  columnName
}) => {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(maxRangeValue);
  const [randNumber, setRandNumber] = useState(Math.random(2000000));
  const { show } = useSelector((state) => state.dataWindow);

  const { Range } = Slider;
  const getPreviousMinMaxValues = (columnName) => {
    const minMax = [];
    content.forEach((field) => {
      if (
        field.column_name === columnName &&
        columnName !== "residential_units"
      )
        {minMax.push(field.value);}
      else if (columnName === "residential_units")
        {if (
          ["unit_min_lot_valu", "unit_max_lot_valu"].includes(field.column_name)
        )
          {minMax.push(field.value);}}
    });
    return minMax;
  };
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    if (Number(value) > Number(maxRangeValue)) {return;}
    const minMax = getPreviousMinMaxValues(columnName);
    const UpdatedFilter = updateMinMax(
      show,
      selectedFilter,
      name,
      value,
      columnName,
      columnName === "yearbuilt" ? 2022 : maxRangeValue,
      minMax,
      columnName === "yearbuilt" ? 1800 : 0
    );
    dispatch(UpdateFilter(UpdatedFilter));
    setRandNumber(Math.random(2000000));
  };
  useEffect(() => {
    content.forEach((field) => {
      field.label === "Min" && setMinValue(field.value);
      field.label === "Max" && setMaxValue(field.value);
    });
  }, [content]);
  const handleSelect = (e) => {
    const { name, checked } = e.target;
    const currentFilter = show.showData;
    const UpdatedFilter = {
      name: currentFilter,
      filterData: checkboxUpdate(currentFilter, selectedFilter, name, checked)
    };
    dispatch(UpdateFilter(UpdatedFilter));
  };
  const onSliderAfterChange = (value) => {
    if (value.length !== 1) {
      const UpdatedFilter = updateSlider(
        show,
        selectedFilter,
        columnName,
        value,
        "doubleSlider",
        columnName === "yearbuilt" ? 2022 : maxRangeValue,
        columnName === "yearbuilt" ? 1800 : 0
      );
      dispatch(UpdateFilter(UpdatedFilter));
    } else {
      const UpdatedFilter = updateSlider(
        show,
        selectedFilter,
        columnName,
        value,
        "singleSlider",
        maxRangeValue
      );
      dispatch(UpdateFilter(UpdatedFilter));
    }
  };
  const onSliderChange = (value) => {
    if (Array.isArray(value)) {
      setMinValue(value[0]);
      setMaxValue(value[1]);
    } else {setMinValue(value);}
  };
  const sliderMinValue = columnName === "yearbuilt" ? 1800 : 0;
  return (
    <>
      <div className="slider">
        {content && (
          <div className="ui two column centered grid">
            <div
              className="four centered row color"
              style={{ justifyContent: "space-around" }}
            >
              {content &&
                content.map((field) => {
                  const subLabelStyle =
                    field.subLabel === "$"
                      ? "98px"
                      : field.subLabel === "square feet"
                        ? "40px"
                        : "82px";
                  const style = { paddingLeft: 0 };

                  return (
                    <>
                      <div style={{ position: "relative" }}>
                        <p
                          style={{
                            float: "left",
                            margin: "0 0 5px 0",
                            fontSize: "13px"
                          }}
                        >
                          {field.labelText}
                        </p>
                        <div className={[`${field.className}`]}>
                          <div className="ui input focus">
                            <input
                              type="number"
                              className="fields"
                              id={field.name}
                              value={
                                field.label === "Min" ? minValue : maxValue
                              }
                              style={field.label === "Min" ? style : {}}
                              name={field.name}
                              min={sliderMinValue}
                              max={maxRangeValue}
                              onChange={handleValueChange}
                              disabled={disabled}
                            />
                          </div>
                          <p
                            className="height-input-label"
                            style={{
                              float: "right",
                              position: "absolute",
                              right: "0px",
                              marginTop: "0px",
                              marginLeft: "0px",
                              fontSize: "13px"
                            }}
                          >
                            {field.subLabel}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default SliderSection;
