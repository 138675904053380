import { useEffect, useState } from "react";

export default function useInfoMaxBuildCalculator(content) {
  const [state, setState] = useState({
    maxBuildType: "MaxBuild AOR",
    customFarValue: null,
    customSfValue: null,
    resultingSfValue: parseFloat(content.envp_bldg_max_area?.replace(/,/g, "")),
    sfUnit: "2",
    unitsDDValue: null,
    unit1: parseFloat(content?.bldgsqft?.replace(/,/g, "")),
    useExistingBldgSf: false,
    unit2: 0,
    unit3: 0,
    unit4: 0,
    units: 5,
    efficiency: 0.85,
    resultingNetSf: 0,
    resultingNetSfPrUnit: 0
  });

  const getResultingSF = (maxBuildType, previous) => {
    let c = 0;

    switch (maxBuildType) {
      case "MaxBuild AOR":
        c = parseFloat(content.envp_bldg_max_area?.replace(/,/g, ""));
        break;
      case "MaxBuild ABT":
        c = parseFloat(content.envp_bldg_bonus_area?.replace(/,/g, ""));
        break;
      case "Custom FAR":
        c =
          parseFloat(content.area_sqft?.replace(/,/g, "")) *
          parseFloat(previous.customFarValue);
        break;
      case "Custom SF":
        c = previous.customSfValue;
        break;
      default:
        c = parseFloat(content.envp_bldg_max_area?.replace(/,/g, ""));
        break;
    }
    return c;
  };
  const handleChangeRadio = (e, { name, value }) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
      resultingSfValue: getResultingSF(value, prev) || 0
    }));
  };
  const getResultingValues = (obj) => {
    const netSf = obj.resultingSfValue * obj.efficiency;
    const units = +obj.sfUnit > 4 ? obj.units : obj.sfUnit;
    return {
      resultingNetSf: netSf,
      resultingNetSfPrUnit: netSf / units
    };
  };
  const getResultAndObj = () => {
    const result = {};
    const obj = {
      resultingSfValue: state?.resultingSfValue,
      sfUnit: state.sfUnit
    };
    return { result, obj };
  };
  const getresultingValuesOnChange = (name, value) => {
    let { result, obj } = getResultAndObj();
    if (name === "efficiency" || name === "units") {
      if (name === "efficiency") {
        result = getResultingValues({
          ...obj,
          efficiency: value,
          units: state.units
        });
      }
      if (name === "units") {
        result = getResultingValues({
          ...obj,
          efficiency: state.efficiency,
          units: value
        });
      }
    }
    return result;
  };
  useEffect(() => {
    let { result, obj } = getResultAndObj();
    result = getResultingValues({
      ...obj,
      efficiency: state.efficiency,
      units: state.units
    });
    setState((prev) => ({
      ...prev,
      ...result
    }));
  }, [state.resultingSfValue]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const result = getresultingValuesOnChange(name, value);
    setState((prev) => ({
      ...prev,
      ...result,
      [name]: value,
      resultingSfValue: getResultingSF(prev.maxBuildType, {
        customFarValue: value,
        customSfValue: value
      })
    }));
  };
  const getSliderValue = (name, value) => {
    const result = getresultingValuesOnChange(name, value);
    setState((prev) => ({
      ...prev,
      ...result,
      [name]: value
    }));
  };

  useEffect(() => {
    if (
      +state.resultingSfValue > 0 &&
      +state.unit1 + +state.unit2 + +state.unit3 + +state.unit4 >
        +state.resultingSfValue
    ) {
      if (state.useExistingBldgSf) {
        setState((prev) => ({
          ...prev,
          unit2: 0,
          unit3: 0,
          unit4: 0
        }));
      } else {
        setState((prev) => ({
          ...prev,
          unit1: +prev.resultingSfValue,
          unit2: 0,
          unit3: 0,
          unit4: 0
        }));
      }
    }
  }, [
    state.unit1,
    state.unit2,
    state.unit3,
    state.unit4,
    state.resultingSfValue
  ]);

  const options = [
    { key: 2, text: "2", value: "2" },
    { key: 3, text: "3", value: "3" },
    { key: 4, text: "4", value: "4" },
    { key: 5, text: "5+", value: "5" }
  ];
  return {
    state,
    handleChangeRadio,
    handleChange,
    setState,
    getResultingValues,
    getSliderValue,
    options
  };
}
