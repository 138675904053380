import {
  SET_CONTENT,
  SET_DATA_WINDOW,
  SET_MAP_SEARCH_FIELD
} from "../actions/actions_datawindow";

const initialState = {
  dataWindow: false,
  content: {},
  show: {
    showData: "",
    showControl: ""
  }
};

const dataWindow = (state = initialState, action) => {
  const { type } = action;
  if (type === SET_DATA_WINDOW) {
    const newState = { ...state };
    newState.dataWindow = action.dataWindow;
    newState.content = action.content;
    newState.updateContent = action.updateContent;
    return {
      ...newState
    };
  } else if (type === SET_CONTENT) {
    const newState = { ...state };
    newState.show.showData = action.show.show.showData;
    newState.show.showControl = action.show.show.showControl;
    return {
      ...newState
    };
  } else if (type === SET_MAP_SEARCH_FIELD) {
    const newState = { ...state };
    newState.show.showMap = action.show;
    return {
      ...newState
    };
  } else {
    return { ...state };
  }
};

export default dataWindow;
