import { isIOS, isMobile } from "react-device-detect";
import config from "../../config";
import "../../css/InfoFontStyle.css";

const HomeContent = ({ logo, SidebarTable, showPanel, setShowPanel }) => {
  const handleClosePanel = () => {
    setShowPanel(false);
  };
  return (
    <div
      className="home-content"
      style={{ margin: isMobile && "-66px -10px -2px -10px" }}
    >
      <div className="general-info" style={{ paddingTop: isMobile && "35px" }}>
        <a href="/" className="logo-container">
          <img src={logo} alt="" style={{ width: isMobile && "30%" }} />
          <h2 style={{ fontSize: isMobile && "26px" }}>URBANFORM</h2>
          <p style={{ fontSize: isMobile && "18px" }}>{config.REGION.name}</p>
        </a>
      </div>
      <div className="other-info">
        <div
          className="mapbox-search"
          style={{ padding: isMobile ? "13px" : "32px" }}
        >
          <div id="mapbox-geocoder-home"></div>
        </div>
        <div>
          <div className="zoning-info">
            <div
              className="bg-content"
              style={{ top: isIOS ? "47%" : isMobile && "50%" }}
            >
              <p className="value-02">
                The only online source for instant, site-specific zoning
                information
              </p>
            </div>
            <p
              style={{
                position: "absolute",
                bottom: isIOS ? "78px" : "10px",
                fontStyle: "italic",
                fontSize: "15px",
                textAlign: "center",
                width: "100%",
                color: "#000",
                opacity: "0.6"
              }}
            >
              Learn More at <br />{" "}
              <a
                href="https://www.urbanform.us"
                target="_blank"
                rel="noreferrer"
              >
                urbanform.us
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
