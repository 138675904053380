import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Confirm } from "semantic-ui-react";
import {
  applyFilter,
  deleteSaveFilters,
  seRunFilter,
  setDeleteFilter,
  setFilterValues
} from "../../../../actions/actions_filters";
import { setLoader } from "../../../../actions/actions_user";
import "../../../../css/SavedSection.css";
import deleteIcon from "../../../../images/remove-circle.png";

const SavedFilter = () => {
  const dispatch = useDispatch();
  const { savedFilter } = useSelector((state) => state.filters);
  const [filterId, setFilterId] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);

  const { user, token } = useSelector((state) => state.user);
  const filterRow = (result) => {
    const filter = savedFilter.filter((el) => {
      if (result.id === el.id) {
        return el;
      }
    });
    const object = {
      filter_json: filter[0].filter_json,
      page_number: 1
    };
    dispatch(setLoader(true));
    dispatch(setFilterValues(filter[0].filter_json));
    dispatch(applyFilter(object, token));
    dispatch(seRunFilter(true));
  };
  const getDate = (date) => {
    var date = date.split("T")[0];
    return date;
  };

  const deleteFilter = (result) => {
    setFilterId(result.id);
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    const newValues = savedFilter.filter((el) => {
      if (el.id !== filterId) {
        return el;
      }
    });
    dispatch(setDeleteFilter(newValues));
    dispatch(setLoader(true));
    dispatch(deleteSaveFilters(filterId));
  };
  const handleCancel = () => {
    setShowConfirm(false);
  };
  return (
    <div className="acc_data_window">
      <h1 style={{ color: "#000", fontFamily: "Inter-Black" }}>
        Hello, {user?.first_name}!
      </h1>
      <div className="property-data">
        <div className="property-heading">
          <h2>Saved Filters</h2>
        </div>

        <div className="saved-section">
          {savedFilter.length > 0 &&
            savedFilter.map((result) => {
              return (
                <>
                  <div
                    className="section-data"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0"
                    }}
                  >
                    <div className="filter-left">
                      <a onClick={() => filterRow(result)}>
                        <div className="section-header">
                          <h2 className="heading-text">
                            {result.filter_name}{" "}
                          </h2>
                        </div>
                      </a>
                      <div className="section-description">
                        {result?.created_at && (
                          <p className="align-date color">
                            saved {getDate(result.created_at)}
                          </p>
                        )}
                      </div>
                    </div>

                    <div
                      className="delete-icon"
                      onClick={() => {
                        deleteFilter(result);
                      }}
                    >
                      <img src={deleteIcon} alt="" />
                    </div>
                  </div>
                </>
              );
            })}
          {savedFilter.length === 0 && (
            <div className="section-data">
              <div className="section-header">
                <h2 className="heading-text color">No record found.</h2>
              </div>
            </div>
          )}
        </div>

        <Confirm
          open={showConfirm}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          cancelButton="No"
          confirmButton="Yes"
          content="Are you sure, you want to delete this?"
          size="tiny"
        />
      </div>
    </div>
  );
};
export default SavedFilter;
