import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Confirm } from "semantic-ui-react";
import { updateSelectedProperty } from "../../../actions/actions_filters";
import { setInfoCurrentValue } from "../../../actions/actions_info";
import {
  deleteSaveProperty,
  setDelete
} from "../../../actions/actions_save_property";
import {
  deleteSaveReport,
  getReportLink,
  setReportDelete
} from "../../../actions/actions_save_report";
import { setLoader } from "../../../actions/actions_user";
import { INFO_CONSTANT } from "../../../constants";
import "../../../css/SavedSection.css";
import deleteIcon from "../../../images/remove-circle.png";

const SavedSection = ({ content, report }) => {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.user);
  const { savedDefaultValues } = useSelector((state) => state.property);
  const { reportDefaultValues } = useSelector((state) => state.savedReport);
  const [showConfirm, setShowConfirm] = useState(false);
  const [propertyId, setPropertyId] = useState(0);
  const [reportId, setReportId] = useState(0);
  const [reportFileName, setReportFileName] = useState(null);

  const handleInfoDetails = () => {
    dispatch(setInfoCurrentValue(INFO_CONSTANT));
  };

  const fetchPlot = (result) => {
    switch (report) {
      case true:
        const body = {
          addr: result.file_name
        };
        dispatch(setLoader(true));
        dispatch(getReportLink(body));
        break;
      case false:
        dispatch(updateSelectedProperty(result));
        break;
    }
  };

  const deleteProperty = (result) => {
    setPropertyId(result.id);
    setShowConfirm(true);
  };

  const deleteReport = (result) => {
    setReportId(result.id);
    setReportFileName(result.file_name);
    setShowConfirm(true);
  };

  const getDate = (date) => {
    var date = date.split("T")[0];
    return date;
  };

  const getHouseName = (result) => {
    switch (report) {
      case true:
        return result.file_name;
      case false:
        if (result?.name !== null) {
          const address = result.name.split(",");
          return address[0];
        } else {return "N/A";}
    }
  };

  const getCity = (address) => {
    switch (report) {
      case true:
        return true;
      case false:
        if (address !== null) {
          return "United States";
        } else {return "N/A";}
    }
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    if (report === true) {
      const newValues = reportDefaultValues.filter((el) => {
        if (el.id !== reportId) {
          return el;
        }
      });
      const body = {
        file_name: reportFileName
      };
      dispatch(setReportDelete(newValues));
      dispatch(setLoader(true));
      dispatch(deleteSaveReport(reportId, body));
    }
    if (report === false) {
      const newValues = savedDefaultValues.filter((el) => {
        if (el.id !== propertyId) {
          return el;
        }
      });
      dispatch(setLoader(true));
      dispatch(setDelete(newValues));
      dispatch(deleteSaveProperty(propertyId, user.id));
    }
  };
  const handleCancel = () => {
    setShowConfirm(false);
  };

  return (
    <div className="saved-section">
      {content.length > 0 &&
        content.map((result) => {
          return (
            <>
              <div
                className="section-data"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 0"
                }}
              >
                <div className="section-header">
                  <a
                    onClick={() => {
                      fetchPlot(result);
                    }}
                  >
                    <div>
                      <h3 className="heading-text"> {getHouseName(result)}</h3>
                    </div>
                  </a>
                  <div className="section-description">
                    {result?.created_at && (
                      <p className="align-date color">
                        Saved {getDate(result.created_at)}
                      </p>
                    )}
                  </div>
                </div>
                {report === false && (
                  <div
                    className="delete-icon"
                    onClick={() => {
                      deleteProperty(result);
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                  </div>
                )}
                {report === true && (
                  <div
                    className="delete-icon"
                    onClick={() => {
                      deleteReport(result);
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                  </div>
                )}
              </div>
            </>
          );
        })}
      {content.length === 0 && (
        <div className="section-data">
          <div className="section-header">
            <h2 className="heading-text color">No record found.</h2>
          </div>
        </div>
      )}
      <Confirm
        open={showConfirm}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        cancelButton="No"
        confirmButton="Yes"
        content="Are you sure, you want to delete this?"
        size="tiny"
      />
    </div>
  );
};
export default SavedSection;
