import React from "react";
import "../../../../css/DefaultDataWindowContent.css";
import QueriesArray from "./currentQueryHelper";

const CurrentQuery = () => {
  const queriesArray = QueriesArray();

  return (
    <>
      <div className="current-query">
        <div className="current-query-filter-card">
          <div className="current-query-header">
            <h3>Current Query</h3>
          </div>
          <div className="current-query-body">
            <div className="d-flex flex-column">
              {queriesArray?.map((item, index) => (
                <div style={{ marginBottom: "50px" }} key={index}>
                  <React.Fragment>
                    <p className="current-query-sub-heading">{item.title}</p>
                    {item.value}
                  </React.Fragment>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentQuery;
