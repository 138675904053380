import { useSelector } from "react-redux";
import SavedSection from "../SavedSection";

const SavedProperty = (dispatch) => {
  const { reportDefaultValues } = useSelector((state) => state.savedReport);
  return (
    <div className="property-data">
      <div className="property-heading">
        <h2>Saved Reports</h2>
      </div>
      <SavedSection
        content={reportDefaultValues}
        dispatch={dispatch}
        report={true}
      />
    </div>
  );
};
export default SavedProperty;
