import { useEffect, useRef } from "react";
import { MobileView } from "react-device-detect";
import { connect, useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import {
  SET_DATA_WINDOW,
  setDataWindow,
  setDataWindowContent
} from "../../actions/actions_datawindow";
import { setLoader, updateUser } from "../../actions/actions_user";
import "../../css/DataWindow.css";
import Container from "./components/Container";
import ExistingStructure from "./components/filters/ExistingStructure";
import LandStats from "./components/filters/LandStats";
import Result from "./components/filters/Result";
import Total from "./components/filters/Total";
import Use from "./components/filters/Use";
import Zone from "./components/filters/Zone";
import Payment from "./components/payment/Payment";
import PaymentMethods from "./components/payment/PaymentMethods";
import SignUp from "./components/SignUp";

import { updatePlanDetails } from "../../utils/Utils";
import DefaultFilterContent from "../SidebarContent/DefaultFilterContent";
import DefaultHelpContent from "../SidebarContent/DefaultHelpContent";
import DefaultInfoContent from "../SidebarContent/DefaultInfoContent";
import DefaultUserContent from "../SidebarContent/DefaultUserContent";
import AccountActivation from "./components/AccountActivation";
import ChangePassword from "./components/ChangePassword";
import CitiesAndPlans from "./components/CitiesAndPlans";
import CurrentQuery from "./components/CurrentQuery/CurrentQuery";
import CoverageFilter from "./components/filters/CoverageFilter";
import FarFilter from "./components/filters/FarFilter";
import HeightFilter from "./components/filters/HeightFilter";
import JurisFilter from "./components/filters/JurisFilter";
import Location from "./components/filters/Location";
import UnitsFilter from "./components/filters/UnitsFilter";
import ForgetPassword from "./components/ForgetPassword";
import Architect from "./components/help/Architect";
import ContactUs from "./components/help/ContactUs";
import Jurisdiction from "./components/help/Jurisdiction";
import NewsFeed from "./components/help/NewsFeed";
import RealEstate from "./components/help/RealEstate";
import ZoningGuide from "./components/help/ZoningGuide";
import Coverage from "./components/info/Coverage";
import Development from "./components/info/Development";
import ExistingStructureInfo from "./components/info/ExistingStructureInfo";
import FAR from "./components/info/FAR";
import HeightLimit from "./components/info/HeightLimit";
import Identifiers from "./components/info/Identifiers";
import LandDetail from "./components/info/LandDetail";
import Massing from "./components/info/Massing";
import Other from "./components/info/Other";
import Parking from "./components/info/Parking";
import PurchaseInfo from "./components/info/PurchaseInfo";
import ReportPreview from "./components/info/ReportPreview";
import ResidentialUnits from "./components/info/ResidentialUnits";
import Setbacks from "./components/info/Setbacks";
import Summary from "./components/info/Summary";
import UseRegulation from "./components/info/UseRegulation";
import ZoneDetail from "./components/info/ZoneDetail";
import SavedFilter from "./components/saved/SavedFilter";
import SavedProperty from "./components/saved/SavedProperty";
import SavedReport from "./components/saved/SavedReport";
import Segment from "./components/Segment";
import SegmentFooter from "./components/SegmentFooter";
import SegmentHeader from "./components/SegmentHeader";

const DataWindow = ({ currentContent, setCurrentContent, dispatch }) => {
  const dataWindow = useSelector((state) => state.dataWindow);
  const userRoles = useSelector((state) => state.plans.plans);
  const loggedUser = useSelector((state) => state.user);
  const { map } = useSelector((state) => state.search);

  const { homeGeocoder } = useSelector((state) => state.search);
  const invalidToken = dataWindow.show.showData;
  const { activeContent, plans } = dataWindow.content.choosePlan;
  const { downloadPdf, oneTimePayment } = dataWindow.content;
  const { planPrice } = useSelector((state) => state.user);
  const scrollDiv = useRef(null);
  useEffect(() => {
    downloadPdf && setCurrentContent("User");
    dataWindow.show.showData === "Help" && setCurrentContent("Help");
    dataWindow.show.showData === "forget-password" && setCurrentContent("User");
    dataWindow.show.showControl === "second-window-sign-up" &&
      setCurrentContent("User");
    dataWindow.show.showData === "see_result" &&
      dataWindow.show.showControl === "filters" &&
      setCurrentContent("Search");
    dataWindow.show.showData === "saved-filter" &&
      dataWindow.show.showControl === "dashboard" &&
      setCurrentContent("User");
  }, [downloadPdf, dataWindow.show, dataWindow.show.showControl]);
  useEffect(() => {
    invalidToken === "invalidToken" && setCurrentContent("User");
  }, [invalidToken]);
  useEffect(() => {
    dataWindow.show.showData === "saved-property" && setCurrentContent("User");
  }, [dataWindow.show, dataWindow.show.showControl]);

  let plan =
    plans &&
    activeContent &&
    plans.filter((pl) => pl.code === activeContent)[0];
  plan &&
    userRoles &&
    userRoles.map((role) => {
      if (role.name === plan.role) {
        plan["id"] = role.id;
      }
    });
  if (plan) {plan = updatePlanDetails(plan, planPrice);}
  const handlePurchasePlan = () => {
    if (plan.code === "non-subscriber") {
      dispatch(setLoader(true));
      dispatch(
        setDataWindow({
          dataWindow: false,
          content: {
            isContact: null,
            contactInfo: {},
            choosePlan: { ...dataWindow.content.choosePlan }
          }
        })
      );
      dispatch(
        updateUser({ status: plan.id }, loggedUser.user.id, loggedUser.token)
      );
    } else if (plan.code === "pro") {
      dispatch(
        setDataWindow({
          dataWindow: true,
          content: {
            isContact: null,
            contactInfo: {},
            choosePlan: { ...dataWindow.content.choosePlan },
            isPayment: true
          }
        })
      );
      dispatch(
        setDataWindowContent({
          show: { showData: "payment", showControl: "choose-plan" }
        })
      );
    } else {
      dispatch(
        setDataWindow({
          dataWindow: true,
          content: {
            isContact: null,
            isPayment: true,
            choosePlan: { ...dataWindow.content.choosePlan }
          }
        })
      );
      dispatch(
        setDataWindowContent({
          show: { showData: "payment", showControl: "choose-plan" }
        })
      );
    }
  };

  const handleCloseDataWindow = () => {
    dispatch(
      setDataWindow({ ...dataWindow, dataWindow: false, updateContent: false })
    );
  };

  const getPanelWidth = () => {
    if (window.innerWidth <= 768) {
      return window.innerWidth === 768
        ? "445px"
        : window.innerWidth - 80 + "px";
    } else {
      return "445px";
    }
  };

  return (
    <div
      className="data-window"
      style={{
        width: `${getPanelWidth()}`,
        background:
          currentContent === "Search"
            ? "#FFEFF9"
            : currentContent === "Help"
              ? "#DFF3FF"
              : currentContent === "User"
                ? "#FFFBE3"
                : "#F2F2F2"
      }}
      ref={scrollDiv}
    >
      <MobileView>
        <Icon
          name="angle double right"
          style={{ fontSize: 30, color: "#000" }}
          onClick={handleCloseDataWindow}
        />
      </MobileView>
      {dataWindow.content &&
        dataWindow.show.showData === "sign-up" &&
        currentContent === "User" && <SignUp />}
      {dataWindow.content &&
        dataWindow.show.showData === "choose-plan" &&
        currentContent === "User" &&
        plan &&
        plan.code === "non-subscriber" && (
          <>
            <Container>
              <SegmentHeader content={plan} />
              <Segment content={plan} />
              <SegmentFooter />
            </Container>
            <div className="purchase-plan-btn">
              <Button fluid onClick={handlePurchasePlan}>
                {plan.action}
              </Button>
            </div>
          </>
        )}
      {dataWindow.content &&
        dataWindow.show.showData === "choose-plan" &&
        currentContent === "User" &&
        plan &&
        plan.code === "pro" && (
          <Payment plan={plan} dispatch={dispatch} title={"options"} />
        )}
      {dataWindow.content &&
        dataWindow.show.showData === "choose-plan" &&
        currentContent === "User" &&
        plan &&
        plan.code === "ent" && (
          <Payment plan={plan} dispatch={dispatch} title={"options"} />
        )}
      {dataWindow.content &&
        dataWindow.content.isPayment === true &&
        dataWindow.show.showData === "payment" &&
        currentContent === "User" && (
          <Payment plan={plan} dispatch={dispatch} title={"Pay"} />
        )}
      {dataWindow.content &&
        dataWindow.content.isPaymentMethod === true &&
        dataWindow.show.showData === "payment" &&
        currentContent === "User" && (
          <PaymentMethods dispatch={dispatch} user={loggedUser} />
        )}
      {dataWindow.content &&
        (dataWindow.show.showData === "jurisdiction" ||
          dataWindow.show.showData === "zoning" ||
          dataWindow.show.showData === "location" ||
          dataWindow.show.showData === "lot_info" ||
          dataWindow.show.showData === "existing_structures" ||
          dataWindow.show.showData === "use" ||
          dataWindow.show.showData === "farr" ||
          dataWindow.show.showData === "height" ||
          dataWindow.show.showData === "coverage" ||
          dataWindow.show.showData === "units" ||
          dataWindow.show.showData === "maxbuild") && <CurrentQuery />}
      {dataWindow.content && dataWindow.show.showData === "zoning" && (
        <Zone dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "location" && (
        <Location dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "lot_info" && (
        <LandStats dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "use" && (
        <Use dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "jurisdiction" && (
        <JurisFilter dispatch={dispatch} />
      )}
      {dataWindow.content &&
        dataWindow.show.showData === "existing_structures" && (
          <ExistingStructure dispatch={dispatch} />
        )}
      {dataWindow.content && dataWindow.show.showData === "coverage" && (
        <CoverageFilter dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "maxbuild" && (
        <Total dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "height" && (
        <HeightFilter dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "farr" && (
        <FarFilter dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "units" && (
        <UnitsFilter dispatch={dispatch} />
      )}{" "}
      {dataWindow.content && dataWindow.show.showData === "downloadBtn" && (
        <ReportPreview dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "see_result" && (
        <Result dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "purchaseInfo" && (
        <PurchaseInfo
          dispatch={dispatch}
          setCurrentContent={setCurrentContent}
        />
      )}
      {dataWindow.content &&
        dataWindow.content.isPayment === true &&
        dataWindow.show.showData === "payment" &&
        oneTimePayment && (
          <Payment
            plan={plan}
            dispatch={dispatch}
            title={"Pay"}
            quota={false}
          />
        )}
      {dataWindow.content &&
        dataWindow.content.isPayment === true &&
        dataWindow.show.showData === "payment-quota" &&
        oneTimePayment && (
          <Payment plan={plan} dispatch={dispatch} title={"Pay"} quota={true} />
        )}
      {dataWindow.content && dataWindow.show.showData === "summary" && (
        <Summary dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "identifiers" && (
        <Identifiers dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "zone_detail" && (
        <ZoneDetail dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "land_detail" && (
        <LandDetail dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "use_regulations" && (
        <UseRegulation dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "info_far" && (
        <FAR dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "height_limit" && (
        <HeightLimit dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "massing" && (
        <Massing dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "info_coverage" && (
        <Coverage dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "setbacks" && (
        <Setbacks dispatch={dispatch} />
      )}
      {dataWindow.content &&
        dataWindow.show.showData === "residential_units" && (
          <ResidentialUnits dispatch={dispatch} />
        )}
      {dataWindow.content &&
        dataWindow.show.showData === "existing_structure_info" && (
          <ExistingStructureInfo dispatch={dispatch} />
        )}
      {dataWindow.content && dataWindow.show.showData === "development" && (
        <Development dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "other" && (
        <Other dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "parking" && (
        <Parking dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "forget-password" && (
        <ForgetPassword dispatch={dispatch} />
      )}
      {dataWindow.content && dataWindow.show.showData === "change-password" && (
        <ChangePassword dispatch={dispatch} />
      )}
      {dataWindow.content &&
        dataWindow.show.showData === "login" &&
        dataWindow.show.showControl === "filter" && (
          <DefaultUserContent dispatch={dispatch} />
        )}
      {dataWindow.content && dataWindow.show.showData === "saved-property" && (
        <SavedProperty />
      )}
      {dataWindow.content && dataWindow.show.showData === "saved-report" && (
        <SavedReport />
      )}
      {dataWindow.content && dataWindow.show.showData === "saved-filter" && (
        <SavedFilter />
      )}
      {dataWindow.content && dataWindow.show.showData === "architect" && (
        <Architect />
      )}
      {dataWindow.content && dataWindow.show.showData === "real_estate_pro" && (
        <RealEstate />
      )}
      {dataWindow.content && dataWindow.show.showData === "contact_support" && (
        <ContactUs />
      )}
      {dataWindow.content && dataWindow.show.showData === "cities-plans" && (
        <CitiesAndPlans />
      )}
      {dataWindow.content && dataWindow.show.showData === "urbanform_news" && (
        <NewsFeed />
      )}
      {dataWindow.content &&
        dataWindow.show.showData === "jurisdiction_info" && <Jurisdiction />}
      {dataWindow.content && dataWindow.show.showData === "zoning_guides" && (
        <ZoningGuide />
      )}
      {dataWindow.content &&
        dataWindow.show.showControl === "login" &&
        currentContent === "Info" && <DefaultInfoContent />}
      {dataWindow.content &&
        dataWindow.show.showControl === "dashboard" &&
        currentContent === "Info" && <DefaultInfoContent />}
      {dataWindow.content &&
        dataWindow.show.showControl === "login" &&
        currentContent === "Search" && <DefaultFilterContent />}
      {dataWindow.content &&
        dataWindow.show.showControl === "dashboard" &&
        currentContent === "Search" && <DefaultFilterContent />}
      {dataWindow.content &&
        dataWindow.show.showControl === "login" &&
        currentContent === "Help" && <DefaultHelpContent />}
      {dataWindow.content && dataWindow.show.showData === "Help" && (
        <DefaultHelpContent />
      )}
      {dataWindow.content &&
        dataWindow.show.showControl === "dashboard" &&
        currentContent === "Help" && <DefaultHelpContent />}
      {dataWindow.content &&
        dataWindow.show.showControl === "login" &&
        currentContent === "User" && <DefaultUserContent />}
      {dataWindow.content &&
        dataWindow.show.showData === "dashboard" &&
        dataWindow.show.showControl === "dashboard" &&
        currentContent === "User" && <CitiesAndPlans />}
      {dataWindow.content && dataWindow.show.showData === "logout" && (
        <DefaultUserContent />
      )}
      {dataWindow.content &&
        dataWindow.show.showData === "account-verification" &&
        currentContent === "User" && <AccountActivation />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  type: SET_DATA_WINDOW,
  dataWindow: state.dataWindow.dataWindow,
  content: state.dataWindow.content,

  zoneFilterSelectedOptions: state.filters.filterSelectedValues.zone,
  zoneCatFilterSelectedOptions: state.filters.filterSelectedValues.zone_cat,
  lotTypeFilterSelectedOptions: state.filters.filterSelectedValues.lot_type,
  htlimLotDefaultOptions: state.filters.filterDefaultValues.htlim_adjx,
  htlimLotSelectedOptions: state.filters.filterSelectedValues.htlim_adjx,
  htlimLotBonusDefaultOptions: state.filters.filterDefaultValues.htlim_bonx,
  htlimLotBonusSelectedOptions: state.filters.filterSelectedValues.htlim_bonx,
  unitLotMinDefaultOptions: state.filters.filterDefaultValues.umax_adjx_valu,
  unitLotMinSelectedOptions: state.filters.filterSelectedValues.umax_adjx_valu,
  unitLotMaxDefaultOptions: state.filters.filterDefaultValues.umax_bonx_valu,
  unitLotMaxSelectedOptions: state.filters.filterSelectedValues.umax_bonx_valu,
  envpGrndLotAreaDefaultOptions:
    state.filters.filterDefaultValues.envp_delt_area,
  envpGrndLotAreaSelectedOptions:
    state.filters.filterSelectedValues.envp_delt_area,
  envpGrndFtptMaxDefaultOptions:
    state.filters.filterDefaultValues.envp_delt_bonus,
  envpGrndFtptMaxSelectedOptions:
    state.filters.filterSelectedValues.envp_delt_bonus,
  envpBldgMaxAreaDefaultOptions:
    state.filters.filterDefaultValues.envp_bldg_max_area,
  envpBldgMaxAreaSelectedOptions:
    state.filters.filterSelectedValues.envp_bldg_max_area,
  envpBldgMaxResDefaultOptions:
    state.filters.filterDefaultValues.envp_bldg_max_res,
  envpBldgMaxResSelectedOptions:
    state.filters.filterSelectedValues.envp_bldg_max_res,

  farLotDefaultOptions: state.filters.filterDefaultValues.far_lot,
  farLotSelectedOptions: state.filters.filterSelectedValues.far_lot,
  farLotBonusDefaultOptions: state.filters.filterDefaultValues.far_bonx,
  farLotBonusSelectedOptions: state.filters.filterSelectedValues.far_bonx,
  taxlotData: state.taxlot.currentTaxlot
});

export default connect(mapStateToProps)(DataWindow);
