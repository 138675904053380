import { useSelector } from "react-redux";
import { Button, List } from "semantic-ui-react";
import "../../../css/Section.css";
import Pdf from "../../../images/UrbanForm_Sample.pdf";

const Section = ({ content, handleButton, handleSampleReport }) => {
  const { activeContent } = useSelector(
    (state) => state.dataWindow.content.choosePlan
  );
  const { planPrice } = useSelector((state) => state.user);
  return (
    <div className="section-styles">
      {activeContent === "non-subscriber" && content.titleSummary && (
        <span className="title-summary">{content.titleSummary}</span>
      )}
      {activeContent === "non-subscriber" && content.optionSummary && (
        <span className="option-summary">{content.optionSummary}</span>
      )}
      {content.summary && (
        <span className="title-summary">{content.summary}</span>
      )}
      {content.options && (
        <List bulleted>
          {content.options.map((item, index) => {
            return <List.Item key={index}>{item}</List.Item>;
          })}
        </List>
      )}
      {content.quote && (
        <blockquote className="content-quote">
          <i>{content.quote}</i>
        </blockquote>
      )}
      {content.detail && (
        <span className="content-details">
          {content.detail}{" "}
          <a href={`mailto:${content.email}`} target="_blank" rel="noreferrer">
            {content.email}
          </a>
        </span>
      )}
      {content.note && (
        <strong className="note">
          Cost: Free, with option to purchase full reports for single lots at $
          {planPrice.OneTime} ea.
        </strong>
      )}
      {content.button && !handleSampleReport && (
        <div className="btn">
          <Button className={content.button.class} fluid onClick={handleButton}>
            {content.button.label}
          </Button>
        </div>
      )}
      {handleSampleReport && (
        <div className="btn">
          <Button
            className={content.button.class}
            fluid
            onClick={() => window.open(Pdf)}
          >
            {content.button.label}
          </Button>
        </div>
      )}
    </div>
  );
};
export default Section;
