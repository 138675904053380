import { useSelector } from "react-redux";
import "../../../../css/LandDetail.css";
import ICON_LOCK from "../../../../images/lock-1.png";
import Container from "../Container";
import Header from "../Header";
import InfoSection from "../InfoSection";

const LandDetail = () => {
  const { lot_info } = useSelector((state) => state.info.infoDefaultValues);
  const { token } = useSelector((state) => state.user);
  return lot_info.map((field) => {
    if (field.lock == true && field.user != "not-login") {
      return (
        <>
          <div className="lock">
            <div className="summary-data land-detail">
              <Container>
                <Header content={field} />
                <hr className="lock-hr" />
                <div className="section-data">
                  <div className="section-description">
                    <p className="sub-heading-lock">
                      Download full report or sign up for a PRO account to see!
                    </p>
                    <img src={ICON_LOCK} className="lock-image" alt="" />
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </>
      );
    } else {
      if (token != null && field.display !== false) {
        if (field.footerText === true) {
          return (
            <>
              <div className="summary-data land-detail">
                <Container>
                  <Header content={field} />
                  <InfoSection content={field.properties} />
                  <div className="footer-section">
                    <div className="more"></div>
                  </div>
                </Container>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="summary-data land-detail">
                <Container>
                  <Header content={field} />
                  <InfoSection content={field.properties} />
                  <div className="footer-section">
                    <div className="more"></div>
                  </div>
                </Container>
              </div>
            </>
          );
        }
      }
      if (token == null) {
        {
          if (field.user !== "login") {
            if (field.footerText === true) {
              return (
                <>
                  <div className="summary-data land-detail">
                    <Container>
                      <Header content={field} />
                      <InfoSection content={field.properties} />
                      <div className="footer-section">
                        <div className="more"></div>
                      </div>
                    </Container>
                  </div>
                </>
              );
            } else {
              return (
                <>
                  <div className="summary-data land-detail">
                    <Container>
                      <Header content={field} />
                      <InfoSection content={field.properties} />
                      <div className="footer-section">
                        <div className="more"></div>
                      </div>
                    </Container>
                  </div>
                </>
              );
            }
          }
        }
      }
    }
  });
};
export default LandDetail;
