import { useEffect } from "react";
import { useAlert } from "react-alert";
import { MobileView, isIOS } from "react-device-detect";
import { connect, useSelector } from "react-redux";
import { Icon, Message } from "semantic-ui-react";
import {
  setDataWindow,
  setDataWindowContent,
  setMapSearch
} from "../actions/actions_datawindow";
import {
  applyFilter,
  updateSelectedProperty
} from "../actions/actions_filters";
import { setTaxlotError } from "../actions/actions_taxlot";
import {
  setAlertMessage,
  setLoader,
  setUserLoginError
} from "../actions/actions_user";
import logo from "../images/logo.svg";
import HelpContent from "./SidebarContent/HelpContent";
import HomeContent from "./SidebarContent/HomeContent";
import InfoContent from "./SidebarContent/InfoContent";
import SearchBar from "./SidebarContent/SearchBar";
import SearchContent from "./SidebarContent/SearchContent";
import SidebarTable from "./SidebarContent/SidebarTable";
import Dashboard from "./SidebarContent/components/Dashboard";

const Sidebar = ({
  currentContent,
  setCurrentContent,
  taxlot,
  dispatch,
  showPanel,
  setShowPanel
}) => {
  const onDismissError = () => dispatch(setTaxlotError(null));
  const { filterValues, defaultFilter, filterResult } = useSelector(
    (state) => state.filters
  );
  const { user, token } = useSelector((state) => state.user);
  const { show } = useSelector((state) => state.dataWindow);
  const getPanelWidth = () => {
    if (window.innerWidth <= 768) {
      return window.innerWidth === 768
        ? "200px"
        : window.innerWidth - 80 + "px";
    } else {
      return "200px";
    }
  };
  const handleClosePanel = () => {
    dispatch(setMapSearch(false));
    setShowPanel(false);
    setCurrentContent("");
  };
  useEffect(() => {
    if (show.showData === "resetLogin") {
      setCurrentContent("User");
    }
  }, [show.showData]);
  const loginError = useSelector((state) => state.user.loginError);
  const alertMessage = useSelector((state) => state.user.alertMessage);
  const alert = useAlert();
  useEffect(() => {
    loginError !== null && alert.error(loginError);
    dispatch(setUserLoginError(null));
    alertMessage !== null && alert.success(alertMessage);
    dispatch(setAlertMessage(null));
  }, [token, alertMessage, loginError]);
  useEffect(() => {
    currentContent != "" && setShowPanel(true);
    if (currentContent === "Search") {
      dispatch(updateSelectedProperty({}));
      if (
        JSON.stringify(defaultFilter) !== JSON.stringify(filterValues) &&
        user?.status === "Enterprise" &&
        filterResult.data === null
      ) {
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: true,
              contactInfo: {},
              choosePlan: {},
              isPayment: false,
              isFilter: true
            }
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "see_result", showControl: "filters" }
          })
        );
        const object = {
          filter_json: filterValues,
          page_number: 1
        };
        dispatch(setLoader(true));
        dispatch(applyFilter(object, token));
      } else if (
        JSON.stringify(defaultFilter) !== JSON.stringify(filterValues) &&
        user?.status === "Enterprise" &&
        filterResult.data !== null
      ) {
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: true,
              contactInfo: {},
              choosePlan: {},
              isPayment: false,
              isFilter: true
            }
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "see_result", showControl: "filters" }
          })
        );
      }
    }
  }, [currentContent]);
  useEffect(() => {
    setShowPanel(false);
  }, []);
  return (
    showPanel === true && (
      <div
        className="sidebar-menu"
        style={{
          width: `${getPanelWidth()}`,
          right: window.innerWidth <= 768 ? "80px" : "85px",
          padding:
            isIOS === true && !["Home", "searchBar"].includes(currentContent)
              ? "0 0 120px 0"
              : "",
          background:
            currentContent === "Search"
              ? "#FFEFF9"
              : currentContent === "Help"
                ? "#DFF3FF"
                : currentContent === "User"
                  ? "#FFFBE3"
                  : "#F2F2F2"
        }}
      >
        {taxlot.taxlotError !== null && (
          <Message
            negative
            className="taxlot-error-message"
            onDismiss={onDismissError}
          >
            {taxlot.taxlotError}
          </Message>
        )}
        {window.innerWidth < 768 && currentContent != "" && (
          <MobileView>
            <Icon
              name="angle double right"
              style={{ fontSize: 30, color: "#000", padding: "20px 0 0 15px" }}
              onClick={handleClosePanel}
            />
          </MobileView>
        )}
        <div
          style={{
            display: currentContent === "Home" ? "block" : "none",
            height: "inherit",
            marginBottom: isIOS && "-120px"
          }}
        >
          <HomeContent
            logo={logo}
            SidebarTable={SidebarTable}
            showPanel={showPanel}
            setShowPanel={setShowPanel}
          />
        </div>
        <div
          style={{
            display: currentContent === "searchBar" ? "block" : "none",
            height: "inherit"
          }}
        >
          <SearchBar />
        </div>
        <div style={{ display: currentContent === "Info" ? "block" : "none" }}>
          <InfoContent />
        </div>
        <div
          style={{ display: currentContent === "Search" ? "block" : "none" }}
        >
          <SearchContent setCurrentContent={setCurrentContent} />
        </div>
        <div style={{ display: currentContent === "Help" ? "block" : "none" }}>
          <HelpContent />
        </div>
        <div style={{ display: currentContent === "User" ? "block" : "none" }}>
          <Dashboard dispatch={dispatch} />
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  taxlot: state.taxlot
});

export default connect(mapStateToProps)(Sidebar);
