import { env } from "../env";
import { setDataWindow, setDataWindowContent } from "./actions_datawindow";
import { setAlertMessage, setLoader, setUserLoginError } from "./actions_user";

export const SET_SAVED_REPORT_VALUE = "SET_SAVED_REPORT_VALUE";

export function getSaveReport(id) {
  return async (dispatch) => {
    const response = await fetch(
      env.REACT_APP_URBANFORM_API_URL + "/api/report/" + id + "/saved-reports",
      {
        method: "GET"
      }
    );
    const data = await response.json();
    if (Object.keys(data).includes("tokenExpired")) {
      window.location.reload(false);
    } else {
      dispatch(getReport(data)); // Here we are also passing the TYPE so we can set it dynamically in the reducer
      dispatch(setLoader(false));
      dispatch(
        setDataWindow({
          dataWindow: true,
          content: {
            isContact: true,
            contactInfo: {},
            choosePlan: {},
            isPayment: false
          }
        })
      );
      dispatch(
        setDataWindowContent({
          show: { showData: "saved-report", showControl: "dashboard" }
        })
      );
    }
  };
}

export function getReportLink(object) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        env.REACT_APP_URBANFORM_API_URL + "/api/report/pdf",
        {
          method: "POST",
          body: JSON.stringify(object)
        }
      );
      const data = await response.json();
      if (data.status !== 404) {
        window.open(env.REACT_APP_URBANFORM_API_URL + data.path, "_blank");
        dispatch(setLoader(false));
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: true,
              contactInfo: {},
              choosePlan: {},
              isPayment: false
            }
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "saved-report", showControl: "dashboard" }
          })
        );
      } else {
        dispatch(setLoader(false));
        dispatch(setUserLoginError("Error 404: Data Not found!"));
      }
    } catch {
      dispatch(setLoader(false));

      dispatch(setUserLoginError("Error 404: Data Not found!"));
    }
  };
}

export function setReportDelete(resp) {
  return (dispatch) => {
    dispatch(getReport(resp));
  };
}

export function deleteSaveReport(id, object) {
  return async (dispatch) => {
    try {
      await fetch(
        env.REACT_APP_URBANFORM_API_URL +
          "/api/report/" +
          id +
          "/saved-reports",
        {
          method: "DELETE",
          body: JSON.stringify(object)
        }
      );
      dispatch(setAlertMessage("Your property has been deleted successfully"));
    } catch {
      dispatch(setAlertMessage("Something went wrong. Please try again."));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

export function getReport(report) {
  return {
    type: SET_SAVED_REPORT_VALUE,
    payload: report
  };
}
