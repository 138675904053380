export const sanConfig: RegionConfig = {
  MAPBOX_STYLE_URL_BASIC: "mapbox://styles/qvt282/clquwyoxo010l01qwc4nmasi6", //multi-region-style
  MAPBOX_STYLE_URL_SATELLITE:
    "mapbox://styles/qvt282/cl6z1amot004r15qf654ijd1z",
  MAPBOX_STYLE_URL_MBENVELOPE:
    "mapbox://styles/qvt282/clgbse9yy000o01l31x9fk066",
  MAPBOX_LAYER_NAME: "san_region",
  REGION: {
    name: "San Diego, California",
    slug: "san_diego"
  },
  JURISDICTIONS: [
    {
      name: "San Diego, California",
      slug: "san_diego"
    }
  ],
  BBOX: " -117.321625,32.529447,-116.783295,33.114549",
  MAP_CENTER: "-117.151680,32.714222",
  OTHER_REGIONS:
    '{ "portland": [], "seattle": [], "san_diego": [], "austin": [] }',
  PROXIMITY: "-117.321625,32.529447,-116.783295,33.114549",
  MB_TILES_LIST: "taxlots-fill",
  ZOOM_LEVEL: 10.5
};
