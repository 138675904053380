import { SET_SAVED_REPORT_VALUE } from "../actions/actions_save_report";

const initialState = {
  reportDefaultValues: ""
};

const savedReport = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SAVED_REPORT_VALUE:
      var newState = JSON.parse(JSON.stringify(state));
      newState.reportDefaultValues = payload;
      return newState;
    default:
      return { ...state };
  }
};

export default savedReport;
