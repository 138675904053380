import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Icon, Input } from "semantic-ui-react";
import {
  resetPassword,
  setLoader,
  updateResetPassword
} from "../../../actions/actions_user";
import { FORGET_PASSWORD } from "../../../constants";
import "../../../css/ForgetPassword.css";
import { validateEmail } from "../../../utils/Utils";
const ForgetPassword = ({ dispatch }) => {
  const { forgetPasswordError } = useSelector((state) => state.user);
  const [emailError, setEmailError] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [tokenTouched, setTokenTouched] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleResetInfo = (e) => {
    const { name, value } = e.target;
    name === "email" && setEmail(value);
  };
  const handleSubmitResetForm = () => {
    dispatch(setLoader(true));
    let response = false;
    if (email === "") {setEmailError(true);}
    else if (email !== "") {
      response = validateEmail(email);
      setEmailError(response);
    }
    const resetPayload = {
      email: email
    };
    dispatch(resetPassword(resetPayload));
  };
  const handleUpdatePasswordInfo = (e) => {
    const { name, value } = e.target;
    name === "token" && setToken(value);
    name === "password" && setPassword(value);
    name === "confirm_password" && setConfirmPassword(value);
  };
  const handleSubmitUpdatePasswordForm = () => {
    if (
      password !== confirmPassword ||
      password === "" ||
      confirmPassword === ""
    ) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }
    if (token === "") {setTokenTouched(true);}
    else {setTokenTouched(false);}
    const updatePassword = {
      token: token,
      password: password
    };
    if (!passwordMismatch && token !== "") {
      dispatch(setLoader(true));
      dispatch(updateResetPassword(updatePassword));
    }
  };
  return (
    <div className="forget-password">
      <div className="header">
        <Icon disabled name="repeat" className="forget-icon" />
        <h4>Reset Password</h4>
      </div>
      <div className="forget-password-section">
        {forgetPasswordError !== "success" && (
          <Form className="reset-token-email-section">
            <Form.Field error={emailError}>
              <Input
                transparent
                required={true}
                name="email"
                type="email"
                placeholder={"Enter Email"}
                onChange={(e) => handleResetInfo(e)}
              />
              {emailError && <span>Email is required.</span>}
            </Form.Field>
            <div className="forget-password-btn">
              <Button fluid onClick={handleSubmitResetForm}>
                Submit
              </Button>
            </div>
          </Form>
        )}
        {forgetPasswordError === "success" && (
          <Form className="reset-token-email-section">
            {FORGET_PASSWORD.map((field) => {
              return (
                <Form.Field
                  error={
                    (passwordMismatch &&
                      (field.name === "confirm_password" ||
                        field.name === "password")) ||
                    (token === "" && field.name === "token" && tokenTouched)
                  }
                >
                  <Input
                    transparent
                    name={field.name}
                    type={field.type}
                    placeholder={field.placeHolder}
                    onChange={(e) => handleUpdatePasswordInfo(e)}
                  />
                  {passwordMismatch && field.name === "confirm_password" && (
                    <span>Password Mismatch.</span>
                  )}
                  {token === "" && field.name === "token" && tokenTouched && (
                    <span>Please Provide Token.</span>
                  )}
                </Form.Field>
              );
            })}
            <div className="forget-password-btn">
              <Button fluid onClick={handleSubmitUpdatePasswordForm}>
                Update Password
              </Button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};
export default ForgetPassword;
