import { combineReducers } from "redux";

import DynoInfo from "./reducer_dyno_info";
import dataWindow from "./reducers_datawindow";
import filters from "./reducers_filters";
import info from "./reducers_info";
import plans from "./reducers_plans";
import property from "./reducers_property";
import savedReport from "./reducers_saved_report";
import search from "./reducers_search";
import taxlot from "./reducers_taxlot";
import user from "./reducers_user";

const rootReducer = combineReducers({
  filters,
  taxlot,
  search,
  user,
  dataWindow,
  plans,
  info,
  property,
  savedReport,
  DynoInfo
});

export default rootReducer;
