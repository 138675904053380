import {
  SET_BUTTONS_RESPONSE,
  SET_DYNO_USE_REGULATIONS_FILTERS,
  SET_INFO_RESPONSE,
  SET_JURIS_RESPONSE
} from "../actions/actions_dyno_info";

const initialState = {
  dynoInfoResponse: null,
  dynoJurisResponse: null,
  dynoButtonsResponse: null,
  dynoUseRegulationsFilters: null
};

const DynoInfo = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INFO_RESPONSE:
      var newState = JSON.parse(JSON.stringify(state));
      newState.dynoInfoResponse = payload;
      return newState;

    case SET_JURIS_RESPONSE:
      var newState = JSON.parse(JSON.stringify(state));
      newState.dynoJurisResponse = payload;
      return newState;

    case SET_BUTTONS_RESPONSE:
      var newState = JSON.parse(JSON.stringify(state));
      newState.dynoButtonsResponse = payload;
      return newState;
    case SET_DYNO_USE_REGULATIONS_FILTERS:
      var newState = JSON.parse(JSON.stringify(state));
      newState.dynoUseRegulationsFilters = payload;
      return newState;
    default:
      return state;
  }
};

export default DynoInfo;
