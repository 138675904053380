/* eslint-disable @typescript-eslint/no-explicit-any */

import mapboxgl from "mapbox-gl";
import "mapbox-gl-style-switcher/styles.css";

import {
  getAddressByLngLat,
  getSelectInfo,
  setInfoCurrentValue
} from "@/actions/actions_info";
import { INFO_CONSTANT } from "@/constants";
import { env } from "@/env";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import "../../css/Map.css";
import { LayerToggleProps, LayerToggles } from "./LayerToggles";
import { useHandleTaxlotClick } from "./useHandleTaxlotClick";
import { useSearchBarGeocoder } from "./useSearchBarGeocoder";

type MapProps = {
  sidebarVisible: boolean;
  dispatch: any;
  /** The sidebar content, TODO remove this in favor of linking */
  setCurrentContent: () => void;
};

/** The map coordinates the filter results, searchbar, and layer toggle visibility */
export function Map({ sidebarVisible, setCurrentContent }: MapProps) {
  const [geocoder, setGeocoder] = useState(null);
  const [map, setMap] = useState(null);
  const [initialStyle, setInitialStyle] = useState("Basic-prod");
  const [mapStyleGlobally, setMapStyleGlobally] = useState(null);
  const primeAccNums: string[] = useSelector(
    (state) => state.search.primaccnum
  );

  const { selectedProperty } = useSelector((state) => state.filters);

  // todo pass map to fn not hook
  const addSearchBarGeocoderToMap = useSearchBarGeocoder();
  const onTaxlotChange = (e: Event, map: any, s: any) => {
    handleMapTaxlotClick({ e, map, searchBarGeocoder: s });
    setCurrentContent("Info");
  };

  const handleMapTaxlotClick = useHandleTaxlotClick();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const fetchPropertyData = (primaccnum, lngLat) => {
    const body = {
      primaccnum: primaccnum
    };

    dispatch(setInfoCurrentValue(INFO_CONSTANT));
    dispatch(getSelectInfo(body, INFO_CONSTANT, lngLat, Math.random(), user));
  };

  useEffect(() => {
    console.log({ selectedProperty });
    if (selectedProperty?.id) {
      setCurrentContent("Info");
      const lng = parseFloat(
        selectedProperty.centre_geom.split(";")[1].split(" ")[0].substr(6)
      );
      const lat = parseFloat(
        selectedProperty.centre_geom.split(";")[1].split(" ")[1].split(")")[0]
      );
      const langlat = {
        lat: lat,
        lng: lng
      };
      const body = {
        primaccnum: selectedProperty.primaccnum, //same here
        searchtype: "filter"
      };
      dispatch(getAddressByLngLat(body, lat, lng, geocoder));
      fetchPropertyData(selectedProperty.primaccnum, langlat); //same here
    }
  }, [selectedProperty.id, selectedProperty.primaccnum, setCurrentContent]); //add selectedProperty.primaccnum

  const [isSatelliteVisible, setIsSatelliteVisible] = useState(false);
  const [isBuildingFootprintsVisible, setIsBuildingFootprintsVisible] =
    useState(true);
  const [isEnvelope3dVisible, setIsEnvelope3dVisible] = useState(false);

  // todo could use state machine
  const handleToggleSatelliteVisibility = () => {
    let mapStyle;
    if (isSatelliteVisible) {
      // changing to streets
      mapStyle = config.MAPBOX_STYLE_URL_BASIC;
      setIsBuildingFootprintsVisible(true);
    } else {
      // changing to satellite
      mapStyle = config.MAPBOX_STYLE_URL_SATELLITE;
      setIsEnvelope3dVisible(false);
      setIsBuildingFootprintsVisible(false);
    }

    map.setStyle(mapStyle);
    setMapStyleGlobally(mapStyle);
    setIsSatelliteVisible(!isSatelliteVisible);
  };

  const handleToggleBuildingFootprintVisibility = () => {
    if (isBuildingFootprintsVisible) {
      map.setLayoutProperty("bldg-fill", "visibility", "none");
    } else {
      map.setLayoutProperty("bldg-fill", "visibility", "visible");
    }
    setIsBuildingFootprintsVisible(!isBuildingFootprintsVisible);
  };

  const handleToggleEnvelope3dVisibility = () => {
    // TODO
    let style;
    if (!isEnvelope3dVisible) {
      // changing to 3d
      setIsBuildingFootprintsVisible(false);
      style = config.MAPBOX_STYLE_URL_MBENVELOPE;
    } else if (mapStyleGlobally === null) {
      style = config.MAPBOX_STYLE_URL_BASIC;
    } else {
      // changing to default
      setIsBuildingFootprintsVisible(true);
      setIsSatelliteVisible(false);
      style = mapStyleGlobally;
    }
    map.setStyle(style);
    setIsEnvelope3dVisible(!isEnvelope3dVisible);
  };

  const layers: LayerToggleProps[] = [
    {
      checked: isSatelliteVisible,
      label: "Satellite View",
      // disabled: false,
      onChange: handleToggleSatelliteVisibility
    },
    {
      checked: isBuildingFootprintsVisible,
      label: "Building Footprints",
      // don't allow toggle footprints if on 3d build or on satellite
      disabled: isSatelliteVisible || isEnvelope3dVisible,
      onChange: handleToggleBuildingFootprintVisibility
    },
    {
      checked: isEnvelope3dVisible,
      label: "MaxBuild 3d",
      // don't allow toggle 3d if on satellite if on 3d build or on satellite
      // disabled: isSatelliteVisible || isEnvelope3dVisible,
      onChange: handleToggleEnvelope3dVisibility
    }
  ];

  // Initialize the map
  useEffect(() => {
    mapboxgl.accessToken = env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const map = new mapboxgl.Map({
      container: "mapbox-map",
      style: config.MAPBOX_STYLE_URL_BASIC,
      center: config.MAP_CENTER.split(","),
      zoom: config.ZOOM_LEVEL
    });
    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
    map.addControl(
      new mapboxgl.ScaleControl({
        maxWidth: 80,
        unit: "metric"
      }),
      "bottom-right"
    );
    map.addControl(
      new mapboxgl.NavigationControl({
        showZoom: false
      }),
      "bottom-right"
    );
    map.on("styledata", (e) => {
      const { name } = e.style.stylesheet;
      if (name === initialStyle) {
        return;
      }
      setInitialStyle(name);
    });

    map.on("load", () => {
      const s = addSearchBarGeocoderToMap(map);
      setGeocoder(s);

      map.on("click", config.MB_TILES_LIST.split(","), (e) =>
        onTaxlotChange(e, map, s)
      );
    });

    setMap(map);
  }, []);

  // Resize map if sidebar has changed
  useEffect(() => {
    if (map) {
      setTimeout(() => {
        map.resize();
      }, 500);
    }
  }, [sidebarVisible]);

  const handleUpdateFilter = () => {
    if (!map) {
      return;
    }
    // 1 add each primaccnum polygon from filter response result to layer
    const filteredTaxlotFeatures = map?.querySourceFeatures("composite", {
      sourceLayer: config.MAPBOX_LAYER_NAME,
      filter: ["in", "PRIMACCNUM", ...primeAccNums]
    });

    console.log("Searching for", primeAccNums, "in", config.MAPBOX_LAYER_NAME);

    const updateOrCreateFilterSourceAndLayer = () => {
      // if there is not yet the filtered source on the map (first time) then create it
      console.log(map.getSource("filtered-lot"), "source");
      if (!map.getSource("filtered-lot")) {
        map.addSource("filtered-lot", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: filteredTaxlotFeatures
          }
        });
        map.addLayer({
          id: "filtered-lot",
          source: "filtered-lot",
          type: "line",
          paint: {
            "line-color": "#ff00ff",
            "line-width": 1.62
          }
        });
      } else {
        map.getSource("filtered-lot").setData({
          type: "FeatureCollection",
          features: filteredTaxlotFeatures
        });
      }
    };

    const updateMapView = () => {
      if (filteredTaxlotFeatures?.length) {
        map.setLayoutProperty("filtered-lot", "visibility", "visible");
      }
    };
    updateOrCreateFilterSourceAndLayer();
    updateMapView();
  };

  useEffect(() => {
    handleUpdateFilter();
  }, [primeAccNums]);

  return (
    <>
      <div id="mapbox-map" className="mbl-mab-box"></div>
      <LayerToggles layers={layers} />
      {/* <FilterResults /> */}
    </>
  );
}
