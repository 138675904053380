import jwt_decode from "jwt-decode";
import { INFO_CONSTANT, UF_TOKEN, citiesAndPlans } from "../constants";
import { env } from "../env";
import { downloadPdf, manageCitiesAndPlans } from "../utils/Utils";
import { setDataWindow, setDataWindowContent } from "./actions_datawindow";
import { setFilterPlan, updateFilterPayment } from "./actions_filters";
import { infoUserNotLoggedIn, updatedInfoSection } from "./actions_info";
import {
  handleUserLogin,
  logoutUser,
  setAlertMessage,
  setLoader,
  setUserLoginError
} from "./actions_user";

export const USER_ROLES = "USER_ROLES";
export const USER_ROLES_ERROR = "USER_ROLES_ERROR";
export const USER_PLAN_SUBSCRIPTION = "USER_PLAN_SUBSCRIPTION";
export const USER_PLAN_SUBSCRIPTION_ERROR = "USER_PLAN_SUBSCRIPTION_ERROR";
export const SET_CURRENT_PLAN = "SET_CURRENT_PLAN";
export const SET_CUSTOMER_ID = "SET_CUSTOMER_ID";
export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
export const SET_CITIES_AND_PLANS = "SET_CITIES_AND_PLANS";

export function getUserRoles() {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/users/roles/`,
        {
          method: "GET"
        }
      );
      const data = await response.json();
      dispatch(handleGetUserRoles(data));
    } catch {
      // noop
    }
  };
}
export function handleGetUserRoles(roles) {
  return {
    type: USER_ROLES,
    payload: roles
  };
}
export function handleUserRolesError(loginError) {
  return {
    type: USER_ROLES_ERROR,
    payload: loginError
  };
}
export function getPlanSubscription(
  body,
  token,
  filterUpgradeProcess,
  infoNotLoggedIn = false,
  primeAccountNumber
) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/payment/`,
        {
          method: "POST",
          body: JSON.stringify(body)
        }
      );
      const data = await response.json();
      if (
        Object.keys(data).includes("message") &&
        !Object.keys(data).includes("token")
      ) {
        dispatch(setUserLoginError(data.message));
      } else {
        dispatch(handlePlanSubscription(data));
        const user = jwt_decode(data.token);
        dispatch(handleUserLogin({ user: user.user, token: data.token }));
        window.localStorage.setItem(UF_TOKEN, data.token);
        if (filterUpgradeProcess) {
          dispatch(
            updateFilterPayment({
              filterUpgradeProcess: true,
              filterPaymentDone: true
            })
          );
          dispatch(
            setDataWindow({
              dataWindow: true,
              content: {
                isContact: true,
                contactInfo: {},
                choosePlan: {},
                isPayment: false,
                isFilter: true
              }
            })
          );
          dispatch(
            setDataWindowContent({
              show: { showData: "see_result", showControl: "filters" }
            })
          );
        } else {
          dispatch(
            setDataWindow({
              dataWindow: false,
              content: { isContact: null, choosePlan: {} }
            })
          );
          dispatch(
            setDataWindowContent({
              show: { showData: "", showControl: "dashboard" }
            })
          );
        }
        dispatch(
          setAlertMessage("Your account plan has been updated successfully.")
        );
      }
      dispatch(setFilterPlan(null));
      dispatch(setLoader(false));
      if (infoNotLoggedIn === true) {
        dispatch(setLoader(true));
        dispatch(infoUserNotLoggedIn(false));
        const infoPayload = {
          primaccnum: primeAccountNumber
        };
        dispatch(updatedInfoSection(infoPayload, INFO_CONSTANT));
      }
    } catch (error) {
      dispatch(handlePlanSubscriptionError(error.detail));
      dispatch(setLoader(false));
    }
  };
}

export function handlePlanSubscription(plan) {
  return {
    type: USER_PLAN_SUBSCRIPTION,
    payload: plan
  };
}
export function handlePlanSubscriptionError(loginError) {
  return {
    type: USER_PLAN_SUBSCRIPTION_ERROR,
    payload: loginError
  };
}

export function getPaymentMethods(token, isPayment = false, user = {}) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/payment/user/get-payment-methods`,
        {
          method: "POST"
        }
      );
      const data = await response.json();
      if (isPayment) {
        if (data?.data) {
          dispatch(setCustomerId(data.data[0].customer_id));
          dispatch(getAllSubscriptions(data.data[0].customer_id, user));
        }
      } else {
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: null,
              contactInfo: {},
              choosePlan: {},
              isPaymentMethod: true,
              paymentMethods: data.data
            }
          })
        );
      }
    } catch {
      // dispatch(setAlertMessage("An error has occurred please try again."));
    }
  };
}

export function addPaymentMethod(body, token) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/payment/user/add-payment-method`,
        {
          method: "POST",
          body: JSON.stringify(body)
        }
      );
      const data = await response.json();
      if (Object.keys(resp).includes("error")) {
        if (data.error === "Payment matching query does not exist.") {
          dispatch(setUserLoginError("A valid saved credit card is needed"));
        } else {
          // dispatch(
          //   setUserLoginError("An error has occurred please try again.")
          // );
        }
      } else {
        dispatch(setLoader(false));
        dispatch(getPaymentMethods(token));
        dispatch(
          setAlertMessage("New Payment method has been added successfully.")
        );
      }
      dispatch(setLoader(false));
    } catch {
      dispatch(setLoader(false));
      // dispatch(setAlertMessage("An error has occured please try again."));
    }
  };
}

export function setDefaultPaymentMethod(body, token) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/payment/user/change-default-payment`,
        {
          method: "POST",
          body: JSON.stringify(body)
        }
      );
      const data = await response.json();
      dispatch(getPaymentMethods(token));
      dispatch(
        setAlertMessage("Default payment method has been changed successfully.")
      );
    } catch {
      // dispatch(setAlertMessage("An error has occurred please try again."));
    }
  };
}

export function cancelSubscription() {
  return async (dispatch) => {
    try {
      await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/payment/user/cancel-subscription`,
        {
          method: "POST"
        }
      );
      dispatch(setAlertMessage("Your plan subscription has been canceled."));
      dispatch(logoutUser());
    } catch {
      // dispatch(setAlertMessage("An error has occurred please try again."));
    }
  };
}

export function oneTimePaymentReport(object, alert) {
  const headers = { "Region-Slug": localStorage.getItem("regionSlug") };

  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/report/one-time`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(object)
        }
      );
      const data = await response.json();
      if (!Object.keys(data).includes("file_name")) {
        if (Object.keys(data).includes("error")) {
          alert.error(data.error);
          dispatch(setLoader(false));
        } else if (Object.keys(data).includes("message")) {
          alert.error(data.message);
          dispatch(setLoader(false));
        } else {
          alert.error("Payment Failed. Please Try Again!");
          dispatch(setLoader(false));
        }
      } else {
        downloadPdf(data.path, data.file_name);
        alert.success("Your report has been generated!");
        dispatch(setLoader(false));
        dispatch(
          setDataWindowContent({
            show: { showData: "", showControl: "info" }
          })
        );
        dispatch(
          setDataWindow({
            dataWindow: false,
            content: {
              isContact: null,
              contactInfo: {},
              choosePlan: {},
              isPayment: false
            }
          })
        );
      }
    } catch {
      dispatch(setLoader(false));
      dispatch(alert.error("Something went wrong. Please try again later."));
    }
  };
}

export function anonymousReportDownload(object, alert) {
  const headers = { "Region-Slug": localStorage.getItem("regionSlug") };

  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/report/download-anonymous`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(object)
        }
      );
      const data = await response.json();
      if (!Object.keys(data).includes("file_name")) {
        if (Object.keys(data).includes("error")) {
          alert.error(data.error);
          dispatch(setLoader(false));
        } else if (Object.keys(data).includes("message")) {
          alert.error(data.message);
          dispatch(setLoader(false));
        } else {
          alert.error("Payment Failed. Please Try Again!");
          dispatch(setLoader(false));
        }
      } else {
        downloadPdf(data.path, data.file_name);
        alert.success("Your report has been generated!");
        dispatch(setLoader(false));
        dispatch(
          setDataWindowContent({
            show: { showData: "", showControl: "info" }
          })
        );
        dispatch(
          setDataWindow({
            dataWindow: false,
            content: {
              isContact: null,
              contactInfo: {},
              choosePlan: {},
              isPayment: false
            }
          })
        );
      }
    } catch {
      dispatch(setLoader(false));
      dispatch(alert.error("Something went wrong. Please try again later."));
    }
  };
}

export function deletePaymentMethod(object) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/payment/user/delete-user-card`,
        {
          method: "DELETE",
          body: JSON.stringify(object)
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("message")) {
        dispatch(setAlertMessage(data.message));
        dispatch(getPaymentMethods(window.localStorage.getItem("_uft")));
      } else if (Object.keys(data).includes("error")) {
        dispatch(setUserLoginError(data.error));
      }
      dispatch(setLoader(false));
    } catch {
      dispatch(setLoader(false));
      dispatch(
        setUserLoginError("Something went wrong. Please try again later.")
      );
    }
  };
}
export function updatePaymentMethod(object) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/payment/user/update-card-info`,
        {
          method: "POST",
          body: JSON.stringify(object)
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("message")) {
        dispatch(
          setAlertMessage("User card information updated successfully!")
        );
        dispatch(getPaymentMethods(window.localStorage.getItem("_uft")));
      } else if (Object.keys(data).includes("error")) {
        dispatch(setUserLoginError(data.error));
      }
      dispatch(setLoader(false));
    } catch {
      dispatch(setLoader(false));
      dispatch(
        setUserLoginError("Something went wrong. Please try again later.")
      );
    }
  };
}

export function getAllSubscriptions(customerId, user) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/payment/subscription/${customerId}`,
        {
          method: "GET"
        }
      );
      const data = await response.json();
      dispatch(setLoader(false));
      dispatch(setCustomerSubscriptions(data.data));
      dispatch(
        manageCitiesAndPlans(
          citiesAndPlans,
          user.status === "Basic" ? "pro" : "ent",
          dispatch,
          data.data,
          true
        )
      );
    } catch {
      // noop
    } finally {
      dispatch(setLoader(false));
    }
  };
}

export const setCurrentPlan = (data) => {
  return {
    type: SET_CURRENT_PLAN,
    payload: data
  };
};

export const setCustomerId = (data) => {
  return {
    type: SET_CUSTOMER_ID,
    payload: data
  };
};

export const setCustomerSubscriptions = (data) => {
  return {
    type: SET_SUBSCRIPTIONS,
    payload: data
  };
};

export const setCitiesAndPlans = (data) => {
  return {
    type: SET_CITIES_AND_PLANS,
    payload: data
  };
};
