import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUseAllowance,
  updateUseCategory
} from "../../../../actions/actions_info";
import "../../../../css/UseRegulation.css";
import Container from "../Container";
import UseRegulationWindow from "./UseRegulationWindow";
import UseRegulationWindowLocked from "./UseRegulationWindowLocked";

const UseRegulation = () => {
  const dispatch = useDispatch();
  const { allowance, category } = useSelector(
    (state) => state.info.useRegulationCheck
  );
  const { dynoInfoResponse } = useSelector((state) => state.DynoInfo);
  const [allowanceBasedRegulations, setAllowanceBasedRegulations] =
    React.useState({});
  const [viewType, setViewType] = React.useState("category");

  const manageUseRegulation = (e) => {
    const { name, checked } = e.target;
    if (name === "category") {
      setViewType("category");
      dispatch(updateUseCategory(checked));
    } else {
      setViewType("allowance");
      dispatch(updateUseAllowance(checked));
    }
  };
  useEffect(() => {
    //hook for managing view by allowance data
    const tempArr = []; //for temporarily storing  viewByAllowance data
    const uc_conditons = {};
    if (dynoInfoResponse.use_regulations[0].properties.length) {
      //following map will create useConditions array dynamically and will gather all properties inside tempArr
      dynoInfoResponse.use_regulations.map((info) => {
        const useConditions = info.properties[2][0]?.value?.split(";");
        if (!Object.keys(uc_conditons).length) {
          useConditions?.map((uc, idx) => {
            const index = uc.indexOf("=");
            if (index !== -1) {
              const truncatedString = uc.substring(0, index);

              uc_conditons[truncatedString.trim()] = idx + 1;
            }
          });
        }
        info.properties[0].map((x) => tempArr.push(x));
      });
      uc_conditons["null"] = 9;
      //sorting tempArr according to uc_conditions order
      tempArr.sort((a, b) => {
        const aValue = uc_conditons[a.value];
        const bValue = uc_conditons[b.value];

        if (aValue !== bValue) {
          return aValue - bValue;
        } else {
          return a.title.localeCompare(b.title);
        }
      });
      //now tempArr contains sorted data -> now we will store it according to UC_condtions in reformedObj
      const reformedObj = {};
      Object.keys(uc_conditons).map((uc) => {
        reformedObj[uc] = tempArr.filter((obj) => obj.value === uc);
      });
      setAllowanceBasedRegulations(reformedObj);
    }
  }, []);

  const UseListDisplayer = () => (
    <>
      {viewType === "category" ? (
        <div>
          {dynoInfoResponse.use_regulations?.map((info) => {
            const useConditions = info.properties[2][0]?.value?.split(";");
            return (
              <div className="use-regulation">
                <div className="summary-data">
                  <Container>
                    <div className="data-header">
                      <div className="header-display">
                        <h2>{info.value}</h2>
                      </div>
                      <div className="aclp">
                        {useConditions?.map((uc) => (
                          <p className={"sub-title"}>{uc}</p>
                        ))}
                      </div>
                    </div>
                    <UseRegulationWindow data={info} />
                    <div className="footer-section"></div>
                  </Container>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        Object.keys(allowanceBasedRegulations).map((use_x) => {
          const useConditions =
            dynoInfoResponse.use_regulations[0]?.properties[2][0]?.value?.split(
              ";"
            );
          if (use_x !== "null") {
            return (
              <div className="use-regulation">
                <div className="summary-data">
                  <Container>
                    <div className="data-header">
                      <div className="header-display">
                        <h2>{use_x}</h2>
                      </div>
                      <div className="aclp">
                        {useConditions?.map((uc) => (
                          <p className={"sub-title"}>{uc}</p>
                        ))}
                      </div>
                    </div>
                    <div className="section">
                      <>
                        {allowanceBasedRegulations[use_x].map((oc) => (
                          <>
                            <div className="info-tabs">
                              <div className="card-label">
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    {oc.title}
                                  </span>{" "}
                                  : {oc.value ? oc.value : "N/A"}
                                </p>
                              </div>
                            </div>
                            <div className="industrial_p section-data" />
                          </>
                        ))}

                        <>
                          <>
                            {!allowanceBasedRegulations[use_x].length && (
                              <>
                                <div className="info-tabs">
                                  <div className="card-label">
                                    <p>
                                      <span
                                        style={{ fontWeight: "bold" }}
                                      ></span>{" "}
                                      N/A
                                    </p>
                                  </div>
                                </div>
                                <div className="industrial_p section-data" />
                              </>
                            )}
                          </>
                          <div className="industrial_p section-data" />
                        </>
                      </>
                    </div>
                    <div className="footer-section"></div>
                  </Container>
                </div>
              </div>
            );
          }
        })
      )}
    </>
  );

  const CheckboxDisplayer = () => (
    <>
      <div className="use-regulation">
        <div className="summary-data " style={0 ? { display: "none" } : {}}>
          <Container>
            <div className="data-header">
              <div className="header-display">
                <h2>USE</h2>
              </div>
            </div>
            <hr style={{ color: "#f2f2f2", width: "99%", marginTop: "-1px" }} />
            <div
              className="checkbox-section"
              style={{
                color: "#5a636f",
                fontSize: "18px",
                marginLeft: "49px",
                fontWeight: "normal",
                marginTop: "18px",
                opacity: "0.9",
                paddingBottom: "5px"
              }}
            >
              <div style={{ marginBottom: "12px" }}>
                <input
                  type="checkbox"
                  name="category"
                  id="category"
                  checked={category}
                  onChange={(e) => {
                    manageUseRegulation(e);
                  }}
                />
                <label htmlFor="category" style={{ marginLeft: "10px" }}>
                  View by category
                </label>
              </div>
              <div style={{ marginBottom: "18px" }}>
                <input
                  type="checkbox"
                  name="allowance"
                  id="allowance"
                  checked={allowance}
                  onChange={(e) => {
                    manageUseRegulation(e);
                  }}
                />
                <label htmlFor="Allowance" style={{ marginLeft: "10px" }}>
                  View by allowance
                </label>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
  return (
    <div>
      {/* if user is logged in and he has subscriptions other than basic then we show checkboxes */}
      {dynoInfoResponse.use_regulations[0]?.properties.length && (
        <CheckboxDisplayer />
      )}

      {dynoInfoResponse.use_regulations[0]?.properties.length ? (
        <UseListDisplayer />
      ) : (
        // if user doesnt have permission to view the data
        <div>
          {dynoInfoResponse.use_regulations?.map((info) => {
            return (
              <div className="use-regulation">
                <div className="summary-data">
                  <UseRegulationWindowLocked heading={info.value} />
                  <div className="footer-section"></div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default UseRegulation;
