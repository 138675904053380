import { useSelector } from "react-redux";
import { UpdateFilter } from "../../../../actions/actions_filters";
import "../../../../css/ExistingStructure.css";
import { checkboxUpdate } from "../../../../utils/Utils";
import Container from "../Container";
import Header from "../Header";
import SliderSection from "../SliderSection";

const ExistingStructure = ({ dispatch }) => {
  const { existing_structures } = useSelector(
    (state) => state.filters.filterValues
  );
  const { show } = useSelector((state) => state.dataWindow);
  const onChangeSelect = (e) => {
    const { name, checked } = e.target;
    const currentFilter = show.showData;
    const UpdatedFilter = {
      name: currentFilter,
      filterData: checkboxUpdate(
        currentFilter,
        existing_structures,
        name,
        checked
      )
    };
    dispatch(UpdateFilter(UpdatedFilter));
  };
  const contentExisting = {
    icon: "ICON_HOME",
    title: "Existing Structure"
  };
  const count = 0;
  return (
    <div className="existing-structure">
      {existing_structures.map((field) => {
        return (
          <>
            <Container>
              <Header content={field} />
              <div className="slider-section">
                <SliderSection
                  range={field.range}
                  content={field.fields}
                  selectedFilter={existing_structures}
                  dispatch={dispatch}
                  checkboxField={field.checkbox}
                  maxRangeValue={field?.maxValue ?? 99999999}
                  columnName={field.column_name}
                />
              </div>
              <br />
            </Container>
          </>
        );
      })}
      ;
    </div>
  );
};

export default ExistingStructure;
