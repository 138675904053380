import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import {
  UpdateFilter,
  getFiltersData
} from "../../../../actions/actions_filters";
import config from "../../../../config";
import "../../../../css/zone.css";
import { JurisCheckboxUpdate, checkboxUpdate } from "../../../../utils/Utils";

const LSCheckbox = ({ dispatch, info, columns, selectedFilter, style }) => {
  const { show } = useSelector((state) => state.dataWindow);

  const onChangeSelect = (e) => {
    const { name, checked } = e.target;

    const currentFilter = show.showData;
    const UpdatedFilter = {
      name: currentFilter,
      filterData:
        show.showData === "jurisdiction"
          ? JurisCheckboxUpdate(currentFilter, selectedFilter, name, checked)
          : checkboxUpdate(currentFilter, selectedFilter, name, checked)
    };

    // if we're showing the jurisdiction select filter
    if (show.showData === "jurisdiction") {
      // and the checkbox is checked
      if (checked) {
        // if the name of the checked checkbox is the region slug (which it will never be?)
        if (name === config.REGION.slug) {
          // then set the region slug to the current
          localStorage.setItem("regionSlug", config.REGION.slug);
          dispatch(getFiltersData(undefined, UpdatedFilter));
        } else {
          // lookup jurisdiction slug by name
          const jurisdiction = config.JURISDICTIONS.find(
            (j) => j.name === name
          );
          const jurisdictionSlug = jurisdiction?.slug;

          localStorage.setItem("jurisdictionSlug", jurisdictionSlug);
          dispatch(getFiltersData(undefined, UpdatedFilter));
        }
      } else {
        dispatch(UpdateFilter(UpdatedFilter));
      }
    } else {
      dispatch(UpdateFilter(UpdatedFilter));
    }
  };
  return (
    <>
      <Grid columns={columns} style={style ?? {}}>
        {info?.checkbox &&
          info.checkbox.map((field) => {
            return (
              <Grid.Column style={{ padding: "7px 0" }}>
                <div className="ui checkbox">
                  <input
                    type={field.type}
                    name={field.name}
                    required={field.required}
                    onChange={onChangeSelect}
                    checked={field.action}
                  />
                  <label>{field.label}</label>
                </div>
              </Grid.Column>
            );
          })}
      </Grid>
    </>
  );
};

export default LSCheckbox;
