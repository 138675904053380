import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Button, Confirm, Image } from "semantic-ui-react";
import {
  setDataWindow,
  setDataWindowContent
} from "../../../actions/actions_datawindow";
import {
  getSaveFilters,
  setFilterValues,
  updateResult
} from "../../../actions/actions_filters";
import {
  cancelSubscription,
  getPaymentMethods
} from "../../../actions/actions_plans";
import { getSaveProperty } from "../../../actions/actions_save_property";
import { getSaveReport } from "../../../actions/actions_save_report";
import { setFilterPrimeAccountNumbers } from "../../../actions/actions_search";
import {
  activityLogger,
  logoutUser,
  setALLPaymentMethod,
  setDefaultPaymentMethod,
  setLoader,
  setQuotaExceed
} from "../../../actions/actions_user";
import { ACCOUNT_INFO, ICONS, SIGNUP_FIELDS } from "../../../constants";
import "../../../css/Dashboard.css";
import contact from "../../../images/help-contact-us.svg";

const Dashboard = ({ dispatch }) => {
  const { user, token } = useSelector((state) => state.user);
  const loggedUser = useSelector((state) => state.user);
  const { updateContent } = useSelector((state) => state.dataWindow);
  const { citiesAndPlans } = useSelector((state) => state.plans);
  const [activeSection, setActiveSection] = useState("cities-plans");
  const [loginUser, setLoginUser] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [subDate, setSubDate] = useState("");
  const dataWindow = useSelector((state) => state.dataWindow);
  const { showFolder } = useSelector((state) => state.filters);
  const { setFlagFile } = useSelector((state) => state.property);
  const { defaultFilter } = useSelector((state) => state.filters);
  const contactButton = isMobile
    ? { fontWeight: "400" }
    : { fontWeight: "400" };

  useEffect(() => {
    if (token) {
      dispatch(getPaymentMethods(token, true, user));
    }
  }, [token]);

  useEffect(() => {
    // if update modal of contact info is closed then reset activeSession
    updateContent === false && setActiveSection("");
  }, [updateContent]);

  useEffect(() => {
    if (setFlagFile === true) {
      setActiveSection("properties");
    }
  }, [setFlagFile]);
  const handleConfirm = () => {
    setShowConfirm(false);
    dispatch(cancelSubscription(token));
  };
  const handleCancel = () => {
    setShowConfirm(false);
  };
  useEffect(() => {
    showFolder && setActiveSection("filters");
  }, [showFolder]);

  const Storedtoken = window.localStorage.getItem("_uft");

  const handleAccountContorls = (control) => {
    if (control === activeSection) {
      setActiveSection("");
      dispatch(
        setDataWindowContent({
          show: { showData: "", showControl: "dashboard" }
        })
      );
      dispatch(
        setDataWindow({
          dataWindow: false,
          content: {
            isContact: null,
            contactInfo: {},
            choosePlan: {},
            isPayment: false
          }
        })
      );
      return;
    }
    setActiveSection(control);
    dispatch(
      setDataWindowContent({
        show: { showData: control, showControl: "dashboard" }
      })
    );

    if (control != "logout") {
      const body = {
        event: "Tab Selected: " + control
      };
      dispatch(activityLogger(body));
    }
    switch (control) {
      case "profile":
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: true,
              contactInfo: SIGNUP_FIELDS,
              values: userInfo,
              choosePlan: {}
            },
            updateContent: true
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "sign-up", showControl: "dashboard" }
          })
        );
        break;
      case "cities-plans":
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: true,
              contactInfo: {},
              choosePlan: {},
              isPayment: false
            }
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "cities-plans", showControl: "dashboard" }
          })
        );
        break;
      case "properties":
        dispatch(setLoader(true));
        dispatch(getSaveProperty(user.id));
        break;
      case "filters":
        dispatch(setLoader(true));
        dispatch(getSaveFilters(user.id));
        break;
      case "info":
        dispatch(
          setDataWindow({
            dataWindow: false,
            content: {
              isContact: null,
              contactInfo: {},
              choosePlan: {},
              isPayment: false
            }
          })
        );

        break;
      case "reports":
        dispatch(setLoader(true));
        dispatch(getSaveReport(user.id));
        break;
      case "payment":
        dispatch(getPaymentMethods(token));

        break;
      case "logout":
        const body = {
          event: "User Logout"
        };
        dispatch(activityLogger(body));
        dispatch(setQuotaExceed(false));
        dispatch(setFilterValues(defaultFilter));
        dispatch(logoutUser(Storedtoken));
        dispatch(setDefaultPaymentMethod(null));
        dispatch(setALLPaymentMethod(null));
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: { isContact: null, choosePlan: {} }
          })
        );
        dispatch(
          updateResult({
            count: 0,
            data: null
          })
        );
        dispatch(setFilterPrimeAccountNumbers([]));
        break;
      default:
        break;
    }
  };

  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    if (user != null) {
      setUserInfo({
        first_name: { value: user.first_name, required: true },
        last_name: { value: user.last_name, required: true },
        company: { value: user.company, required: false },
        industry: { value: user.industry, required: false },
        title: { value: user.title, required: false },
        address_1: { value: user.address_1, required: true },
        address_2: { value: user.address_2, required: false },
        city: { value: user.city, required: true },
        state: { value: user.state, required: true },
        zip_code: { value: user.zip_code, required: true },
        phone_number: { value: user.phone_number, required: false },
        email: { value: user.email, required: true },
        password: { value: user.password, required: false },
        password_confirm: { value: user.password_confirm, required: false }
      });
    }
    const date =
      user != null && ["null", null].includes(user.last_payment_date) === false
        ? user.last_payment_date
        : user != null && user.date_joined;
    if (user != null && user.last_payment_date) {
      setSubDate(new Date(date || "").toUTCString().slice(0, 12));
    }
  }, [user]);
  const getIconHelper = (name) => {
    return ICONS.filter((icon) => name === icon.name)[0].icon;
  };

  return (
    <div className="dashboard-container">
      {ACCOUNT_INFO.map((info) => {
        return (
          <div
            key={info.id}
            className={
              info.code === "logout" ? "account-item-no-hover" : "account-item"
            }
            style={
              info.code === "logout"
                ? { background: "#43c1f3", borderRadius: 14 }
                : {}
            }
          >
            {info.code === "logout" && !user ? null : (
              <Button
                size="large"
                fluid
                className={`${
                  activeSection === info.code ? "active" : ""
                } curve-wrapper account-contorls-btns disable`}
                onClick={() => handleAccountContorls(info.code)}
                disabled={!user}
              >
                {info.iconName && (
                  <Image
                    src={getIconHelper(info.iconName)}
                    className={
                      info.iconName === "ICON_ACCOUNT" ? "account-icon" : ""
                    }
                  />
                )}
                <span
                  className={info.iconName === null ? "centered" : ""}
                  style={{
                    marginLeft: info.code === "logout" ? "45px" : "",
                    textAlign:
                      info.code === "cities-plans" ? "left" : "justify",
                    color: info.code === "logout" ? "white" : ""
                  }}
                >
                  {info.title}
                </span>
              </Button>
            )}
          </div>
        );
      })}
      <div className="contact-support">
        <a
          className="contact-support-button"
          target="_blank"
          href="mailto:support@urbanform.us"
          style={contactButton}
          rel="noreferrer"
        >
          <Image src={contact} />
          <span>Contact Support</span>
        </a>
      </div>

      <Confirm
        open={showConfirm}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        cancelButton="No"
        confirmButton="Yes"
        content="Are you sure, you want to cancel the subscription ?"
        size="tiny"
      />
    </div>
  );
};
export default Dashboard;
