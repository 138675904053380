import {
  SAVE_TOKEN,
  SET_ACCOUNT_ACTIVATION_PROCESS,
  SET_ALERT_MESSAGE,
  SET_ALL_PAYMENT_METHOD,
  SET_ALREADY_LOGIN,
  SET_DEFAULT_PAYMENT_METHOD,
  SET_FORGET_PASSWORD_ERROR,
  SET_LOADER,
  SET_NEWS_FEED,
  SET_PLAN_PRICE,
  SET_QUOTA_EXCEED,
  SET_USER_LOGIN_ERROR,
  SET_USER_UPDATE_ERROR,
  USER_HAS_LOGGEDIN,
  USER_HAS_SIGNEDUP,
  USER_HAS_SUBSCRIBED,
  USER_HAS_UPDATED,
  USER_LOG_OUT,
  USER_NO_AUTH
} from "../actions/actions_user";

const initialState = {
  user: {
    subscription_active: true
  },
  token: null,
  alreadyLogin: false,
  loginError: null,
  alertMessage: null,
  loader: false,
  forgetPasswordError: false,
  planPrice: null,
  news: null,
  quotaExceed: false,
  defaultPaymentMethod: null,
  allPaymentMethod: null,
  accountActivationProcess: false
};

const User = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_HAS_SIGNEDUP:
      var newState = JSON.parse(JSON.stringify(state));
      newState.user = payload.user;
      newState.token = payload.token;
      return newState;
    case USER_HAS_LOGGEDIN:
      var newState = JSON.parse(JSON.stringify(state));
      newState.user = payload.user;
      newState.token = payload.token;
      return newState;
    case SAVE_TOKEN:
      var newState = JSON.parse(JSON.stringify(state));
      newState.token = payload;
      return newState;
    case SET_USER_LOGIN_ERROR:
      var newState = JSON.parse(JSON.stringify(state));
      newState.loginError = payload;
      return newState;
    case USER_HAS_SUBSCRIBED:
      var newState = JSON.parse(JSON.stringify(state));
      newState.user = payload;
      return newState;
    case USER_LOG_OUT:

    case USER_NO_AUTH:
      var newState = JSON.parse(JSON.stringify(state));
      newState.user = null;
      newState.token = null;
      return newState;
    case USER_HAS_UPDATED:
      var newState = JSON.parse(JSON.stringify(state));
      newState.user = payload;
      return newState;
    case SET_USER_UPDATE_ERROR:
      var newState = JSON.parse(JSON.stringify(state));
      newState.updateError = payload;
      return newState;
    case SET_ALERT_MESSAGE:
      var newState = JSON.parse(JSON.stringify(state));
      newState.alertMessage = payload;
      return newState;
    case SET_LOADER:
      var newState = JSON.parse(JSON.stringify(state));
      newState.loader = payload;
      return newState;
    case SET_ALREADY_LOGIN:
      var newState = JSON.parse(JSON.stringify(state));
      newState.alreadyLogin = payload;
      return newState;
    case SET_FORGET_PASSWORD_ERROR:
      var newState = JSON.parse(JSON.stringify(state));
      newState.forgetPasswordError = payload;
      return newState;
    case SET_DEFAULT_PAYMENT_METHOD:
      var newState = JSON.parse(JSON.stringify(state));
      newState.defaultPaymentMethod = payload;
      return newState;
    case SET_ALL_PAYMENT_METHOD:
      var newState = JSON.parse(JSON.stringify(state));
      newState.allPaymentMethod = payload;
      return newState;
    case SET_NEWS_FEED:
      const newsState = {
        ...state,
        news: payload
      };
      return newsState;
    case SET_PLAN_PRICE:
      var newState = JSON.parse(JSON.stringify(state));
      newState.planPrice = payload;
      return newState;
    case SET_QUOTA_EXCEED:
      var newState = JSON.parse(JSON.stringify(state));
      newState.quotaExceed = payload;
      return newState;
    case SET_ACCOUNT_ACTIVATION_PROCESS:
      var newState = JSON.parse(JSON.stringify(state));
      newState.accountActivationProcess = payload;
      return newState;

    default:
      return state;
  }
};

export default User;
