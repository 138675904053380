export const pdxConfig: RegionConfig = {
  MAPBOX_STYLE_URL_BASIC: "mapbox://styles/qvt282/clsxvexm0004a01o818l76cja", //multi-region-style
  MAPBOX_STYLE_URL_SATELLITE:
    "mapbox://styles/qvt282/clsxvfe6z003301raf48b8fpw",
  MAPBOX_STYLE_URL_MBENVELOPE:
    "mapbox://styles/qvt282/clsxvf9t0004c01py2myf9doe",
  MAPBOX_LAYER_NAME: "pdxbvn_taxlots",
  REGION: {
    slug: "portland",
    name: "Portland, Oregon"
  },
  JURISDICTIONS: [
    // NB it is a coincidence that the portland jurisdiction has the same name as the portland region
    {
      slug: "portland",
      name: "Portland, Oregon"
    },
    {
      name: "Beaverton, Oregon",
      slug: "beaverton"
    }
  ],
  BBOX: "-124.566244, 41.991794, -116.463504, 46.292035",
  MAP_CENTER: "-122.676,45.530",
  OTHER_REGIONS:
    '{ "portland": [], "seattle": [], "san_diego": [], "austin": [] }',
  PROXIMITY: "-122.675, 45.518",
  MB_TILES_LIST: "taxlots-fill",
  ZOOM_LEVEL: 10.5
};
