import config from "./config";

export const ICONS = [
  { name: "ICON_ACCOUNT", icon: require("./images/quill-write.svg") },
  { name: "ICON_CITIES", icon: require("./images/earth-setting.svg") },
  { name: "ICON_CARD", icon: require("./images/credit-card.svg") },
  { name: "ICON_FILTER", icon: require("./images/filter.png") },
  { name: "ICON_FILTERS", icon: require("./images/folder-empty.svg") },
  { name: "ICON_HELP", icon: require("./images/help.png") },
  { name: "ICON_HOME", icon: require("./images/home.png") },
  { name: "ICON_INFO", icon: require("./images/info.png") },
  { name: "ICON_REPORTS", icon: require("./images/folder-file.svg") },
  { name: "ICON_PROPERTIES", icon: require("./images/folder-star.svg") },
  { name: "ICON_ZONE", icon: require("./images/zoning-1.svg") },

  { name: "ICON_LOT", icon: require("./images/lot-1.svg") },
  { name: "ICON_LAND_STATS", icon: require("./images/filter_land.png") },
  { name: "ICON_DENSITY", icon: require("./images/filter_density.png") },
  { name: "ICON_TOTAL", icon: require("./images/filter_total.png") },
  { name: "ICON_MAX_BUILD_AREA", icon: require("./images/total_max.png") },
  {
    name: "ICON_BUILDABLE_DELTA",
    icon: require("./images/total_buildable.png")
  },
  { name: "ICON_FAR", icon: require("./images/far-1.svg") },
  { name: "ICON_HEIGHT", icon: require("./images/density_height_limit.png") },
  {
    name: "ICON_Residential",
    icon: require("./images/density_residential_unit.png")
  },
  { name: "ICON_LOTSIZE", icon: require("./images/land_lot_size.png") },
  { name: "ICON_LOTTYPE", icon: require("./images/filter_lot_type.png") },
  { name: "ICON_MAXFOOTPRINT", icon: require("./images/info_max.png") },
  { name: "ICON_SUMMARY", icon: require("./images/summary-1.svg") },
  { name: "ICON_IDENTIFIERS", icon: require("./images/identifier-1.svg") },
  { name: "ICON_Land", icon: require("./images/info_land.png") },
  { name: "ICON_SETBACK", icon: require("./images/setbacks-1.svg") },
  { name: "ICON_UNITS", icon: require("./images/building-1.svg") },
  { name: "ICON_MASSING", icon: require("./images/massing-1.svg") },
  { name: "ICON_MAXBUILD", icon: require("./images/max-build-1.svg") },
  { name: "ICON_OTHER", icon: require("./images/other.svg") },
  { name: "ICON_SETBACK_SF", icon: require("./images/info_setback_sf.png") },
  {
    name: "ICON_USE",
    icon: require("./images/real-estate-settings-house.svg")
  },
  { name: "ICON_FAR", icon: require("./images/far-1.svg") },
  { name: "ICON_HEIGHTLIMIT", icon: require("./images/height-1.svg") },
  { name: "ICON_COVERAGE", icon: require("./images/coverage-1.svg") },
  { name: "ICON_PARKING", icon: require("./images/info_parking.png") },
  { name: "ICON_EXISTINGSTRUCTURE", icon: require("./images/existing-1.svg") },
  { name: "ICON_DEVELOPMENT", icon: require("./images/info_development.png") },
  { name: "ICON_OVERLAYS", icon: require("./images/info_overlays.png") },
  { name: "ICON_COVERAGE", icon: require("./images/coverage-1.svg") },
  { name: "ICON_FAR_SPECIAL", icon: require("./images/info_far_special.png") },
  { name: "ICON_RESIDENTIAL", icon: require("./images/info_residential.png") },
  { name: "ICON_COMMERCIAL", icon: require("./images/info_commercial.png") },
  { name: "ICON_INDUSTRIAL", icon: require("./images/info_industrial.png") },
  { name: "ICON_FAR_Minimum", icon: require("./images/info_far_minimum.png") },
  {
    name: "ICON_Height_Bonus",
    icon: require("./images/info_height_bonus.png")
  },
  {
    name: "ICON_RESIDENTIAL_Max",
    icon: require("./images/info_residential_max.png")
  },
  {
    name: "ICON_RESIDENTIAL_Min",
    icon: require("./images/info_residential_min.png")
  },
  {
    name: "ICON_RESIDENTIAL_Max_Bonus",
    icon: require("./images/info_residential_max_bonus.png")
  },
  {
    name: "ICON_DEVELOPMENT_MAX",
    icon: require("./images/info_development_max.png")
  },
  {
    name: "ICON_DEVELOPMENT_FAR",
    icon: require("./images/info_development_far.png")
  },
  {
    name: "ICON_DEVELOPMENT_HT",
    icon: require("./images/info_development_ht.png")
  },
  { name: "ICON-LOCK", icon: require("./images/lock.jpg") },
  { name: "ICON-ADDRESS", icon: require("./images/address.png") },
  { name: "USE", icon: require("./images/Use.png") },

  {
    name: "ICON_jURISDICATION",
    icon: require("./images/help-jurisdication.svg")
  },
  { name: "ICON_ZONING_GUIDES", icon: require("./images/help-zoning.svg") },
  { name: "ICON_NEWS", icon: require("./images/help-news.svg") },
  { name: "ICON_SUPPORT", icon: require("./images/help-contact-us.svg") },

  {
    name: "ICON_FILTER_jURISDICATION",
    icon: require("./images/filter-jurisdication.svg")
  },
  {
    name: "ICON_FILTER_LOCATION",
    icon: require("./images/filter-location.svg")
  }
];

export const UF_TOKEN = "_uft";
export const PRODUCT = config.REGION.slug;
export const BBOX = config.BBOX.split(",").map((b) => Number(b));
export const GEOCODER_CITY = config.REGION.slug;

export const MAP_CENTER = config.MAP_CENTER.split(",").map((b) => Number(b));
export const SIGNUP_FIELDS = [
  {
    type: "text",
    name: "first_name",
    placeHolder: "First name",
    label: "name",
    required: true
  },
  {
    type: "text",
    name: "last_name",
    placeHolder: "Last name",
    label: null,
    required: true
  },
  {
    type: "text",
    name: "company",
    placeHolder: "Company (optional)",
    label: "Organization",
    required: false
  },
  {
    type: "text",
    name: "title",
    placeHolder: "Title (optional)",
    label: null,
    required: false
  },
  {
    type: "select",
    name: "industry",
    placeHolder: "Industry",
    label: "Industry",
    required: true
  },
  {
    type: "text",
    name: "address_1",
    placeHolder: "Address",
    label: "Address",
    required: true
  },
  {
    type: "text",
    name: "address_2",
    placeHolder: "Address 2 (optional)",
    label: null,
    required: false
  },
  {
    type: "text",
    name: "city",
    placeHolder: "City",
    label: null,
    required: true
  },
  {
    type: "text",
    name: "state",
    placeHolder: "State",
    label: null,
    required: true
  },
  {
    type: "text",
    name: "zip_code",
    placeHolder: "Zip code",
    label: null,
    required: true
  },
  {
    type: "tel",
    name: "phone_number",
    placeHolder: "Tel (optional)",
    label: null,
    required: false
  },
  {
    type: "email",
    name: "email",
    placeHolder: "Email",
    label: "Email",
    required: true
  },
  {
    type: "password",
    name: "password",
    placeHolder: "create password",
    label: "Password",
    required: true
  },
  {
    type: "password",
    name: "password_confirm",
    placeHolder: "confirm password",
    label: null,
    required: true
  }
];
export const PLANS = [
  {
    code: "non-subscriber",
    role: "Basic",
    title: "Free",
    subTitle: "Browse zoning summaries",
    titleSummary:
      "See lot-specific zoning summaries for any and all lots within a jurisdiction",
    optionSummary: "See zoning basics:",
    options: [
      "Zoning designations with overlays and other location modifications",
      "FAR",
      "Height Limit",
      "Lot Size"
    ],
    note: "Cost: Free, with option to purchase full reports for single lots at ",
    learnMoreLink: {
      text: "Learn more and compare plans",
      link: ""
    },
    cost: {
      amount: 0,
      header: "No cost",
      content: "With the option to purchase full reports for single lots at",
      subContent: "Upgrade anytime"
    },
    action: "Choose"
  },
  {
    code: "pro",
    role: "Professional",
    title: "Pro",
    subTitle: "Unlimited access to all information",
    titleSummary:
      "See lot-specific zoning summaries for any and all lots within a jurisdiction",
    optionSummary: "See zoning basics:",
    options: [
      "Online access to ALL lot-specific zoning information for any and all lots within a jurisdiction",
      "Access to calculated zoning results, such as Maximum Building Area",
      "Unlimited property zoning report downloads",
      "Save favorite properties"
    ],
    note: "Cost: Free, with option to purchase full reports for single lots at $899 ea.",
    learnMoreLink: {
      text: "Learn more and compare plans",
      link: ""
    },
    cost: {
      amount: 12,
      header: "per month",
      content: "With a 12 month commitment",
      subContent: "For more details, "
    },
    action: "Purchase"
  },
  {
    code: "ent",
    role: "Enterprise",
    title: "Enterprise",
    subTitle: "Unlimited access to all information",
    titleSummary:
      "See lot-specific zoning summaries for any and all lots within a jurisdiction",
    optionSummary: "See zoning basics:",
    options: [
      "Online access to ALL lot-specific zoning information for any and all lots within a jurisdiction",
      "Access to calculated zoning results, such as Maximum Building Area",
      "Use the Form Filter function to search and filter properties that match certain development criteria",
      "Unlimited proeprty zoning report downloads",
      "Save favorite properties",
      "Save Filter criteria"
    ],
    note: "Cost: Free, with option to purchase full reports for single lots at $49 ea.",
    learnMoreLink: {
      text: "Learn more and compare plans",
      link: ""
    },
    cost: {
      amount: 12,
      header: "per year",
      content: "With a 12 month commitment",
      subContent: "For more details, "
    },
    action: "Purchase"
  },
  {
    code: "one_time",
    role: "one_time",
    title: "Download Report",
    subTitle: "",
    titleSummary:
      "See lot-specific zoning summaries for any and all lots within a jurisdiction",
    optionSummary: "See zoning basics:",
    options: [
      "Online access to ALL lot-specific zoning information for any and all lots within a jurisdiction",
      "Access to calculated zoning results, such as Maximum Building Area",
      "Use the Form Filter function to search and filter properties that match certain development criteria",
      "Unlimited proeprty zoning report downloads",
      "Save favorite properties",
      "Save Filter criteria"
    ],
    note: "Cost: Free, with option to purchase full reports for single lots at $49 ea.",
    learnMoreLink: {
      text: "Learn more and compare plans",
      link: ""
    },
    cost: {
      amount: 12,
      header: "per report",
      content:
        "Cost: Free, with option to purchase full reports for single lots at $49 ea.",
      subContent: ""
    },
    action: "OneTime"
  }
];
export const ACCOUNT_INFO = [
  {
    id: 1,
    code: "profile",
    title: "Contact info",
    iconName: "ICON_ACCOUNT"
  },
  {
    id: 2,
    code: "cities-plans",
    title: "cities & plans",
    iconName: "ICON_CITIES"
  },
  {
    id: 3,
    code: "properties",
    title: "Saved properties",
    iconName: "ICON_PROPERTIES"
  },
  {
    id: 4,
    code: "filters",
    title: "Saved filters",
    iconName: "ICON_FILTERS"
  },
  {
    id: 5,
    code: "reports",
    title: "Saved reports",
    iconName: "ICON_REPORTS"
  },
  {
    id: 6,
    code: "payment",
    title: "Payment methods",
    iconName: "ICON_CARD"
  },
  {
    id: 7,
    code: "logout",
    title: "Logout",
    iconName: null
  }
];

export const Filter_INFO = [
  {
    id: 1,
    code: "jurisdiction",
    title: "jurisdiction",
    iconName: "ICON_FILTER_jURISDICATION"
  },
  {
    id: 2,
    code: "zoning",
    title: "Zoning",
    iconName: "ICON_ZONE"
  },
  {
    id: 3,
    code: "location",
    title: "Location",
    iconName: "ICON_FILTER_LOCATION"
  },
  {
    id: 4,
    code: "lot_info",
    title: "Lot info",
    iconName: "ICON_LOT"
  },
  {
    id: 6,
    code: "existing_structures",
    title: "Existing structures",
    iconName: "ICON_EXISTINGSTRUCTURE"
  },
  {
    id: 7,
    code: "use",
    title: "Use",
    iconName: "ICON_USE"
  },
  {
    id: 8,
    code: "farr",
    title: "FAR",
    iconName: "ICON_FAR"
  },
  {
    id: 9,
    code: "height",
    title: "Height",
    iconName: "ICON_HEIGHTLIMIT"
  },
  {
    id: 10,
    code: "coverage",
    title: "coverage",
    iconName: "ICON_COVERAGE"
  },
  {
    id: 11,
    code: "units",
    title: "units",
    iconName: "ICON_UNITS"
  },
  {
    id: 12,
    code: "maxbuild",
    title: "maxbuild",
    iconName: "ICON_MAXBUILD"
  }
];

export const LAND_LOT_SIZE = [
  {
    id: "1",
    title: "Lot Size",
    icon: "ICON_LOTSIZE",
    className: "sub-title",
    name: "lot_size",
    subTitle: "Filter for lots with a total size within a specific range",
    checkboxOn: false,
    column_name: "envp_grnd_lot_area",
    maxValue: 0,
    fields: [
      {
        id: 1,
        label: "Min",
        labelText: "Minimum SF",
        name: "envp_grnd_lot_area_min",
        column_name: "area_sqft",
        className: "min-div",
        subLabel: "square feet",
        value: "",
        action: false,
        leftDefault: true
      },
      {
        id: 2,
        label: "Max",
        labelText: "Maximum SF",
        name: "envp_grnd_lot_area_max",
        column_name: "area_sqft",
        className: "max-div",
        subLabel: "square feet",
        value: "",
        action: false,
        rightDefault: true
      }
    ],
    checkbox: []
  },
  {
    id: 2,
    title: "Lot Type",
    icon: "ICON_LOTTYPE",
    name: "lot_type",
    type: "checkbox",
    action: false,
    className: "sub-title",
    subTitle: "Filter by lot type",
    checkboxOn: true,
    label: "Select all/none",
    column_name: "lot_type",
    checkbox: [
      {
        type: "checkbox",
        default: false,
        label: "Interior",
        name: "interior",
        action: false,
        column_name: "lot_type"
      },
      {
        type: "checkbox",
        default: false,
        label: "Corner",
        name: "corner",
        action: false,
        column_name: "lot_type"
      },
      {
        type: "checkbox",
        default: false,
        label: "Thru",
        name: "thru",
        action: false,
        column_name: "lot_type"
      },
      {
        type: "checkbox",
        default: false,
        label: "Flag",
        name: "flag",
        action: false,
        column_name: "lot_type"
      },
      {
        type: "checkbox",
        default: false,
        label: "Landlocked",
        name: "landlocked",
        action: false,
        column_name: "lot_type"
      }
    ]
  }
];

export const JURIS_FILTER = [
  {
    type: "checkbox",
    label: "Select all/none",
    hide: true,
    // start with no jurisdictions, will be populated
    checkbox: [],
    action: false
  }
];

export const COVERAGE_FILTER = [
  {
    id: 1,
    title: "Coverage Delta",
    subTitle:
      "Filter for lots with an allowable FAR within the following range",
    icon: "ICON_FAR",
    className: "sub-title",
    name: "coverage_delta",
    column_nam: "envp_grnd_ftpt_delta",
    checkboxField: true,
    range: "single",
    maxValue: 0,
    fields: [
      {
        id: 1,
        name: "coverage_delta",
        column_name: "envp_grnd_ftpt_delta",
        label: "Max",
        labelText: "Minimum difference between allowable coverage and existing",
        title: "",
        className: "min-div",
        value: "",
        action: false,
        rightDefault: false
      }
    ],
    checkboxON: false,
    checkbox: [
      {
        type: "checkbox",
        default: false,
        label: "Include contingent bonuses",
        name: "include_contingent_bonuses",
        column_name: "far_bonus_max",
        action: false
      }
    ],
    action: false
  }
];
export const HEIGHT_FILTER = [
  {
    id: 1,
    title: "Height limit",
    megaTitle: "as-of-right(AOR)",
    subTitle:
      "Filter for lots with an allowable FAR within the following range",
    icon: "ICON_FAR",
    className: "sub-title",
    name: "far",
    column_name: "htlim_adjx",
    checkboxField: true,
    maxValue: 0,
    fields: [
      {
        id: 1,
        name: "htlim_adjx_valu_min",
        column_name: "htlim_adjx_valu",
        label: "Min",
        labelText: "Minimum height limit",
        className: "min-div",
        subLabel: "feet",
        value: "",
        action: false,
        leftDefault: true
      },
      {
        id: 2,
        name: "htlim_adjx_valu_max",
        column_name: "htlim_adjx_valu",
        label: "Max",
        labelText: "Maximum height limit",
        className: "max-div",
        subLabel: "feet",
        value: "",
        action: false,
        rightDefault: false
      }
    ],
    checkboxON: false,
    checkbox: [
      {
        type: "checkbox",
        default: false,
        label: "Include contingent bonuses",
        name: "include_contingent_bonuses",
        column_name: "far_bonus_max",
        action: false
      }
    ],
    action: false
  },
  {
    id: 2,
    title: "Height limit",
    megaTitle: "all bonuses taken (ABT)",
    subTitle:
      "Filter for lots with a height limit that is greater than the value set below",
    icon: "ICON_HEIGHT",
    className: "sub-title",
    name: "height_limit",
    column_name: "htlim_bonx",
    checkboxField: true,
    maxValue: 0,
    fields: [
      {
        id: 1,
        name: "htlim_bonx_min",
        column_name: "htlim_bonx_valu",
        label: "Min",
        labelText: "Minimum height limit",
        className: "min-div",
        subLabel: "feet",
        value: "",
        action: false,
        leftDefault: true
      },
      {
        id: 2,
        name: "htlim_bonx_valu_max",
        column_name: "htlim_bonx_valu",
        label: "Max",
        labelText: "Maximum height limit",
        className: "max-div",
        subLabel: "feet",
        value: "",
        action: false,
        rightDefault: false
      }
    ],
    checkboxON: true,
    checkbox: [
      {
        id: 1,
        checkboxON: true,
        label: "Include contingent bonuses",
        name: "contingent_bonuses",
        action: false,
        type: "checkbox",
        column_name: "htlim_bonus_max"
      }
    ],
    action: false
  }
];
export const FAR_FILTER = [
  {
    id: 1,
    title: "FAR",
    megaTitle: "as-of-right(AOr)",
    subTitle:
      "Filter for lots with an allowable FAR within the following range",
    icon: "ICON_FAR",
    className: "sub-title",
    name: "far_aor",
    column_name: "far_lot",
    checkboxField: true,
    maxValue: 0,
    fields: [
      {
        id: 1,
        name: "far_lot_min",
        column_name: "far_adjx_valu",
        label: "Min",
        labelText: "Minimum FAR",
        className: "min-div",
        value: "",
        action: false,
        leftDefault: true
      },
      {
        id: 2,
        name: "far_lot_max",
        column_name: "far_adjx_valu",
        label: "Max",
        labelText: "Maxmum FAR",
        className: "max-div",
        value: "",
        action: false,
        rightDefault: false
      }
    ],
    checkboxON: true,
    checkbox: [
      {
        type: "checkbox",
        checkboxON: true,
        label: "Include contingent bonuses",
        name: "include_contingent_bonuses",
        column_name: "far_bonus_max",
        action: false
      }
    ],
    action: false
  },
  {
    id: 2,
    title: "FAR",
    megaTitle: "all bonuses taken (ABT)",
    subTitle:
      "Filter for lots with a height limit that is greater than the value set below",
    icon: "ICON_HEIGHT",
    className: "sub-title",
    name: "far_abt",
    column_name: "far_bonx",
    checkboxField: true,
    maxValue: 0,
    fields: [
      {
        id: 1,
        name: "far_abt_min",
        column_name: "far_bonx_valu",
        label: "Min",
        labelText: "Minimum FAR",
        className: "min-div",
        value: "",
        action: false,
        leftDefault: true
      },
      {
        id: 2,
        name: "far_abt_max",
        column_name: "far_bonx_valu",
        label: "Max",
        labelText: "Maximum FAR",
        className: "max-div",
        value: "",
        action: false,
        rightDefault: false
      }
    ],
    checkboxON: true,
    checkbox: [
      {
        id: 1,
        checkboxON: true,
        label: "Include contingent bonuses",
        name: "contingent_bonuses",
        action: false,
        type: "checkbox",
        column_name: "htlim_bonus_max"
      }
    ],
    action: false
  }
];
export const UNITS_FILTER = [
  {
    id: 1,
    title: "Allowable units",
    megaTitle: "as-of-right(AOr)",
    subTitle:
      "Filter for lots with a height limit that is greater than the value set below",
    icon: "ICON_HEIGHT",
    className: "sub-title",
    name: "allowable_units_aor",
    column_name: "umax_adjx_valu",
    checkboxField: true,
    range: "single",
    maxValue: 0,
    fields: [
      {
        id: 1,
        name: "allowable_units_aor",
        column_name: "umax_adjx_valu",
        label: "Max",
        labelText: "Allowable units greater than",
        className: "min-div",
        subLabel: "feet",
        value: 0,
        action: false,
        leftDefault: true
      }
    ],
    checkboxON: true,
    checkbox: [
      {
        id: 1,
        checkboxON: true,
        label: "Include contingent bonuses",
        name: "contingent_bonuses",
        action: false,
        type: "checkbox",
        column_name: "htlim_bonus_max"
      }
    ],
    action: false
  },
  {
    id: 2,
    title: "Allowable units",
    megaTitle: "all-bonuses-taken(ABT)",
    subTitle:
      "Filter for lots with a height limit that is greater than the value set below",
    icon: "ICON_HEIGHT",
    className: "sub-title",
    name: "allowable_units_abt",
    column_name: "umax_bonx_valu",
    checkboxField: true,
    range: "single",
    maxValue: 0,
    fields: [
      {
        id: 1,
        name: "allowable_units_abt",
        column_name: "umax_bonx_valu",
        label: "Max",
        labelText: "Allowable units greater than",
        className: "min-div",
        subLabel: "feet",
        value: 0,
        action: false,
        leftDefault: true
      }
    ],
    checkboxON: true,
    checkbox: [
      {
        id: 1,
        checkboxON: true,
        label: "Include contingent bonuses",
        name: "contingent_bonuses",
        action: false,
        type: "checkbox",
        column_name: "htlim_bonus_max"
      }
    ],
    action: false
  }
];

export const EXISTING_STRUCTURE_FILTER = [
  {
    type: "checkbox",
    title: "Existing Building Size",
    name: "existing_square_footage",
    action: false,
    sub_fields: true,
    column_name: "bldgsqft",
    maxValue: 0,
    fields: [
      {
        id: 1,
        label: "Min",
        labelText: "Minimum SF",
        name: "bldgsqft_min",
        column_name: "bldgsqft",
        className: "min-div",
        subLabel: "sq.ft.",
        value: "",
        action: false,
        leftDefault: true
      },
      {
        id: 2,
        label: "Max",
        labelText: "Maximun SF",
        name: "bldgsqft_max",
        column_name: "bldgsqft",
        subLabel: "sq.ft.",
        className: "max-div",
        value: 0,
        action: false,
        rightDefault: true
      }
    ]
  },
  {
    type: "checkbox",
    title: "Year Built",
    name: "between_the_years",
    action: false,
    sub_fields: true,
    column_name: "yearbuilt",
    maxValue: 0,
    fields: [
      {
        id: 1,
        label: "Min",
        labelText: "Built after",
        name: "yearbuilt_min",
        column_name: "yearbuilt",
        className: "min-div",
        minValue: 0,
        value: 2010,
        action: false,
        leftDefault: true
      },
      {
        id: 2,
        label: "Max",
        labelText: "Built before",
        name: "yearbuilt_max",
        column_name: "yearbuilt",
        className: "max-div",
        value: 0,
        action: false,
        rightDefault: true
      }
    ]
  },
  {
    type: "checkbox",
    title: "Total Assessed Value",
    name: "assessed_value_between",
    action: false,
    sub_fields: true,
    column_name: "totalval",
    maxValue: 0,
    fields: [
      {
        id: 1,
        label: "Min",
        labelText: "Minimum value",
        name: "bldgval_min",
        column_name: "totalval",
        subLabel: "$",
        className: "min-div",
        value: 0,
        action: false,
        leftDefault: true
      },
      {
        id: 2,
        label: "Max",
        labelText: "Maximum value",
        name: "bldgval_max",
        column_name: "totalval",
        subLabel: "$",
        className: "max-div",
        value: 0,
        action: false,
        rightDefault: true
      }
    ]
  }
];

export const TOTAL = [
  {
    id: 1,
    icon: "ICON_MAX_BUILD_AREA",
    className: "sub-title",
    title: "Maximum buildable SF,",
    megaTitle: "as-of-right(AOr)",
    name: "maximum_buildable_aor",
    column_name: "envp_bldg_max_area",
    subTitle:
      "Filter for lots with a maximum buildable area in the following range. This calculation assumes above ground building only with an 11 ft floor-- to-floor height",
    checkboxField: true,
    range: "single",
    maxValue: 0,
    fields: [
      {
        id: 1,
        label: "Max",
        labelText: "Buildable SF greater than",
        name: "envp_bldg_max_area_max",
        column_name: "envp_bldg_max_area",
        className: "min-div",
        value: 0,
        action: false,
        leftDefault: true
      }
    ],
    checkboxON: true,
    checkbox: [
      {
        id: 1,
        checkboxON: true,
        label: "Include contingent bonuses",
        name: "residential_use_only",
        type: "checkbox",
        action: false,
        column_name: "envp_bldg_bonus_area"
      }
    ]
  },
  {
    id: 2,
    icon: "ICON_BUILDABLE_DELTA",
    className: "sub-title",
    title: "Maximum buildable SF",
    megaTitle: "all bonuses taken(ABT)",
    column_name: "envp_bldg_bonus_area",
    subTitle:
      "Filter for lots with a difference in maximum buildable area from existing built area within the following range",
    name: "buildable_delta",
    checkboxField: true,
    range: "single",
    maxValue: 0,
    fields: [
      {
        id: 1,
        label: "Max",
        labelText: "Buildable SF greater than",
        name: "envp_bldg_bonus_area_max",
        column_name: "envp_bldg_bonus_area",
        className: "min-div",
        value: 0,
        action: false,
        leftDefault: true
      }
    ],
    checkboxON: true,
    checkbox: [
      {
        id: 1,
        checkboxON: true,
        label: "Include contingent bonuses",
        name: "buildable_delta_checkbox",
        type: "checkbox",
        action: false,
        column_name: "envp_delt_area"
      }
    ]
  },
  {
    id: 3,
    icon: "ICON_MAX_BUILD_AREA",
    className: "sub-title",
    title: "Buildable delta",
    megaTitle: "as-of-right(AOR)",
    name: "buildable_delta_aor",
    column_name: "envp_delt_area",
    subTitle:
      "Filter for lots with a maximum buildable area in the following range. This calculation assumes above ground building only with an 11 ft floor-- to-floor height",
    checkboxField: true,
    range: "single",
    maxValue: 0,
    fields: [
      {
        id: 1,
        label: "Max",
        labelText: "Difference b/w max buildable and existing greater than",
        name: "envp_delt_area_max",
        column_name: "envp_delt_area",
        className: "min-div",
        value: 0,
        action: false,
        leftDefault: true
      }
    ],
    checkboxON: true,
    checkbox: [
      {
        id: 1,
        checkboxON: true,
        label: "Include contingent bonuses",
        name: "residential_use_only",
        type: "checkbox",
        action: false,
        column_name: "envp_bldg_bonus_area"
      }
    ]
  },
  {
    id: 4,
    icon: "ICON_BUILDABLE_DELTA",
    className: "sub-title",
    title: "Buildable delta ",
    megaTitle: "all bonuses taken(ABT)",
    column_name: "envp_delt_bonus",
    subTitle:
      "Filter for lots with a difference in maximum buildable area from existing built area within the following range",
    name: "buildable_delta",
    checkboxField: true,
    range: "single",
    maxValue: 0,
    fields: [
      {
        id: 1,
        label: "Max",
        labelText: "Difference b/w max buildable and existing greater than",
        name: "envp_delt_bonus_max",
        column_name: "envp_delt_bonus",
        className: "min-div",
        value: 0,
        action: false,
        leftDefault: true
      }
    ],
    checkboxON: true,
    checkbox: [
      {
        id: 1,
        checkboxON: true,
        label: "Include contingent bonuses",
        name: "buildable_delta_checkbox",
        type: "checkbox",
        action: false,
        column_name: "envp_delt_area"
      }
    ]
  }
];

export const INFO = [
  {
    id: 1,
    code: "summary",
    title: "Summary",
    iconName: "ICON_SUMMARY"
  },
  {
    id: 2,
    code: "identifiers",
    title: "Identifiers",
    iconName: "ICON_IDENTIFIERS"
  },
  {
    id: 3,
    code: "existing_structure_info",
    title: "Existing structure",
    iconName: "ICON_EXISTINGSTRUCTURE"
  },
  {
    id: 4,
    code: "zone_detail",
    title: "Zoning",
    iconName: "ICON_ZONE"
  },
  {
    id: 5,
    code: "land_detail",
    title: "Lot info",
    iconName: "ICON_LOT"
  },
  {
    id: 6,
    code: "use_regulations",
    title: "Use",
    iconName: "ICON_USE"
  },
  {
    id: 7,
    code: "info_far",
    title: "FAR",
    iconName: "ICON_FAR"
  },
  {
    id: 8,
    code: "height_limit",
    title: "Height",
    iconName: "ICON_HEIGHTLIMIT"
  },

  {
    id: 9,
    code: "info_coverage",
    title: "coverage",
    iconName: "ICON_COVERAGE"
  },
  {
    id: 10,
    code: "setbacks",
    title: "setbacks",
    iconName: "ICON_SETBACK"
  },
  {
    id: 11,
    code: "residential_units",
    title: "units",
    iconName: "ICON_UNITS"
  },
  {
    id: 12,
    code: "massing",
    title: "massing",
    iconName: "ICON_MASSING"
  },
  {
    id: 13,
    code: "other",
    title: "other",
    iconName: "ICON_OTHER"
  },
  {
    id: 14,
    code: "development",
    title: "maxbuild",
    iconName: "ICON_MAXBUILD"
  },
  {
    id: 15,
    code: "downloadBtn",
    title: "Download full report",
    iconName: ""
  }
];
export const FULL_REPORT = {
  title: "Full report",
  subTitle: "What's included?",
  summary: "ALL lot-specific zoning information: ",
  options: [
    "Location detail",
    "Identifiers detail",
    "Maps screenshot",
    "Satellite map screenshot",
    "Zoning detail",
    "FAR detail",
    "Height limit detail",
    "Land detail",
    "Setbacks detail",
    "Use regulations",
    "Unit regulations",
    "Development and buildable areas detail"
  ],
  button: {
    type: "button",
    label: "See Sample Report",
    class: "primary-button"
  }
};

export const REPORT_PURCHASE = {
  title: "$",
  quote: "It's right or it's free",
  detail:
    "Just let us know what was inaccurate and we'll refund the cost of the report. Send us an email to report any issues at",
  email: "support@urbanform.us",
  cost: {
    header: "per report",
    content:
      "Cost: Free, with option to purchase full reports for single lots at $49 ea."
  },
  productName: "One Time",
  price: null,
  button: {
    type: "button",
    label: "Purchase",
    class: "gold-button"
  }
};
export const REPORT_DOWNLOAD = {
  title: "$",
  quote: "It's right or it's free",
  detail:
    "Just let us know what was inaccurate and we'll refund the cost of the report. Send us an email to report any issues at",
  email: "support@urbanform.us",
  button: {
    type: "button",
    label: "Download",
    class: "gold-button"
  }
};

export const QUOTA_EXCEEDS = {
  code: "quota_exceeds",
  id: "quota_exceeds",
  title: "$",
  quote: "",
  detail:
    "You've reached your monthly limit for downloadable reports. You may purchase additional reports for a reduced price of",
  email: "",
  cost: {
    header: "per report",
    content:
      "You've reached your monthly limit for downloadable reports. You may purchase additional reports for a reduced price of $29"
  },
  productName: "Quota Exceeded",
  price: null,
  button: {
    type: "button",
    label: "Purchase",
    class: "gold-button"
  }
};
export const SUMMARY = [
  {
    id: "zone_detail",
    code: "zone_detail",
    icon: "ICON_ZONE",
    className: "sub-title",
    title: "Zone",
    subTitle: "",
    description: "",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 0,
        map_id: 0,
        premacuim_no: "",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading",
        property_class: "zone_a",
        fontClass: "value-01",
        fontClass3: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 1,
        small_heading: "Zone Name:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Description:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_c",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_c",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 5,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 9,
        source: "More zoning details...",
        link: ""
      }
    ]
  },

  {
    id: "lot_size",
    icon: "ICON_LOTSIZE",
    className: "sub-title",
    title: "Lot size",
    subTitle: "",
    description: "",
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Lot size in square feet:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_a",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Lot size in acres:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_b",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Lot width:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_c",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Lot depth:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_d",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Length of street frontage: ",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_e",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      }
    ]
  },

  {
    id: "base_far",
    code: "info_far",
    icon: "ICON_FAR",
    className: "sub-title",
    title: "Base FAR",
    subTitle:
      "Floor-area-ratio available with location specific adjustments but without contingent bonuses",
    description: "",
    footerText: true,
    show: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Ratio:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 4,
        small_heading:
          "Is there an FAR modification on this site due to some location specifics?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Are FAR bonuses available?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 7,
        source: "More FAR details...",
        link: "",
        display: true
      }
    ]
  },

  {
    id: "height_limit_base",
    code: "height_limit",
    icon: "ICON_HEIGHTLIMIT",
    className: "sub-title",
    title: "Height Limit",
    subTitle:
      "Height limit  with location adjustments but without contingent bonuses",
    description: "",
    show: true,
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Ratio:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_adjusted_b",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_c",
        fontClass: "value-title",
        fontClass3: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 4,
        small_heading:
          "Is there a Height Limit modification on this site due to some location specifics?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_d",
        fontClass: "value-title",
        fontClass3: "value-03",
        hr: true,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Are Height bonuses available?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_e",
        fontClass: "value-title",
        fontClass3: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 7,
        source: "More Height details...",
        link: "",
        display: true
      }
    ]
  },

  {
    id: "max_buildable_square_footage",
    icon: "ICON_DEVELOPMENT_MAX",
    className: "sub-title",
    title: "Max building potential",
    subTitle: "",
    description: "",
    footerText: false,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "AOR (As-of-Right):",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "without_bonuses_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "development_comment_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 3,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_text: "UrbanForm\n" + "calculation",
        link: "https://urbanform.us/faq",
        display: true
      },
      {
        id: 4,
        small_heading: "",
        small_heading_class: "small-heading-class",
        customStyle: { marginBottom: "-10px" },
        onlyLine: true,
        sourceHr: true,
        display: true
      },
      {
        id: 5,
        small_heading: "ABT (All Bonuses Taken):",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "without_bonuses_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "development_comment_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 7,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_text: "UrbanForm\n" + "calculation",
        link: "https://urbanform.us/faq",
        display: true
      }
    ]
  },
  {
    id: "building_delta",
    icon: "ICON_BUILDABLE_DELTA",
    className: "sub-title",
    title: "Buildable square footage by FAR",
    subTitle:
      "The differential between maximum building potential and existing,",
    description: "",
    footerText: false,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading:
          "The difference between As-of-right (AOR) building potential and existing:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "without_bonuses_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 2,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_text: "UrbanForm\n" + "calculation",
        link: "https://urbanform.us/faq",
        display: true
      },
      {
        id: 7,
        small_heading: "",
        small_heading_class: "small-heading-class",
        customStyle: { marginBottom: "-10px" },
        onlyLine: true,
        sourceHr: true,
        display: true
      },

      {
        id: 3,
        small_heading:
          "The difference between all bonuses taken (ABT) building potential and existing:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "without_bonuses_a",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_text: "UrbanForm\n" + "calculation",
        link: "https://urbanform.us/faq",
        display: true
      },
      {
        id: 7,
        small_heading: "",
        small_heading_class: "small-heading-class",
        customStyle: { marginBottom: "-10px" },
        onlyLine: true,
        sourceHr: true,
        display: true
      },
      {
        id: 5,
        small_heading:
          "The difference between MaxBuild Footprint and existing coverage:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "without_bonuses_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_text: "UrbanForm\n" + "calculation",
        link: "https://urbanform.us/faq",
        display: true
      }
    ]
  }
];

export const IDENTIFIERS = [
  {
    id: "address",
    icon: "ICON-ADDRESS",
    className: "sub-title",
    title: "Address",
    subTitle: "",
    description: "",
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Street address:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "address_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: false
      },
      {
        id: 2,
        small_heading: "City, State:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "address_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: false
      },
      {
        id: 3,
        small_heading: "Zip:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        title_zip_code: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "address_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: false
      }
    ]
  },

  {
    id: "jurisdication",
    icon: "ICON-ADDRESS",
    className: "sub-title",
    title: "Jurisdication",
    subTitle: "",
    description: "",
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Jurisdication:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "address_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: false
      },
      {
        id: 2,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "address_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 3,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "address_3",
        fontClass: "value-03",
        source_p: "",
        hr: false,
        ul: false,
        display: true,
        applyItalic: false
      }
    ]
  },

  {
    id: "identifiers",
    icon: "ICON_IDENTIFIERS",
    className: "sub-title",
    title: "Identifiers",
    subTitle: "",
    description: "",
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "identifiers_a",
        fontClass: "value-title",
        fontClass3: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "identifiers_b",
        fontClass: "value-title",
        fontClass3: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "identifiers_c",
        fontClass: "value-title",
        fontClass3: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        title: "",
        title_desc_inline: "",
        title_desc: " ",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "identifiers_d",
        fontClass: "value-title",
        fontClass3: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "address_3",
        fontClass: "value-03",
        source_p: "",
        hr: false,
        ul: false,
        display: true
      }
    ]
  },
  {
    id: "assessor",
    icon: "ICON-ADDRESS",
    className: "sub-title",
    title: "Assessor",
    subTitle: "",
    description: "",
    lock: false,
    properties: [
      {
        id: 1,
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading value-03",
        subClassName: "sub-heading-2 value-03",
        property_class: "address_a",
        fontClass: "value-02",
        fontClass3: "value-03",
        link: "",
        source_p: "",
        hr: false,
        ul: false,
        display: true
      }
    ]
  }
];

export const ZONE_DETAIL = [
  {
    id: "zone_detail",
    icon: "ICON_ZONE",
    className: "sub-title",
    title: "Zone",
    subTitle: "",
    description: "",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Zone Name:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Description:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_c",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_c",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },
  {
    id: "location-specification",
    icon: "ICON_ZONE",
    className: "sub-title",
    title: "Zone",
    subTitle: "",
    description: "",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_b",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 3,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 4,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc02
      {
        id: 5,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 6,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_d",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 7,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 8,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc03

      {
        id: 9,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_e",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_f",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 11,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 12,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc04
      {
        id: 13,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_g",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 14,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_h",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 15,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 16,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      //lc05
      {
        id: 17,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_i",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 18,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_j",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 19,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 20,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc06
      {
        id: 21,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_k",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 22,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_l",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 23,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 24,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc07
      {
        id: 25,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_m",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 26,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_n",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 27,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 28,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc08
      {
        id: 29,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_o",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 30,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_p",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 31,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 32,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc09
      {
        id: 33,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_q",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 34,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_r",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 35,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },

      {
        id: 36,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc10
      {
        id: 37,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_s",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 38,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_t",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 39,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },

      {
        id: 40,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc11
      {
        id: 41,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_u",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 42,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_v",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 43,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },

      {
        id: 44,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc12

      {
        id: 45,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_w",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 46,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_x",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 47,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 48,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc13

      {
        id: 49,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_y",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 50,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_z",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 51,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 52,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc14

      {
        id: 53,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_aa",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 54,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ab",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 55,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },

      {
        id: 56,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc15

      {
        id: 57,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ac",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 58,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ad",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 59,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 60,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc16

      {
        id: 61,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ae",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 62,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_af",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 63,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 64,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc17

      {
        id: 65,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ag",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 66,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ah",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 67,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },

      {
        id: 68,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc18

      {
        id: 69,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ai",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 70,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_aj",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 71,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },

      {
        id: 72,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc19

      {
        id: 73,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ak",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 74,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_al",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 75,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 76,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc20

      {
        id: 77,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_am",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 78,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_an",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 79,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 80,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc21

      {
        id: 81,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ao7",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 82,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ap",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 83,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },

      {
        id: 84,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc22

      {
        id: 85,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_aq",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 86,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ar",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 87,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 88,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc23
      {
        id: 89,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_as",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 90,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_at",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 91,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 92,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc24
      {
        id: 93,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_au",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 94,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_av",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 95,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },

      {
        id: 96,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      //lc25
      {
        id: 97,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_aw",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 98,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ax",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 99,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 100,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc26
      {
        id: 101,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_ay",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 102,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_az",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 103,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 104,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc27
      {
        id: 105,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 106,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 107,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 108,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc28
      {
        id: 109,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 110,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 111,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 112,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc29
      {
        id: 113,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 114,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 115,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 116,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc30
      {
        id: 117,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 118,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 119,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 120,
        small_heading: "",
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //lc30
      {
        id: 121,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 122,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "zone_detail_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 123,
        small_heading: "More Info:",
        small_heading_class: "small-heading-class value-title link",
        source_p: "",
        link: "",
        sourceHr: true,
        onlyLine: true,
        display: true
      }
    ]
  }
];
export const LAND_DETAIL = [
  {
    id: "lot_size",
    icon: "ICON_LOTSIZE",
    className: "sub-title",
    title: "Lot size",
    subTitle: "",
    description: "",
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Lot size in square feet:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_a",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Lot size in acres:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_b",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Lot width:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_c",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Lot depth:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_d",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Length of street frontage: ",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_e",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      }
    ]
  },
  {
    id: "lot_type",
    className: "sub-title",
    title: "Lot type",
    subTitle: "",
    description: "",
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Lot type:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Alley adjacent:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      }
    ]
  }
];

export const SETBACKS = [
  {
    id: "setback",
    icon: "ICON_SETBACK",
    className: "sub-title",
    title: "Setbacks",
    subTitle: "Required distances from property lines",
    description: "Required distances from property lines",
    footerText: true,
    lock: false,
    show: true,
    properties: [
      {
        id: 1,
        small_heading: "No setbacks required for this lot",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      {
        id: 2,
        small_heading: "Setback rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Setback distance:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 5,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: true,
        display: true
      },

      {
        id: 6,
        small_heading: "Setback rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 7,
        small_heading: "Setback distance:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 8,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },

      {
        id: 9,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 10,
        small_heading: "Setback rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 11,
        small_heading: "Setback distance:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 12,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 13,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 14,
        small_heading: "Setback rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 15,
        small_heading: "Setback distance:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 16,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 17,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 18,
        small_heading: "Setback rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 19,
        small_heading: "Setback distance:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 20,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 21,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      //6
      {
        id: 22,
        small_heading: "Setback rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 23,
        small_heading: "Setback distance:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 24,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 25,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 26,
        small_heading: "Setback rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 27,
        small_heading: "Setback distance:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 28,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 29,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 30,
        small_heading: "Other setbacks comments:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      }
    ]
  },
  {
    id: "max_build_footpront",
    className: "sub-title",
    title: "MaxBuild Footprint",
    description: "",
    footerText: true,
    show: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "MaxBuild Footprint:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Existing coverage:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Difference:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      }
    ]
  }
];
export const OTHER = [
  {
    id: "other",
    icon: "ICON_SETBACK",
    className: "sub-title",
    title: "Other",
    subTitle: "Required distances from property lines",
    description: "Required distances from property lines",
    footerText: true,
    lock: false,
    show: true,
    properties: [
      {
        id: 1,
        small_heading: "No other regulations to display",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      {
        id: 2,
        small_heading: "Regulation:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 7,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: true,
        display: true
      },

      {
        id: 8,
        small_heading: "Regulation:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 9,
        small_heading: "Value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 11,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 12,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 13,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: true,
        display: true
      },
      {
        id: 14,
        small_heading: "Regulation:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 15,
        small_heading: "Value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 16,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 17,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 18,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 19,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: true,
        display: true
      },
      {
        id: 20,
        small_heading: "Regulation:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 21,
        small_heading: "Value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 22,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 23,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 24,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 25,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: true,
        display: true
      },
      {
        id: 26,
        small_heading: "Regulation:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 27,
        small_heading: "Value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 28,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 29,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 30,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 31,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: true,
        display: true
      },
      //6
      {
        id: 32,
        small_heading: "Regulation:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 33,
        small_heading: "Value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 34,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 35,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 36,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 37,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: true,
        display: true
      },
      {
        id: 38,
        small_heading: "Regulation:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 39,
        small_heading: "Value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 40,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 41,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 42,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 43,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: true,
        display: true
      },

      {
        id: 44,
        small_heading: "Regulation:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 45,
        small_heading: "Value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 46,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 47,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 48,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 49,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: true,
        display: true
      },

      {
        id: 50,
        small_heading: "Regulation:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 51,
        small_heading: "Value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 52,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 53,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 54,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 55,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: true,
        display: true
      }
    ]
  }
];
export const MAX_FOOT_PRINT = [
  {
    id: "max_foot_print",
    icon: "ICON_MAXFOOTPRINT",
    className: "sub-title",
    title: "Max footprint",
    subTitle:
      "The maximum building area for this lot:the lesser of the allowable coverage and min setback min lot size",
    description: "",
    footerText: false,
    lock: false,
    properties: [
      {
        id: 1,
        title: "123,456,789",
        title_desc_inline: "",
        title_desc: "square feet",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "max_foot_print_a",
        hr: false,
        ul: false,
        display: true
      }
    ]
  },
  {
    id: "coverage_max_footprint",
    icon: "ICON_COVERAGE",
    className: "sub-title",
    title: "Coverage",
    subTitle: "The maximum allowable building area on  a lot",
    description: "The maximum allowable building area on  a lot",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        title: "50%",
        title_desc_inline: "",
        title_desc: "or",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "coverage_max_footprint_a",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        title: "123,456,789",
        title_desc_inline: "",
        title_desc: "square feet",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "coverage_max_footprint_b",
        hr: true,
        ul: false,
        display: true
      },
      {
        id: 3,
        title: "",
        title_desc_inline: "",
        title_desc:
          "Coverage limits for this lot are expressed as a formula, the results of which are shown here",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "coverage_max_footprint_c",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        source: "Source:",
        link: "",
        display: true
      }
    ]
  },
  {
    id: "setbacks_lot_size",
    icon: "ICON_SETBACK_SF",
    className: "sub-title",
    title: "Setback lot size",
    subTitle: "Size of the lot minus areas restricted by setbacks",
    description: "",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        title: "200,000,000",
        title_desc_inline: "",
        title_desc: "square feet",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "setbacks_lot_size_a",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        source: "More detail",
        link: "",
        display: true
      }
    ]
  }
];

export const USE_REGULATIONS = [
  {
    id: "checkbox",
    title: "USE",
    icon: "ICON_USE",
    hidden: false,
    properties: [
      {
        id: 1,
        type: "radio",
        title: "View by category",
        checked: true,
        name: "category",
        display: true
      },
      {
        id: 2,
        type: "radio",
        title: "View by allowance",
        checked: false,
        name: "allowance",
        display: true
      }
    ]
  },
  {
    id: "residential",
    icon: "ICON_RESIDENTIAL",
    className: "sub-title",
    title: "",
    subTitle: "— = Not Permitted CL = Conditional/Limited P = Permitted Use",
    description: "",
    footerText: true,
    lock: false,
    display: true,
    hidden: false,
    properties: [
      {
        id: 1,
        title: "Single-family dwelling",
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "residential_a",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        title:
          "Apartments, carriage houses, cottage houses, rowhouses, and townhouses",
        title_desc_inline: "CL",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "residential_b",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        title: "Floating homes",
        title_desc_inline: "CL",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "residential_c",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        title: "Congregate residences",
        title_desc_inline: "CL",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "residential_d",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        title: "Detached ADU",
        title_desc_inline: "CL",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "residential_e",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        title: "Adult family homes",
        title_desc_inline: "CL",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "residential_f",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 7,
        title: "Permanent supportive housing",
        title_desc_inline: "CL",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "residential_g",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 8,
        title: "Caretaker's quarters",
        title_desc_inline: "CL",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "residential_h",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 9,
        title: "Mobile home parks",
        title_desc_inline: "CL",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "residential_i",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        title: "Artists studio dwellings",
        title_desc_inline: "CL",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "residential_j",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 11,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },

  {
    id: "commercial",
    icon: "ICON_COMMERCIAL",
    className: "sub-title",
    title: "",
    subTitle: "— = Not Permitted CL = Conditional/Limited P = Permitted Use",
    description: "",
    footerText: true,
    lock: false,
    hidden: false,
    properties: [
      {
        id: 1,
        title: "Business support services",
        title_desc_inline: "CL",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_a",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        title: "Food processing and craft work",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_b",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        title: "Bed and breakfast",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_c",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        title: "Nursing homes",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "commercial_d",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        title: "Drive-in businesses",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_e",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        title: "Ground-floor commercial uses",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_f",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 7,
        title:
          "Medical service uses other than permitted ground-floor commercial",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_g",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 8,
        title: "Uses not otherwise permitted in Landmark structures",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_h",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 9,
        title: "Cemeteries",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_i",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        title: "Animal shelters and kennels",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_j",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 11,
        title: "Drinking establishments",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_k",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 12,
        title: "Restaurants",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_l",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 13,
        title: "Cabarets, adult",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_m",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 14,
        title: "Motion picture theaters, adult",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_n",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 15,
        title: "Panorams, adult",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_o",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 16,
        title: "Sports and recreation, indoor",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_p",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 17,
        title: "Sports and recreation, outdoor",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_q",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 18,
        title: "Theaters and spectator sports facilities",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_r",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 19,
        title: "Food processing and craft work",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_s",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 20,
        title: "Laboratories, research and development",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_t",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 21,
        title: "Lodging uses",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_u",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 22,
        title: "Medical services",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_v",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 23,
        title: "Offices",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_w",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 24,
        title: "Retail sales and services, automotive",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_x",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 25,
        title: "Sales and rental of motorized vehicles",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_y",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 26,
        title: "Vehicle repair, major automotive",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_z",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 27,
        title: "Retail sales and services, general",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_aa",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 28,
        title: "Retail sales, multipurpose",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ab",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 29,
        title: "Commercial sales, heavy",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ac",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 30,
        title: "Commercial services, heavy",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ad",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 31,
        title: "Retail sales, major durables",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ae",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 32,
        title: "Retail sales and services, non-household",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_af",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 33,
        title: "Wholesale showrooms",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ag",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 34,
        title: "Marine service stations",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ah",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 35,
        title: "Sales and rental of large boats",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ai",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 36,
        title: "Sales and rental of small boats, boat parts and accessories",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_aj",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 37,
        title: "Vessel repair, major",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ak",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 38,
        title: "Vessel repair, minor",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_al",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 39,
        title: "Live-work units",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_am",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 40,
        title: "Major marijuana activity",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_an",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 41,
        title: "Retail ice dispensaries",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ao",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 42,
        title: "Frozen food lockers",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ap",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 43,
        title: "Pet daycare",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_aq",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 44,
        title: "Bowling alleys",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ar",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 45,
        title: "Skating rinks",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_as",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 46,
        title: "Medical testing laboratories",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_at",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 47,
        title: "Mortuary services",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_au",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 48,
        title: "Motels",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_av",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 49,
        title: "Shooting galleries",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_aw",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 50,
        title: "Formula fast food restaurants",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ax",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 51,
        title: "Hotels",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "commercial_ay",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 52,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },
  {
    id: "industrial",
    icon: "ICON_INDUSTRIAL",
    className: "sub-title",
    title: "",
    subTitle: "— = Not Permitted CL = Conditional/Limited P = Permitted Use",
    description: "",
    footerText: true,
    lock: false,
    hidden: false,
    properties: [
      {
        id: 1,
        title: "Childcare centers",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_a",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        title: "Schools, elementary or secondary",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        fontClass: "value-03",
        mobileCheck: true,
        property_class: "industrial_b",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        title: "Religious facilities",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_c",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        title: "Major institutions subject to 23.69",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_d",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        title: "Other institutions",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_e",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        title: "Institutions meeting base zone development standards",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_g",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 7,
        title: "Institutions",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_h",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 8,
        title: "Public schools",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_i",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 9,
        title:
          "Child care centers, preschools, public or private schools, educational and vocational training . . . and similar uses in existing or former public schools",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_d",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        title: "Community centers",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_e",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 11,
        title: "Private schools",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_g",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 12,
        title: "Public schools",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_h",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 13,
        title: "Libraries",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_i",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 14,
        title: "Existing institutes for advanced study",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_e",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 15,
        title: "Hospitals",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_g",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 16,
        title: "Colleges",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_h",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 17,
        title: "Museums",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_i",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 18,
        title: "Private clubs",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_d",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 19,
        title: "Vocational schools",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_g",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 20,
        title: "Adult care centers",
        title_desc_inline: "P",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_h",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 21,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },
  {
    id: "agriculture",
    icon: "ICON_INDUSTRIAL",
    className: "sub-title",
    title: "",
    subTitle: "— = Not Permitted CL = Conditional/Limited P = Permitted Use",
    description: "",
    footerText: true,
    lock: false,
    hidden: false,
    properties: [
      {
        id: 1,
        title: "Parks and playgrounds including customary uses",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_a",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        title: "Community gardens",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_b",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        title: "Animal husbandry",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_c",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        title: "Aquaculture",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_d",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        title: "Horticulture",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        mobileCheck: true,
        subClassName: "sub-heading-2",
        property_class: "industrial_e",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        title: "Urban farm",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_f",
        fontClass: "value-03",
        mobileCheck: true,
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 7,
        title: "Parks and open space",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_g",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 8,
        title: "Plant nurseries",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_h",
        fontClass: "value-03",
        mobileCheck: true,
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 9,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },

  {
    id: "transportation",
    icon: "ICON_INDUSTRIAL",
    className: "sub-title",
    title: "",
    subTitle: "— = Not Permitted CL = Conditional/Limited P = Permitted Use",
    description: "",
    footerText: true,
    lock: false,
    hidden: false,
    properties: [
      {
        id: 1,
        title: "Cargo terminals",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_a",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        title: "Boat moorage",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_b",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        title: "Dry boat storage",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_c",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        title: "Parking, flexible-use",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_d",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        title: "Park and ride facilities",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_e",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        title: "Park and pool lots",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_f",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 7,
        title: "Towing services",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_g",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 8,
        title: "Passenger terminals",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_h",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 9,
        title: "Rail transit facilities",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_i",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        title: "Airports (land-based)",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_j",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 11,
        title: "Airports (water-based)",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_k",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 12,
        title: "Heliports",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_l",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 13,
        title: "Helistops",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_m",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 14,
        title: "Bus bases",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_n",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 15,
        title: "Railroad switchyards",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_o",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 16,
        title: "Railroad switchyards with a mechanized hump",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_p",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 17,
        title: "Transportation services, personal",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_q",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 18,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },

  {
    id: "utilities",
    icon: "ICON_INDUSTRIAL",
    className: "sub-title",
    title: "",
    subTitle: "— = Not Permitted CL = Conditional/Limited P = Permitted Use",
    description: "",
    footerText: true,
    lock: false,
    hidden: false,
    properties: [
      {
        id: 1,
        title: "Communication utilities, major",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_a",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        title: "Communication utilities, minor",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_b",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        title: "Power plants",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_c",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        title: "Recycling",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_d",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        title: "Sewage treatment plants",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_e",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        title: "Solid waste management",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_f",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 7,
        title: "Utility services uses",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_g",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 8,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },

  {
    id: "manufacturing",
    icon: "ICON_INDUSTRIAL",
    className: "sub-title",
    title: "",
    subTitle: "— = Not Permitted CL = Conditional/Limited P = Permitted Use",
    description: "",
    footerText: true,
    lock: false,
    hidden: false,
    properties: [
      {
        id: 1,
        title: "Manufacturing, light",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_a",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        title: "Manufacturing, general",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_b",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        title: "Manufacturing, heavy",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_c",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        title: "High impact",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_d",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },

  {
    id: "public",
    icon: "ICON_INDUSTRIAL",
    className: "sub-title",
    title: "",
    subTitle: "— = Not Permitted CL = Conditional/Limited P = Permitted Use",
    description: "",
    footerText: true,
    lock: false,
    hidden: false,
    properties: [
      {
        id: 1,
        title: "Youth service centers",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_a",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        title: "All other jails",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_b",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        title: "Work-release centers",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_c",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },

  {
    id: "storage",
    icon: "ICON_INDUSTRIAL",
    className: "sub-title",
    title: "",
    subTitle: "— = Not Permitted CL = Conditional/Limited P = Permitted Use",
    description: "",
    footerText: true,
    lock: false,
    hidden: false,
    properties: [
      {
        id: 1,
        title: "Mini-warehouses",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_a",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        title: "Storage, outdoor",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_b",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        title: "Warehouses",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        mobileCheck: true,
        property_class: "industrial_c",
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },

  {
    id: "misc",
    icon: "ICON_INDUSTRIAL",
    className: "sub-title",
    title: "",
    subTitle: "— = Not Permitted CL = Conditional/Limited P = Permitted Use",
    description: "",
    footerText: true,
    lock: false,
    hidden: false,
    properties: [
      {
        id: 1,
        title: "All other uses",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "industrial_a",
        mobileCheck: true,
        fontClass: "value-03",
        fontClassInline: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },

  {
    id: "not_permitted",
    iconText: "A",
    title: "Not Permitted",
    className: "sub-title",
    title: "",
    lock: false,
    type: "category",
    properties: []
  },
  {
    id: "conditional",
    iconText: "CL",
    className: "sub-title",
    title: "",
    lock: false,
    type: "category",
    properties: []
  },

  {
    id: "Permitted Use",
    iconText: "P",
    className: "sub-title",
    title: "",
    lock: false,
    type: "category",
    properties: []
  }
];

export const FAR = [
  {
    id: "info_far",
    icon: "ICON_FAR",
    className: "sub-title",
    title: "Base FAR",
    subTitle:
      "Floor-area-ratio available with location specific adjustments but without contingent bonuses",
    description: "",
    footerText: true,
    show: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Ratio:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },
  {
    id: "far_allowable",
    icon: "ICON_FAR",
    className: "sub-title",
    title: "Allowable FAR",
    subTitle:
      "Floor-area-ratio available with location specific adjustments but without contingent bonuses",
    description: "",
    footerText: true,
    show: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Ratio:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_c",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 5,
        small_heading: "Buildable area by FAR:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "title-desc",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_d",
        fontClass: "value-02",
        fontClass3: "value-text",
        hr: false,
        ul: false,
        display: true
      }
    ]
  },
  {
    id: "far_Restriction",
    icon: "ICON_FAR_SPECIAL",
    className: "sub-title",
    title: "FAR Restriction",
    subTitle: "Bonus FAR available based on development options",
    description: "",
    show: true,
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "No FAR restriction exist for this site",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      {
        id: 2,
        small_heading:
          "Any FAR restrictions that may apply to this site. See below for more details.",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },

      {
        id: 3,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Ratio:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_b",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 6,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_d",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 7,
        className: "",
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 8,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 9,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_e",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        small_heading: "Ratio:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_f",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 11,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_g",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 12,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_h",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 13,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 14,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 15,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      }
    ]
  },
  {
    id: "far_bonus",
    icon: "ICON_FAR_SPECIAL",
    className: "sub-title",
    title: "FAR Bonuses",
    subTitle: "Bonus FAR available based on development options",
    description: "",
    show: true,
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: " ",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      //
      {
        id: 2,
        small_heading: "Are FAR bonuses available for this lot?:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      {
        id: 3,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Bonus condition:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Bonus Ratio:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 7,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 8,
        className: "far_bonus_c_hr",
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 9,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 10,
        small_heading: "Bonus condition:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_d",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 11,
        small_heading: "Bonus Ratio:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_e",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 12,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 13,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 14,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 15,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 16,
        small_heading: "Bonus condition:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_f",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 17,
        small_heading: "Bonus Ratio:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_g",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 18,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 19,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 20,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 21,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 22,
        small_heading: "Bonus condition:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_h",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 23,
        small_heading: "Bonus Ratio:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_i",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 24,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 25,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 26,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 27,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 28,
        small_heading: "Bonus condition:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_j",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 29,
        small_heading: "Bonus Ratio:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_k",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 30,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 31,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 32,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: true,
        display: true
      },
      {
        id: 33,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      }
    ]
  },
  {
    id: "max_far_bonus",
    icon: "ICON_FAR_SPECIAL",
    className: "sub-title",
    title: "Max Bonus FAR",
    subTitle: "",
    description: "",
    show: true,
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "No Data to show",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_j",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      {
        id: 2,
        small_heading: "Max bonus condition:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_k",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Ratio:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_k",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_k",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 5,
        small_heading: "Applies to this lot:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_k",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        sourceHr: false,
        display: true
      },
      {
        id: 7,
        small_heading: "Resulting buildable area by Max Bonus FAR: ",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "title-desc",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_l",
        fontClass: "value-02",
        hr: false,
        display: true,
        ul: false
      }
    ]
  }
];
export const HEIGHT_LIMIT = [
  {
    id: "height_limit",
    icon: "ICON_HEIGHTLIMIT",
    className: "sub-title",
    title: "Height Limit",
    subTitle:
      "Height limit  with location adjustments but without contingent bonuses",
    description: "",
    show: true,
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Ratio:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Comment:",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_b",
        fontClass: "value-title",
        fontClass3: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },
  {
    id: "allowable_height",
    icon: "ICON_HEIGHTLIMIT",
    className: "sub-title",
    title: "Allowable Height",
    subTitle: "",
    show: true,
    description: "",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_adjustment_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Limit:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_adjustment_b",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_adjusted_c",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Resulting Buildable Area:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "title-desc",
        subClassName: "sub-heading-2",
        property_class: "height_limit_adjusted_d",
        fontClass: "value-02",
        fontClass3: "value-text",
        hr: false,
        ul: false,
        display: true
      }
    ]
  },
  {
    id: "height_restriction",
    icon: "ICON_HEIGHTLIMIT",
    className: "sub-title",
    title: "Height Restriction",
    subTitle: "",
    show: true,
    description: "",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: " ",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_adjustment_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      {
        id: 2,
        small_heading:
          "Any Height restrictions that may apply to this site. See below for more details.",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_bonus_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      //1
      {
        id: 3,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_restriction_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Limit:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_restriction_b",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_restriction_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 6,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_restriction_d",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 7,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 8,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },

      //2
      {
        id: 9,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_restriction_e",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        small_heading: "Limit:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_restriction_f",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 11,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_restriction_g",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 12,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_restriction_h",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 13,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 14,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 15,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_adjustment_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        indent: true
      }
    ]
  },
  {
    id: "height_bonuses",
    icon: "ICON_HEIGHTLIMIT",
    className: "sub-title",
    show: true,
    title: "Height Bonuses",
    subTitle: "",
    description: "",
    properties: [
      {
        id: 1,
        small_heading: " ",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      {
        id: 2,
        small_heading: "Are Height bonuses available for this lot?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Limit:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_b",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 7,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_d",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 8,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 9,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      //2
      {
        id: 10,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_e",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 11,
        small_heading: "Bonus Height Limit:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_f",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 12,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_g",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 13,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_h",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 14,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 15,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      //3
      {
        id: 16,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_i",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 17,
        small_heading: "Limit:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_j",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 18,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_k",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 19,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_l",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 20,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 21,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      //4
      {
        id: 22,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_m",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 23,
        small_heading: "Limit:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_n",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 24,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_o",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 25,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_p",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 26,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 27,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      //5
      {
        id: 28,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_q",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 29,
        small_heading: "Bonus Height Limit:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_r",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 30,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_s",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 31,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_t",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 32,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      }
    ]
  },
  //
  {
    id: "max_height_bonuses",
    icon: "ICON_HEIGHTLIMIT",
    className: "sub-title",
    title: "Max Height",
    subTitle: "",
    show: true,
    display: true,
    description: "",
    properties: [
      {
        id: 1,
        small_heading: "No Data to show",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_l",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },

      {
        id: 2,
        small_heading: "Max Bonus conditions: ",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_m",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Limit:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_k",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_k",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 5,
        small_heading: "Applies to this lot:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_k",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        sourceHr: false,
        display: true
      },
      {
        id: 7,
        small_heading: "Resulting buildable area by Max Bonus Height:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "height_limit_bonus_k",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      }
    ]
  }
];

export const RESIDENTIAL_UNITS = [
  {
    id: "unit_minimum",
    icon: "ICON_RESIDENTIAL_Min",
    className: "sub-title",
    title: "Unit minimum",
    subTitle: "",
    description: "",
    footerText: true,
    lock: false,
    show: true,
    properties: [
      {
        id: 1,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_minimum_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Units:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_minimum_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Comment:",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_minimum_c",
        fontClass: "value-title",
        fontClass3: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      }
    ]
  },

  {
    id: "Unit_maximum",
    icon: "ICON_RESIDENTIAL_Min",
    className: "sub-title",
    title: "Unit minimum",
    subTitle: "",
    description: "",
    footerText: true,
    lock: false,
    show: true,
    properties: [
      {
        id: 1,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_minimum_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Units:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_minimum_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Comment:",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_minimum_c",
        fontClass: "value-title",
        fontClass3: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      }
    ]
  },

  {
    id: "unit_bonus",
    icon: "ICON_RESIDENTIAL_Max_Bonus",
    className: "sub-title",
    title: "Max units with all bonuses",
    subTitle: "",
    description: "",
    footerText: true,
    lock: false,
    show: true,
    properties: [
      {
        id: 1,
        small_heading: "No Bonuses are available for this lot",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      {
        id: 2,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Unit:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_c",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_d",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 5,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_e",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 7,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 8,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_e",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 9,
        small_heading: "Unit:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_f",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_g",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 11,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_h",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 12,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 13,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 14,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_i",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 15,
        small_heading: "Unit:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_j",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 16,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_k",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 17,
        small_heading: "Applies to this lot?",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_l",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 18,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 19,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 20,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      }
    ]
  },
  {
    id: "max_unit_bonus",
    icon: "ICON_RESIDENTIAL_Max_Bonus",
    className: "sub-title",
    title: "Max Unit bonus",
    subTitle: "",
    description: "",
    show: true,
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_k",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Units: ",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_l",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_l",
        fontClass: "value-03",
        hr: true,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 3,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        sourceHr: false,
        display: true
      },
      //
      {
        id: 5,
        small_heading: "No Data",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "unit_bonus_maximum_k",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      }
    ]
  }
];
export const PARKING = [
  {
    id: "parking_requirements",
    icon: "ICON_RESIDENTIAL_Min",
    className: "sub-title",
    title: "Parking Requirements",
    subTitle: "",
    description: "",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        title: "",
        title_desc_inline: "",
        title_desc:
          "Parking requirements in the City of Austin are dependent on the proposed uses and square footage of proposed use. A multi-family building will have different car and bike parking requirements than an office building of the same size. For the detailed car and bike parking requirement formulas, please see the Chapter 266 in Title 33 (link below).",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "parking_requirements",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        className: "sub-heading",
        subClassName: "sub-heading-2",
        link: "",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        source: "33.266",
        link: "https://www.austin.gov/sites/default/files/code/266-parking.pdf",
        display: true
      }
    ]
  }
];
export const EXISTING_STRUCTURE = [
  {
    id: "assessor_details",
    icon: "ICON_BUILDABLE_DELTA",
    className: "sub-title",
    title: "Assessor Detail",
    subTitle:
      "This size, year built, and other data about the existing structure, if any",
    description: "",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Assessed total value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Assessed land value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Assessed improvement value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_c",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Last sale price:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_d",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Last sale Date:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_e",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        sourceHr: false,
        display: true
      }
    ]
  },

  {
    id: "structure_details",
    icon: "ICON_BUILDABLE_DELTA",
    className: "sub-title",
    title: "Structure Detail",
    subTitle:
      "This size, year built, and other data about the existing structure, if any",
    description: "",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Year built:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Square footage:",
        small_heading_class: "small-heading-class value-title",
        title: "123,456,789",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Existing Use:",
        small_heading_class: "small-heading-class value-title",
        title: "$123,456,789",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_c",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        sourceHr: false,
        display: true
      }
    ]
  },

  {
    id: "existing_lot_coverage",
    icon: "ICON_BUILDABLE_DELTA",
    className: "sub-title",
    title: "Existing lot coverage",
    subTitle:
      "This size, year built, and other data about the existing structure, if any",
    description: "",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Number of structures:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Lot coverage:",
        small_heading_class: "small-heading-class value-title",
        title: "123,456,789",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "lot_type_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "Austin Open Data",
        link: "",
        sourceHr: false,
        display: true
      }
    ]
  },

  {
    id: "property_owner",
    icon: "ICON_BUILDABLE_DELTA",
    className: "sub-title",
    title: "Property Owner",
    subTitle:
      "This size, year built, and other data about the existing structure, if any",
    description: "",
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Name:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "property_owner_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Address:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "property_owner_b, property_owner_c",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      }
    ]
  }
];
export const MASSING = [
  {
    id: "massing",
    className: "sub-title",
    title: "Massing/Bulk Rules",
    description: "",
    footerText: true,
    show: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "No massing rules required for this lot",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      {
        id: 2,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 4,
        small_heading: "Applies to this lot:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 35,
        className: "far_adjusted_b_hr",
        sourceHr: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Other Massing Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 7,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 8,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 9,
        small_heading: "Applies to this lot:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 35,
        className: "far_adjusted_b_hr",
        sourceHr: false,
        display: true
      },
      {
        id: 11,
        small_heading: "Other Massing Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      //3
      {
        id: 12,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 13,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 14,
        small_heading: "Applies to this lot:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 15,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 35,
        className: "far_adjusted_b_hr",
        sourceHr: false,
        display: true
      },
      {
        id: 16,
        small_heading: "Other Massing Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      //4
      {
        id: 17,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 18,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 19,
        small_heading: "Applies to this lot:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 20,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 35,
        className: "far_adjusted_b_hr",
        sourceHr: false,
        display: true
      },
      {
        id: 21,
        small_heading: "Other Massing Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      //5
      {
        id: 22,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 23,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 24,
        small_heading: "Applies to this lot:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 25,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 35,
        className: "far_adjusted_b_hr",
        sourceHr: false,
        display: true
      },
      {
        id: 26,
        small_heading: "Other Massing Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      //6
      {
        id: 27,
        small_heading: "Rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 28,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 29,
        small_heading: "Applies to this lot:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 30,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      },
      {
        id: 35,
        className: "far_adjusted_b_hr",
        sourceHr: false,
        display: true
      },
      {
        id: 31,
        small_heading: "Other Massing Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      }
    ]
  }
];
export const COVERAGE = [
  {
    id: "coverage",
    className: "sub-title",
    title: "Coverage",
    description: "",
    footerText: true,
    show: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "Coverage rule:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Allowable coverage:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },
  {
    id: "coverage_bonus",
    icon: "ICON_FAR_SPECIAL",
    className: "sub-title",
    title: "Coverage Bonuses",
    subTitle: "Bonus Coverage available based on development options",
    description: "",
    show: true,
    footerText: true,
    lock: false,
    properties: [
      {
        id: 32,
        small_heading: "No Coverage bonuses available for this lot",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        indent: true
      },
      //
      {
        id: 1,
        small_heading: "Are Coverage bonuses available for this lot?",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Bonus condition:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Bonus Value:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_d",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 6,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_e",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 7,
        className: "far_bonus_c_hr",
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 8,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 9,
        small_heading: "Bonus condition:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_f",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        small_heading: "Bonus Value:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_g",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 11,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_h",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 12,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_i",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 13,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 14,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 15,
        small_heading: "Bonus condition:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_j",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 16,
        small_heading: "Bonus Value:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_k",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 17,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_k",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 18,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "cvg_bonus_l",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 19,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: false,
        display: true
      },
      {
        id: 20,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      }
    ]
  },
  {
    id: "coverage_restriction",
    icon: "ICON_FAR_SPECIAL",
    className: "sub-title",
    title: "Coverage Restriction",
    subTitle: "Bonus FAR available based on development options",
    description: "",
    show: true,
    footerText: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "No Coverage restriction exist for this site",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "coverage_restriction_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: false,
        indent: true
      },
      {
        id: 2,
        small_heading: "Restriction:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "coverage_restriction_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Value",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "coverage_restriction_c",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "coverage_restriction_d",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 5,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "coverage_restriction_e",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        className: "",
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: true,
        display: true
      },
      {
        id: 7,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      },
      {
        id: 8,
        small_heading: "Restriction:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "coverage_restriction_f",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 9,
        small_heading: "Value:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "coverage_restriction_g",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 10,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "coverage_restriction_h",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 11,
        small_heading: "Applies to this lot?:",
        small_heading_class: "small-heading-class-desc value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "",
        subClassName: "sub-heading-2",
        property_class: "coverage_restriction_i",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 12,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        hr: true,
        display: true
      },
      {
        id: 13,
        small_heading_class: "small-heading-class",
        onlyLine: true,
        sourceHr: false,
        display: true
      }
    ]
  },

  {
    id: "max_bonus_coverage",
    className: "sub-title",
    title: "Max Bonus Coverage",
    description: "",
    footerText: true,
    show: true,
    lock: false,
    properties: [
      {
        id: 0,
        small_heading: "",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 1,
        small_heading: "Max Bonus Condition:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Value:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_b",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 4,
        small_heading: "Applies to this lot:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 5,
        small_heading: "Source:",
        small_heading_class: "small-heading-class link",
        source_p: "",
        link: "",
        display: true
      }
    ]
  },
  {
    id: "max_build_footpront",
    className: "sub-title",
    title: "MaxBuild Footprint",
    description: "",
    footerText: true,
    show: true,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "MaxBuild Footprint:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Existing coverage:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 3,
        small_heading: "Difference:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 4,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "far_adjusted_a",
        fontClass: "value-01",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      }
    ]
  }
];
export const DEVELOPMENT = [
  {
    id: "max_buildable_square_footage",
    icon: "ICON_DEVELOPMENT_MAX",
    className: "sub-title",
    title: "Max building potential",
    subTitle: "",
    description: "",
    footerText: false,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading: "AOR (As-of-Right):",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "without_bonuses_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 2,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "development_comment_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 3,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_text: "UrbanForm\n" + "calculation",
        link: "https://urbanform.us/faq",
        display: true
      },
      {
        id: 4,
        small_heading: "",
        small_heading_class: "small-heading-class",
        customStyle: { marginBottom: "-10px" },
        onlyLine: true,
        sourceHr: true,
        display: true
      },
      {
        id: 5,
        small_heading: "ABT (All Bonuses Taken):",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "without_bonuses_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Comment:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "development_comment_a",
        fontClass: "value-03",
        hr: false,
        ul: false,
        display: true,
        applyItalic: true
      },
      {
        id: 7,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_text: "UrbanForm\n" + "calculation",
        link: "https://urbanform.us/faq",
        display: true
      }
    ]
  },
  {
    id: "building_delta",
    icon: "ICON_BUILDABLE_DELTA",
    className: "sub-title",
    title: "Buildable square footage by FAR",
    subTitle:
      "The differential between maximum building potential and existing,",
    description: "",
    footerText: false,
    lock: false,
    properties: [
      {
        id: 1,
        small_heading:
          "The difference between As-of-right (AOR) building potential and existing:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "without_bonuses_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 2,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_text: "UrbanForm\n" + "calculation",
        link: "https://urbanform.us/faq",
        display: true
      },
      {
        id: 7,
        small_heading: "",
        small_heading_class: "small-heading-class",
        customStyle: { marginBottom: "-10px" },
        onlyLine: true,
        sourceHr: true,
        display: true
      },

      {
        id: 3,
        small_heading:
          "The difference between all bonuses taken (ABT) building potential and existing:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "without_bonuses_a",
        fontClass: "value-02",
        fontClass3: "value-title",
        hr: false,
        ul: false,
        display: true
      },

      {
        id: 4,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_text: "UrbanForm\n" + "calculation",
        link: "https://urbanform.us/faq",
        display: true
      },
      {
        id: 7,
        small_heading: "",
        small_heading_class: "small-heading-class",
        customStyle: { marginBottom: "-10px" },
        onlyLine: true,
        sourceHr: true,
        display: true
      },
      {
        id: 5,
        small_heading:
          "The difference between MaxBuild Footprint and existing coverage:",
        small_heading_class: "small-heading-class value-title",
        title: "",
        title_desc_inline: "",
        title_desc: "",
        title_cat: "",
        className: "sub-heading",
        subClassName: "sub-heading-2",
        property_class: "without_bonuses_a",
        fontClass: "value-02",
        hr: false,
        ul: false,
        display: true
      },
      {
        id: 6,
        small_heading: "Source:",
        small_heading_class: "small-heading-class value-title link",
        source_text: "UrbanForm\n" + "calculation",
        link: "https://urbanform.us/faq",
        display: true
      }
    ]
  },
  { id: "max_building_calculator", icon: "ICON_DEVELOPMENT_MAX" }
];
export const FORGET_PASSWORD = [
  {
    type: "text",
    name: "token",
    placeHolder: "Verification Code",
    label: "Verification Code",
    required: true
  },
  {
    type: "password",
    name: "password",
    placeHolder: "Password",
    label: "Password",
    required: true
  },
  {
    type: "password",
    name: "confirm_password",
    placeHolder: "Confirm Password",
    label: "Confirm Password",
    required: true
  }
];
export const CHANGE_PASSWORD = [
  {
    type: "password",
    name: "current_password",
    placeHolder: "Current Password",
    label: "Current Password",
    required: true
  },
  {
    type: "password",
    name: "password",
    placeHolder: "Password",
    label: "Password",
    required: true
  },
  {
    type: "password",
    name: "confirm_password",
    placeHolder: "Confirm Password",
    label: "Confirm Password",
    required: true
  }
];
export const SAVED_PROPERTY = [
  {
    id: 1,
    name: "property name",
    created_at: "20-05-2021"
  }
];
export const SAVED_REPORT = [
  {
    id: 1,
    name: "report name",
    report_path: "/asda",
    created_at: "20-05-2021"
  }
];

export const INFO_CONSTANT = {
  summary: SUMMARY,
  identifiers: IDENTIFIERS,
  zoning: ZONE_DETAIL,
  lot_info: LAND_DETAIL,
  setbacks: SETBACKS,
  max_foot_print: MAX_FOOT_PRINT,
  use_regulation: USE_REGULATIONS,
  far: FAR,
  coverage: COVERAGE,
  height_limit: HEIGHT_LIMIT,
  residential_units: RESIDENTIAL_UNITS,
  existing_structures: EXISTING_STRUCTURE,
  development: DEVELOPMENT,
  parking: PARKING,
  massing: MASSING,
  other: OTHER,
  info_not_updated: true
};
export const EARTH_DIRECTIONS = [
  "SW",
  "SN",
  "SE",
  "SS",
  "WS",
  "WN",
  "WE",
  "WW",
  "EW",
  "EN",
  "ES",
  "EE",
  "NW",
  "NN",
  "NE",
  "NN"
];

export const HELP_CONTENT = [
  {
    id: 1,
    code: "jurisdiction_info",
    title: "jurisdiction info",
    iconName: "ICON_jURISDICATION"
  },
  {
    id: 2,
    code: "zoning_guides",
    title: "zoning guides",
    iconName: "ICON_ZONING_GUIDES"
  },
  {
    id: 3,
    code: "urbanform_news",
    title: "urbanform news",
    iconName: "ICON_NEWS"
  },
  {
    id: 4,
    code: "contact_support",
    title: "Contact Support",
    iconName: "ICON_SUPPORT"
  }
];
export const CONTACT_SUPPORT = [
  {
    type: "text",
    name: "name",
    placeHolder: "name",
    label: "name",
    required: true
  },
  {
    type: "email",
    name: "email",
    placeHolder: "Email",
    label: "Email",
    required: true
  },
  {
    type: "text",
    name: "subject",
    placeHolder: "Subject",
    label: "Subject",
    required: true
  },
  {
    type: "textarea",
    name: "message",
    placeHolder: "Message",
    label: "Message",
    required: true
  }
];
export const EDIT_PAYMENT_DETAILS = [
  {
    type: "text",
    name: "name",
    placeHolder: "Card holder name",
    label: "Card holder name",
    required: false,
    value: ""
  },
  {
    type: "month",
    name: "month",
    placeHolder: "Month",
    label: "Month",
    required: false,
    value: ""
  },
  {
    type: "year",
    name: "year",
    placeHolder: "Year",
    label: "Year",
    required: false,
    value: ""
  },
  {
    type: "text",
    name: "street1",
    placeHolder: "Street 1",
    label: "Street 1",
    required: false,
    value: ""
  },
  {
    type: "text",
    name: "street2",
    placeHolder: "Street 2",
    label: "Street 2",
    required: false,
    value: ""
  },
  {
    type: "number",
    name: "postalCode",
    placeHolder: "Postal Code",
    label: "Postal Code",
    required: false,
    value: ""
  },
  {
    type: "text",
    name: "city",
    placeHolder: "City",
    label: "City",
    required: false,
    value: ""
  },
  {
    type: "text",
    name: "state",
    placeHolder: "State",
    label: "State",
    required: false,
    value: ""
  },
  {
    type: "text",
    name: "country",
    placeHolder: "Country",
    label: "Country",
    required: false,
    value: ""
  }
];
export const MONTHS = [
  {
    key: 1,
    text: "JAN",
    value: 1
  },
  {
    key: 2,
    text: "FEB",
    value: 2
  },
  {
    key: 3,
    text: "MAR",
    value: 3
  },
  {
    key: 4,
    text: "APR",
    value: 4
  },
  {
    key: 5,
    text: "MAY",
    value: 5
  },
  {
    key: 6,
    text: "JUNE",
    value: 6
  },
  {
    key: 7,
    text: "JULY",
    value: 7
  },
  {
    key: 8,
    text: "AUG",
    value: 8
  },
  {
    key: 9,
    text: "SEPT",
    value: 9
  },
  {
    key: 10,
    text: "OCT",
    value: 10
  },
  {
    key: 11,
    text: "NOV",
    value: 11
  },
  {
    key: 12,
    text: "DEC",
    value: 12
  }
];

export const citiesAndPlans = {};
