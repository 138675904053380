import { envsafe, json, str, url } from "envsafe";

export const env = envsafe({
  REACT_APP_URBANFORM_API_URL: url({
    devDefault: "http://localhost:8000",
    // devDefault: "https://urbanform-backend-production.onrender.com/api",
    default: "https://urbanform-backend-production.onrender.com"
  }),
  // TODO this should be API driven
  REACT_APP_ALL_REGIONS: str({
    default:
      '{ "portland": [], "seattle": [], "san_diego": [], "austin": [],"los_angeles": [], "yamhill": [] }'
  }),
  // TODO this should be API driven
  REACT_APP_JURISDICTION_SLUG_MAP: json({
    default: {
      "City of San Diego": "san_diego",
      "City of Los Angeles": "los_angeles",
      "City of Austin": "austin",

      // seattle
      "City of Seattle": "seattle",
      "City of Bellevue": "bellevue",

      // portland
      "City of Portland": "portland",
      "City of Beaverton": "beaverton",

      // yamhill
      "City of McMinnville": "mcminnville",
      "City of Sheridan": "sheridan",
      "City of Amity": "amity",
      "City of Willamina": "willamina",
      "City of Dundee": "dundee",
      "City of Dayton": "dayton",
      "City of Newberg": "newberg",
      "City of Carlton": "carlton",
      "Yamhill County": "yamhill_county",
      "City of Lafayette": "lafayette"
    }
  }),

  REACT_APP_MAPBOX_ACCESS_TOKEN: str({
    default:
      "pk.eyJ1IjoicXZ0MjgyIiwiYSI6ImNrZmtxOWdveDB2ZXkyeG56NzR0aW9jbXUifQ.Plxub9XCzhhAbZ3MJbAXMw"
  }),
  REACT_APP_STRIPE_KEY: str({
    devDefault:
      "pk_test_51J3keRKwuRPRmetLYA1Zgpg1T50OlJj7L5U7p60MB7hvR8VaaXhoDk0llLi95tOaWUU77c4rNEx7fa22nyEStrxD00X4iN7RXg",
    default:
      "pk_live_51J3keRKwuRPRmetLx954L0Hp0oEMK3O1xaVJ02YH9ojGDTzaHaQCQXRw5Pox6VUfCQl1ZS4PfU63M4ys66E4F61g00CDRf3ISt"
  })
});
