import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useState } from "react";
import { positions, Provider } from "react-alert";
import { useBeforeunload } from "react-beforeunload";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../css/Sidebar.css";
import { Map } from "./Map/Map";
import SideMenu from "./SideMenu";
import Sidebar from "./Sidebar";

import { Analytics } from "@vercel/analytics/react";
import jwtDecode from "jwt-decode";
import { isMobile } from "react-device-detect";
import { Dimmer, Icon, Loader, Message } from "semantic-ui-react";
import packageJson from "../../package.json";
import { setDataWindowContent } from "../actions/actions_datawindow";
import { getFiltersData, seRunFilter } from "../actions/actions_filters";
import { getReportCount } from "../actions/actions_info";
import {
  activityLogger,
  getPlanPrice,
  getUpdatedAccessToken,
  setLoader,
  setUserLoginError,
  userNotAuth,
  verifyToken
} from "../actions/actions_user";
import config from "../config";
import { UF_TOKEN } from "../constants";
import "../css/App.css";
import DataWindow from "./dataWindow/DataWindow";
import { applyFetchInterceptors } from "./fetchInterceptor";
import { initializePosthog } from "./posthog";

applyFetchInterceptors();
initializePosthog();

const App = ({ dispatch }) => {
  const [sidebarVisible, changeSidebarVisible] = useState(
    window.innerWidth >= 768 ? true : false
  );
  // TODO refactor away from current content
  const [currentContent, setCurrentContent] = useState("Home");
  const [unAuthorizedModal, setUnAuthorizedModal] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  const dataWindow = useSelector((state) => state.dataWindow.dataWindow);
  const { loader, user } = useSelector((state) => state.user);
  const [showPanel, setShowPanel] = useState(true);
  const { filterValues, runFilter } = useSelector((state) => state.filters);
  const historyToken = window.localStorage.getItem("_uft");
  const alreadyLogin = window.localStorage.getItem("already");
  const navigate = useNavigate();
  const sessionToken = new URL(window.location).searchParams.get("t");
  const token = localStorage.getItem("_uft");
  const options = {
    timeout: 5000,
    position: positions.TOP_CENTER
  };
  const successAlert = {
    backgroundColor: "#43c1f3",
    marginTop: "5px",
    color: "#ffffff"
  };
  const errorAlert = {
    backgroundColor: "#E2444A",
    marginTop: "5px",
    color: "#ffffff"
  };
  const emptyCache = async () => {
    const version = localStorage.getItem("version");
    if (version != packageJson?.version) {
      if ("caches" in window) {
        const names = await caches.keys();

        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }
      localStorage.clear();
      localStorage.setItem("version", packageJson?.version);
    }
  };
  useEffect(() => {
    // When the app loads, the region slug is set immediately.
    localStorage.setItem("regionSlug", config.REGION.slug);

    // TODO We also currently default the jurisdiction slug to the first listed jurisdiction
    // (all regions have minimally 1 jurisdiction). The UX should probably change.
    localStorage.setItem("jurisdictionSlug", config.JURISDICTIONS[0].slug);
  }, []);
  useEffect(() => {
    if (sessionToken != null) {
      try {
        const decodedToken = jwtDecode(sessionToken);
        if (decodedToken.exp < new Date().getTime()) {
          dispatch(getUpdatedAccessToken(navigate, sessionToken));
        } else {
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 10);
        }
      } catch (error) {
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 10);
      }
    } else {
      const session = new URL(window.location).searchParams.get("s");
      if (session != null && session === "expired") {
        localStorage.removeItem(UF_TOKEN);
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 10);
      }
    }
  }, [sessionToken]);
  useEffect(() => {
    if (sessionToken != null) {
      return;
    }
    if (historyToken === null && alreadyLogin === "true") {
      window.localStorage.removeItem("already");
      dispatch(setUserLoginError("Your account login in other device."));
      dispatch(userNotAuth());
      dispatch(
        setDataWindowContent({
          show: { showData: "invalidToken", showControl: "login" }
        })
      );
      dispatch(setLoader(false));
    }
  }, [historyToken, alreadyLogin, sessionToken]);
  useEffect(() => {
    if (sessionToken != null) {
      return;
    }
    const body = {
      event: "Session start"
    };
    dispatch(activityLogger(body));
    if (Object.keys(filterValues.zoning).length === 0 && !runFilter) {
      // TODO remove
      dispatch(getFiltersData("portland"));
    }
    dispatch(seRunFilter(false));
    // check for token on app render
    dispatch(verifyToken(token));
  }, [sessionToken]);

  useBeforeunload((event) => {
    if (sessionToken != null) {
      return;
    }
    const body = {
      event: "Session end"
    };
    dispatch(activityLogger(body));
  });
  useEffect(() => {
    emptyCache();
  }, [loader]);
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }
    window.addEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    // to get the count of downloads/remaining of reports
    !isMobile &&
      token &&
      ["Professional", "Enterprise"].includes(user?.status) &&
      dispatch(getReportCount(token));
    // display popup if user is not logged in
    setTimeout(() => {
      if (!token && !window.localStorage.getItem(UF_TOKEN)) {
        handleUnauthorizedUserModal();
      }
    }, 30000);
  }, [token]);
  useEffect(() => {
    if (sessionToken != null) {
      return;
    }
    if (isMobile) {
      setCurrentContent("mobileMap");
    }
    dispatch(getPlanPrice());
  }, [sessionToken]);

  const AlertTemplate = ({ message, options }) => (
    <Message
      icon
      style={
        options.type === "success"
          ? successAlert
          : options.type === "error"
            ? errorAlert
            : ""
      }
    >
      {options.type === "success" && <Icon name="check square outline" />}
      {options.type === "error" && <Icon name="x icon" />}
      <Message.Content>
        <Message.Header>
          {typeof message !== "string" ? JSON.stringify(message) : message}
        </Message.Header>
      </Message.Content>
    </Message>
  );
  const getPanelWidth = () => {
    if (window.innerWidth <= 768) {
      return window.innerWidth === 768
        ? "218px"
        : window.innerWidth - 56 + "px";
    } else {
      return dataWindow === true ? "650px" : "218px";
    }
  };
  const getMapWidth = () => {
    if (window.innerWidth <= 768) {
      return window.innerWidth === 768 ? "0px" : "0px";
    } else {
      return dataWindow === true ? "640px" : "0px";
    }
  };
  const showMapHandler = () => {
    return window.innerWidth < 768 && showPanel === false;
  };
  const handleSideBarPanel = () => {
    setShowPanel(true);
  };
  const handleUnauthorizedUserModal = () => {
    setUnAuthorizedModal(!unAuthorizedModal);
  };
  return (
    <Provider template={AlertTemplate} {...options}>
      <Analytics />
      <div id="urbanform-styles">
        <div className="menu-columns">
          <div
            className="thick-column"
            style={{
              width:
                window.innerWidth <= 768
                  ? "calc(100% - 80px)"
                  : "calc(100% - 85px)"
            }}
          >
            <div className="main-columns">
              {showMapHandler && (
                <div
                  className={"map-container"}
                  style={{ width: `calc( 100% - ${getMapWidth()} )` }}
                >
                  <Map
                    sidebarVisible={sidebarVisible}
                    setCurrentContent={setCurrentContent}
                  />
                </div>
              )}

              <div
                className={"sidebar-container"}
                style={{ width: `${getPanelWidth()})` }}
              >
                <Sidebar
                  currentContent={currentContent}
                  setCurrentContent={setCurrentContent}
                  showPanel={showPanel}
                  setShowPanel={setShowPanel}
                />

                <div>
                  <div
                    style={
                      dataWindow &&
                      currentContent !== "User" &&
                      currentContent !== "Help"
                        ? {
                            position: isMobile ? "absolute" : "",
                            left: isMobile ? "47px" : "",
                            top: isMobile ? "7px" : "",
                            padding: isMobile ? "" : "27px 10px 0 10px",
                            background:
                              currentContent === "Search"
                                ? "#FFEFF9"
                                : "#F2F2F2"
                          }
                        : { display: "none" }
                    }
                    className="info-heading"
                  >
                    <div id="mapbox-geocoder-info"></div>
                  </div>
                  {dataWindow && (
                    <DataWindow
                      currentContent={currentContent}
                      setCurrentContent={setCurrentContent}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="thin-column"
            style={{ width: window.innerWidth <= 768 ? "80px" : "85px" }}
          >
            <SideMenu
              currentContent={currentContent}
              setCurrentContent={setCurrentContent}
              dispatch={dispatch}
              showPanel={showPanel}
              setShowPanel={setShowPanel}
              showMap={currentContent === "mobileMap"}
            />
          </div>

          {loader && isMobile === false && (
            <Dimmer active>
              <Loader content="Loading" />
            </Dimmer>
          )}
          {loader && isMobile && (
            <Dimmer active>
              <Loader className={"mobile-loader"} content="Loading" />
            </Dimmer>
          )}
        </div>
      </div>
    </Provider>
  );
};

export default connect()(App);
