import { env } from "@/env";

/** circumvent the backend to always get an address via mapbox in the cases we are missing */
export const getAddressFromLatLng = async (
  lat: number,
  lng: number
): Promise<string> => {
  const mapboxReverseGeocodingApiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;

  try {
    const response = await fetch(mapboxReverseGeocodingApiUrl);

    if (!response.ok) {
      throw new Error("Geocoding request failed");
    }

    const data = await response.json();

    if (data.features && data.features.length > 0) {
      const address = data.features[0].place_name;

      return address;
    } else {
      console.log("No results found");
    }
  } catch (error) {
    console.error("Error during reverse geocoding:", error);
  }
};
