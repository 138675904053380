import jwt_decode from "jwt-decode";
import { CONTACT_SUPPORT, INFO_CONSTANT, PLANS, UF_TOKEN } from "../constants";
import { env } from "../env";
import { downloadPdf } from "../utils/Utils";
import { setDataWindow, setDataWindowContent } from "./actions_datawindow";
import { updateFilterPayment } from "./actions_filters";
import {
  getReportCount,
  infoUserNotLoggedIn,
  updatedInfoSection
} from "./actions_info";

export const USER_HAS_SIGNEDUP = "USER_HAS_SIGNEDUP";
export const USER_HAS_LOGGEDIN = "USER_HAS_LOGGEDIN";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const SET_USER_LOGIN_ERROR = "SET_USER_LOGIN_ERROR";
export const USER_HAS_SUBSCRIBED = "USER_HAS_SUBSCRIBED";
export const USER_LOG_OUT = "USER_LOG_OUT";
export const USER_NO_AUTH = "USER_NO_AUTH";
export const USER_HAS_UPDATED = "USER_HAS_UPDATED";
export const SET_USER_UPDATE_ERROR = "SET_USER_UPDATE_ERROR";
export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE";
export const SET_LOADER = "SET_LOADER";
export const SET_ALREADY_LOGIN = "SET_ALREADY_LOGIN";
export const SET_FORGET_PASSWORD_ERROR = "SET_FORGET_PASSWORD_ERROR";
export const SET_PLAN_PRICE = "SET_PLAN_PRICE";
export const SET_NEWS_FEED = "SET_NEWS_FEED";
export const SET_QUOTA_EXCEED = "SET_QUOTA_EXCEED";
export const SET_DEFAULT_PAYMENT_METHOD = "SET_DEFAULT_PAYMENT_METHOD";
export const SET_ALL_PAYMENT_METHOD = "SET_ALL_PAYMENT_METHOD";
export const SET_ACCOUNT_ACTIVATION_PROCESS = "SET_ACCOUNT_ACTIVATION_PROCESS";

export function getUpdatedAccessToken(navigate, token) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/users/update-token`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("msg")) {
        dispatch(setUserLoginError(data.msg));
      } else {
        const user = jwt_decode(data.token);
        dispatch(handleUserLogin({ user: user.user, token: data.token }));
        dispatch(setUserLoginError(null));
        // store token in local storage
        window.localStorage.setItem(UF_TOKEN, data.token);
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 10);
      }
    } catch {
      dispatch(
        setUserLoginError("Something went wrong. Please try again later.")
      );
    }
  };
}

export function signupUser(userInfo) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/users/`,
        {
          method: "POST",
          body: JSON.stringify(userInfo)
        }
      );
      const data = await response.json();
      if (!Object.keys(data).includes("token")) {
        const errorMessages = [];
        dispatch(setLoader(false));
        Object.keys(data).includes("email") &&
          errorMessages.push("Account with this email already exist!");
        Object.keys(data).includes("phone_number") &&
          errorMessages.push("You have entered an invalid phone number!");
        Object.keys(data).includes("zip_code") &&
          errorMessages.push("You have entered an invalid zip code!");
        if (Object.keys(data).includes("password")) {
          Array.isArray(data.password) &&
            data.password.forEach((msg) => {
              errorMessages.push(msg);
              errorMessages.push(" ");
            });
          !Array.isArray(data.password) && errorMessages.push(data.password);
        }
        dispatch(setUserLoginError(errorMessages));
      } else {
        dispatch(setAccountActivationProcess(true));
        dispatch(setLoader(false));
        dispatch(setUserLoginError(null));
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: { isContact: false, choosePlan: {} }
          })
        );
        dispatch(
          setDataWindowContent({
            show: {
              showData: "account-verification",
              showControl: "sign-up"
            }
          })
        );
        dispatch(
          setAlertMessage(
            "Email with verification code has been sent to your account!"
          )
        );
      }
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setUserLoginError(error.message));
    }
  };
}

export function activityLogger(object) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        env.REACT_APP_URBANFORM_API_URL + "/api/auth/event-logger/",
        {
          method: "POST",
          body: JSON.stringify(object)
        }
      );
    } catch {
      // noop
    }
  };
}

export function handleUserSignup(userInfo) {
  return {
    type: USER_HAS_SIGNEDUP,
    payload: userInfo
  };
}

export function loginUser(
  userInfo,
  filterPlan = null,
  infoNotLoggedIn = false,
  infoPayload = {}
) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/auth/login/`,
        {
          method: "POST",
          body: JSON.stringify(userInfo)
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("is_active") && !data.is_active) {
        dispatch(setAlertMessage("Please enter the verification code"));
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: { isContact: false, choosePlan: {} }
          })
        );
        dispatch(
          setDataWindowContent({
            show: {
              showData: "account-verification",
              showControl: "sign-up"
            }
          })
        );
        dispatch(setLoader(false));
      } else if (Object.keys(data).includes("is_valid") && !data.is_valid) {
        dispatch(
          setUserLoginError("You have entered an invalid email or password.")
        );
        dispatch(setLoader(false));
      } else if (Object.keys(data).includes("message") && !data.is_valid) {
        dispatch(setUserLoginError(data.message));
        dispatch(setLoader(false));
      } else {
        const user = jwt_decode(data.access);
        if (Object.keys(user).includes("msg")) {
          dispatch(setUserLoginError(user.msg));
          dispatch(setLoader(false));
        } else {
          dispatch(handleUserLogin({ user: user.user, token: data.access }));
          ["null", null].includes(user.user.status) === true
            ? dispatch(
                setDataWindowContent({
                  show: {
                    showData: "choose-plan",
                    showControl: "choose-plan"
                  }
                })
              )
            : dispatch(
                setDataWindowContent({
                  show: { showData: "dashboard", showControl: "dashboard" }
                })
              );
          if (
            filterPlan &&
            filterPlan === "Enterprise" &&
            user.user.status !== "Enterprise"
          ) {
            dispatch(
              setDataWindow({
                dataWindow: true,
                content: {
                  isContact: null,
                  isForce: true,
                  choosePlan: { plans: PLANS, activeContent: "ent" },
                  downloadPdf: true
                }
              })
            );
            user.user.status !== "Enterprise" &&
              dispatch(
                updateFilterPayment({
                  filterUpgradeProcess: true,
                  filterPaymentDone: false
                })
              );
          }
          dispatch(setUserLoginError(null));
          // dispatch(setAlertMessage("You have successfully logged in."))
          dispatch(setLoader(false));
          // store token in local storage
          window.localStorage.setItem(UF_TOKEN, data.access);

          if (infoNotLoggedIn === true) {
            dispatch(setLoader(true));
            dispatch(infoUserNotLoggedIn(false));
            if (infoPayload.primaccnum?.length) {
              dispatch(updatedInfoSection(infoPayload, INFO_CONSTANT));
            }
          }
        }
        dispatch(setAlertMessage("Logged in successfully"));
      }
    } catch (error) {
      dispatch(setUserLoginError(error));
      dispatch(setLoader(false));
    }
  };
}

export function logoutUser(token) {
  return async (dispatch) => {
    try {
      await fetch(`${env.REACT_APP_URBANFORM_API_URL}/api/auth/logout/`, {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(setAlertMessage("Logged out successfully"));
      window.localStorage.removeItem(UF_TOKEN);
      dispatch(handleUserlogout());
    } catch {
      dispatch(
        setUserLoginError("Something went wrong. Please try again later.")
      );
    }
  };
}
export function saveToken(token) {
  return {
    type: SAVE_TOKEN,
    payload: token
  };
}

export function handleUserLogin(userInfo) {
  return {
    type: USER_HAS_LOGGEDIN,
    payload: userInfo
  };
}

export function setUserLoginError(loginError) {
  return {
    type: SET_USER_LOGIN_ERROR,
    payload: loginError
  };
}

export function patchUserSubscription(body) {
  const { user_id } = body["user"];
  return async (dispatch) => {
    const response = await fetch(
      `${env.REACT_APP_URBANFORM_API_URL}/api/users/${user_id}`,
      {
        method: "PATCH",
        body: JSON.stringify(body)
      }
    );
    const data = await response.json();
    Object.keys(data).includes("msg")
      ? console.error("Error: ", data)
      : dispatch(setUserSubscription(data));
  };
}

export function setUserSubscription(subscriptionInfo) {
  return {
    type: USER_HAS_SUBSCRIBED,
    payload: subscriptionInfo
  };
}

export function verifyToken(token) {
  return async (dispatch) => {
    const t = token || window.localStorage.getItem(UF_TOKEN);
    if (!t) {
      dispatch(userNotAuth());
      // dispatch(handleUserlogout());wi
      dispatch(
        setDataWindowContent({ show: { showData: "", showControl: "login" } })
      );
      return;
    }
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/auth/verify/`,
        {
          method: "POST",
          body: JSON.stringify({ token: t })
        }
      );
      const data = await response.json();
      if (data.is_valid) {
        const user = jwt_decode(t);
        dispatch(handleUserLogin({ user: user.user, token: t }));
        if (user === null) {
          dispatch(logoutUser());
          dispatch(
            setDataWindowContent({
              show: { showData: "resetLogin", showControl: "login" }
            })
          );
        } else {
          ["null", null].includes(user.user.status) === true
            ? dispatch(
                setDataWindowContent({
                  show: {
                    showData: "choose-plan",
                    showControl: "choose-plan"
                  }
                })
              )
            : dispatch(
                setDataWindowContent({
                  show: { showData: "dashboard", showControl: "dashboard" }
                })
              );
        }
        window.localStorage.setItem(UF_TOKEN, t);
      } else if (!data.is_valid) {
        dispatch(logoutUser());
        dispatch(
          setUserLoginError("You have been logged out due to inactivity")
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "resetLogin", showControl: "login" }
          })
        );
      }
    } catch {
      dispatch(
        setUserLoginError("You have entered an invalid email or password.")
      );
      dispatch(setLoader(false));
    }
  };
}
export function handleUserlogout() {
  return {
    type: USER_LOG_OUT,
    payload: null
  };
}

export function userNotAuth() {
  return {
    type: USER_NO_AUTH,
    payload: null
  };
}

export function updateUser(userData, id, token) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/users/${id}`,
        {
          method: "PATCH",
          body: JSON.stringify(userData)
        }
      );
      const data = await response.json();
      dispatch(setLoader(false));
      const user = jwt_decode(data.token);
      dispatch(handleUserLogin({ user: user.user, token: data.token }));
      window.localStorage.setItem(UF_TOKEN, data.token);
      if (Object.keys(data).includes("status")) {
        dispatch(
          setDataWindowContent({
            show: { showData: "", showControl: "dashboard" }
          })
        );
      }
      return true;
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setUserUpdateError(error.message));
      return false;
    }
  };
}

export function setUserUpdateError(updateError) {
  return {
    type: SET_USER_UPDATE_ERROR,
    payload: updateError
  };
}

export function setAlertMessage(alertMessage) {
  return {
    type: SET_ALERT_MESSAGE,
    payload: alertMessage
  };
}

export function setLoader(loader) {
  return {
    type: SET_LOADER,
    payload: loader
  };
}
export function setAlreadyLogIn(loader) {
  return {
    type: SET_ALREADY_LOGIN,
    payload: loader
  };
}

export function downloadUserReport(object, token) {
  const headers = { "Region-Slug": localStorage.getItem("regionSlug") };

  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/report/`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(object)
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("message")) {
        dispatch(setQuotaExceed(true));
        dispatch(setUserLoginError(data.message));
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: null,
              isForce: true,
              isPayment: true,
              oneTimePayment: true,
              choosePlan: { plans: "", activeContent: "" }
            }
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "payment-quota", showControl: "info" }
          })
        );
      } else if (Object.keys(data).includes("error")) {
        if (data.error === "Payment matching query does not exist.") {
          dispatch(setUserLoginError("A valid saved credit card is needed"));
        } else {
          // dispatch(
          //   setUserLoginError("An error has occurred, please try again.")
          // );
        }
      } else {
        downloadPdf(data.path, data.file_name);
        dispatch(getReportCount(token));
        dispatch(setAlertMessage("Your report has been generated!"));
      }
      dispatch(setLoader(false));
    } catch {
      dispatch(setLoader(false));
      dispatch(
        setUserLoginError("Something went wrong. Please try again later.")
      );
    }
  };
}

export function downloadAnonymousUserReport(object) {
  const headers = {
    "Region-Slug": localStorage.getItem("regionSlug"),
    "Jurisdiction-Slug": localStorage.getItem("jurisdictionSlug")
  };

  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/report/download-anonymous`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(object)
        }
      );
      const data = await response.json();
      downloadPdf(data.path, data.file_name);
      dispatch(setAlertMessage("Your report has been generated!"));
      dispatch(setLoader(false));
    } catch {
      dispatch(setLoader(false));
      dispatch(
        setUserLoginError("Something went wrong. Please try again later.")
      );
    }
  };
}

export function resetPassword(data) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/password_reset/`,
        {
          method: "POST",
          body: JSON.stringify(data)
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("email")) {
        dispatch(setUserLoginError(data.email));
        dispatch(setForgetPasswordError(true));
        dispatch(setLoader(false));
      } else {
        dispatch(setForgetPasswordError("success"));
        dispatch(
          setAlertMessage("Email has sent. Please enter code to continue.")
        );
        dispatch(setLoader(false));
        return true;
      }
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setUserUpdateError(error.message));
      return false;
    }
  };
}
export function updateResetPassword(userData) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/password_reset/confirm/`,
        {
          method: "POST",
          body: JSON.stringify(userData)
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("password")) {
        dispatch(setUserLoginError(data.password[0]));
        dispatch(setLoader(false));
      } else {
        dispatch(setAlertMessage("Password has been updated successfully."));
        dispatch(setLoader(false));
        dispatch(setForgetPasswordError(false));
        dispatch(
          setDataWindow({
            dataWindow: false,
            content: { isContact: null, choosePlan: {} }
          })
        );
      }
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setUserUpdateError(error.message));
      return false;
    }
  };
}

export function changePassword(userData, token) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/auth/update-password`,
        {
          method: "PATCH",
          body: JSON.stringify(userData)
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("current_password")) {
        dispatch(setUserLoginError(data.current_password[0]));
        dispatch(setLoader(false));
      } else if (Object.keys(data).includes("new_password")) {
        dispatch(setUserLoginError(data.new_password[0]));
        dispatch(setLoader(false));
      } else {
        if (Object.keys(data).includes("new_password")) {
          const errorMessages = [];
          dispatch(setLoader(false));
          Object.keys(data).includes("new_password") &&
            errorMessages.push(
              "This password is too short. It must contain at least 8 characters!"
            );
          dispatch(setUserLoginError(errorMessages));
        } else {
          dispatch(setAlertMessage("Password has been updated successfully."));
          dispatch(setLoader(false));
          dispatch(
            setDataWindow({
              dataWindow: false,
              content: { isContact: null, choosePlan: {} }
            })
          );
        }
      }
    } catch (error) {
      dispatch(setLoader(false));
      console.error("Update Error: ", error.message);
      dispatch(setUserLoginError(error.message));
      return false;
    }
  };
}
export function setForgetPasswordError(error) {
  return {
    type: SET_FORGET_PASSWORD_ERROR,
    payload: error
  };
}

export function getPlanPrice() {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/payment/account/prices`
      );
      const data = await response.json();
      dispatch(setPlanPrice(data));
    } catch {
      dispatch(
        setUserLoginError("Something went wrong. Please try again later.")
      );
    }
  };
}

export function getNewsFeed() {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/info/get-news-blog/`,
        {
          method: "POST",
          body: JSON.stringify({
            blogs_url: "https://www.urbanform.us/stories/blog-feed.xml"
          })
        }
      );
      const data = await response.json();
      const convert = require("xml-js");
      const news = convert.xml2json(data.blogs, {
        compact: true,
        spaces: 2
      });
      dispatch(setNewsFeed(JSON.parse(news)));
    } catch {
      dispatch(
        setUserLoginError("Something went wrong. Please try again later.")
      );
    }
  };
}

export function contactSupport(contactInfo) {
  return async (dispatch) => {
    try {
      await fetch(`${env.REACT_APP_URBANFORM_API_URL}/api/help/support`, {
        method: "POST",
        body: JSON.stringify(contactInfo)
      });

      dispatch(setAlertMessage("Your query has been submitted successfully."));
      dispatch(setLoader(false));
      dispatch(
        setDataWindow({
          dataWindow: true,
          content: {
            isContact: true,
            contactFields: CONTACT_SUPPORT,
            contactValues: {
              name: { value: "", required: true },
              email: { value: "", required: true },
              subject: { value: "", required: true },
              message: { value: "", required: true }
            },
            choosePlan: {}
          }
        })
      );
    } catch (error) {
      dispatch(setLoader(false));
      console.error("Contact Support: ", error);
      dispatch(setUserLoginError(error.message));
    }
  };
}

export function setPlanPrice(price) {
  return {
    type: SET_PLAN_PRICE,
    payload: price
  };
}
export function setNewsFeed(news) {
  return {
    type: SET_NEWS_FEED,
    payload: news
  };
}
export function setQuotaExceed(quota) {
  return {
    type: SET_QUOTA_EXCEED,
    payload: quota
  };
}

export function getPaymentMethodsQuota(token) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/payment/user/get-payment-methods`,
        {
          method: "POST"
        }
      );
      const data = await response.json();
      data.data.map((d) => {
        if (d.default_payment_method === true) {
          dispatch(setDefaultPaymentMethod(d));
        }
      });
      dispatch(setALLPaymentMethod(data.data));
    } catch (error) {
      console.error("Error: " + error);
    }
  };
}
export function setDefaultQuotaPaymentMethod(body, token) {
  return async (dispatch) => {
    await fetch(
      `${env.REACT_APP_URBANFORM_API_URL}/api/payment/user/change-default-payment`,
      {
        method: "POST",
        body: JSON.stringify(body)
      }
    );
    dispatch(getPaymentMethodsQuota(token));
  };
}

export function activateUserAccount(body) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${env.REACT_APP_URBANFORM_API_URL}/api/users/activation`,
        {
          method: "POST",
          body: JSON.stringify(body)
        }
      );
      const data = await response.json();
      if (Object.keys(data).includes("token")) {
        const user = jwt_decode(data.token);
        const object = { user: user.user, token: data.token };
        dispatch(setAccountActivationProcess(false));
        dispatch(handleUserSignup(object));
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: false,
              choosePlan: { plans: PLANS, activeContent: "non-subscriber" }
            }
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "choose-plan", showControl: "choose-plan" }
          })
        );
        // store token in local storage
        dispatch(
          setAlertMessage(
            "Congratulations, your account has been created successfully."
          )
        );
        dispatch(handleUserLogin({ user: user.user, token: data.token }));
        setQuotaExceed(false);
        window.localStorage.setItem(UF_TOKEN, data.token);
        dispatch(getPlanPrice());
      } else if (
        Object.keys(data).includes("message") &&
        !Object.keys(data).includes("token")
      ) {
        dispatch(setUserLoginError(data.message));
      }
      dispatch(setLoader(false));
    } catch (error) {
      console.error(error);
      dispatch(setUserLoginError(JSON.stringify(error)));
      dispatch(setLoader(false));
    }
  };
}

export function setDefaultPaymentMethod(data) {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD,
    payload: data
  };
}

export function setALLPaymentMethod(data) {
  return {
    type: SET_ALL_PAYMENT_METHOD,
    payload: data
  };
}
export function setAccountActivationProcess(data) {
  return {
    type: SET_ACCOUNT_ACTIVATION_PROCESS,
    payload: data
  };
}
